import React from 'react'
import s from './button.module.css'
import { ButtonPropsType } from '../../types/types'

const Button = (props:ButtonPropsType) => {
  let content  = props.content 
  let id = props.id
  let onClick = props.onClick
  let groupId = props.groupId
  let type = props.type
  let exeHandleClick = () => {
    onClick(groupId, id)
  }

  return (
    <div
      className={type===0 ? s.normal: type===1 ? s.selected : s.off}
      onClick={exeHandleClick}  
    >
      {content}
    </div>
  )
}

export default Button