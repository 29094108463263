import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'

const initialState = {
  status: 'idle',
  messages: []

}

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage(state, action) {
      const fil = action.payload
      state.messages = fil

    },

  }
})



//===========================THUNK================================
export const getMessageThunkCreator =() => {
  return (dispatch:any) => {
     gccApi.getMessagesList().then(data => {
     dispatch(setMessage(data))    
         })
 }
 }





export const { setMessage } = messageSlice.actions
export default messageSlice.reducer