import React, { useEffect } from 'react'
import s from './MdrChoice.module.css'
import Button from '../button/button'
import { useDispatch, useSelector } from 'react-redux'
import {  mdrSelectorDict, mdrSelectorNew } from '../../selectors/selectors'
import { getMdrSelectorThunkCreator, handleClickThunkCreator, itemSelectorType, makeClick } from '../../redux/mdrSlice'

export interface selectorTotalType {
  [key: string]: Array<number>
}

function MdrChoice() {
  let dispatch:any = useDispatch()
  const choiceList: Array<itemSelectorType> = useSelector(mdrSelectorNew)
  const selectorDict:any = useSelector(mdrSelectorDict)

  let onClick = (group:string, id:number) => {

    // dispatch(getMdrSelectorThunkCreator(group, id))
    dispatch(handleClickThunkCreator(id))
  }

  useEffect(() => {
    dispatch(getMdrSelectorThunkCreator('',-1))
  },[dispatch])
  return (

    <div className={s.wrapper}>
      {choiceList
      ? choiceList.map((tag, index:number) =>

     
        <Button 
          id={tag.id}
          content={selectorDict[tag.groupId][tag.dbId]}
          key={index} 
          onClick={onClick}
          type={tag.buttonState}
          groupId={tag.groupId}

        /> 
        )
      
      : <p>loading...</p>
      }
    </div>

  )
}

export default MdrChoice