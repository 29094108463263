import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import { isEditable } from '@testing-library/user-event/dist/utils'
import TableUniveralSet from '../components/table/tableUniveralset'
import NavbarManagement from '../components/navbar/NavbarManagement'



const Lala = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()
  const buttonState = useSelector((state:RootState)=>state.transmittal.newSelector)
  let selected:any = buttonState.find(string=>string.buttonState===1)?.dbId || 0
  const finalisationSele  = useSelector((state:RootState) => state.tables.tables['interface/settings']) as any
  let finalisation = 0
  if (finalisationSele){
    // const result = words.filter((word) => word.length > 6);
    finalisation = finalisationSele.filter((item:any) => item.id ===2)[0].value
  }

  useEffect(() => {
    dispatch(TranHandleClickThunkCreator(0))
  },[dispatch])
  return (
<div>
<NavbarManagement/>

  <TableUniveral
  tableHeader='Статистика посещений'
  headers={[
    "team",
    "address",
    "time",
  ]}




  apiPath='appstat/visits'
  isRemoveIdex={true}
  isAddTime={true}
  cols={
    [
      "team",
      "address",
      "time",
      
    ]
  }


  /> 
</div>
    
  )
}
const Visits = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(Visits)


