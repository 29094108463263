import { useDispatch } from "react-redux"
import { utilities } from "../../redux/utilities"
import CellSimple from "./cellSimple"
import CellRowspan from "./cellRowspan"
import { TableSelectorType, simpleDict } from "../../types/types"
import CellDropdown from "./cellDropdown"

const CellSelector = (props:{
	colName:string,
	children:any,
	rowNumber:number
	isAddTime: boolean | undefined
	rowSpanDict:{[key:string]:Array<string|number>}
	rowSpanCols:Array<string>
	separate: Array<string> | undefined
	rowIndex:number
	isAddIndex:boolean
	copyColumnName: Array<string> | undefined
	path: string | undefined 
	editableCols: Array<string> | undefined
	tableName:string
	postCols: Array<string> | undefined
	postColsData: simpleDict| undefined

	selectors?: TableSelectorType
	id: number
	apiPath: string
	onSave?: Function
	// isPostRow: boolean
	// modifyCellFunc: Function

 }) =>{
	let dispatch:any = useDispatch()
	let ifDifineRospan: boolean
	props.rowSpanCols.includes(props.colName) ? ifDifineRospan = true : ifDifineRospan =false
	let isOff:boolean
	let isDropdown: boolean = false

	if (props.selectors) {
		Object.keys(props.selectors).includes(props.colName) ? isDropdown = true : isDropdown =false
	}
	
	let isRowspan:boolean
	let rowspanAmount:number
	if (ifDifineRospan) {
		isOff = utilities.isCellOff(props.rowSpanDict[props.colName], props.rowNumber)
		isRowspan = utilities.isCellRowpan(props.rowSpanDict[props.colName], props.rowNumber)
			isRowspan 
			? rowspanAmount = utilities.desineRospanNumber(props.rowSpanDict[props.colName], props.rowNumber)
			: rowspanAmount = 0

	} else {
		isOff = false
		rowspanAmount = 0
	}


	return (
<>
{isDropdown &&
	<CellDropdown
	isAddTime = {props.isAddTime}
	separate = {props.separate}
	colName = {props.colName}
	rowNumber = {props.rowNumber}
	path = {props.path}
	copyColumnName = {props.copyColumnName}
	editableCols = {props.editableCols}
	tableName = {props.tableName}
	postCols = {props.postCols}
	selectors = {props.selectors}
	id = {props.id}
	apiPath = {props.apiPath}
	// modifyCellFunc = {props.modifyCellFunc}
	>
		{props.children}
	</CellDropdown>
}


{!ifDifineRospan && !isDropdown &&
	<CellSimple
	isAddTime = {props.isAddTime}
	separate = {props.separate}
	colName = {props.colName}
	rowNumber = {props.rowNumber}
	path = {props.path}
	copyColumnName = {props.copyColumnName}
	editableCols = {props.editableCols}
	tableName = {props.tableName}
	postCols = {props.postCols}
	postColsData = {props.postColsData}
	onSave = {props.onSave}
	// modifyCellFunc = {props.modifyCellFunc}
	>
		{props.children}
	</CellSimple>
}
{ifDifineRospan &&
	<CellRowspan
		isAddTime = {props.isAddTime}
		isOff = {isOff}
		rowSpanNum={rowspanAmount}
	>
		{props.children}
	</CellRowspan>
}

</>
	)
}


export default CellSelector