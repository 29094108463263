import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'

const Lala = ({dictFunc}:any) => {
  return (
<div>
  <TableUniveral
    headers={[
      dictFunc('drc_number'),
      dictFunc('document_number'),
      dictFunc('transmittal_inbox_number'),

    ]}
    apiPath='revision/drc'
    cols={[  
      "number",
      "drcfiles__file_name",
     "drcfiles__file_id__transmittal__number"]}
    rowSpanCols={[
      'number',
      "drcfiles__file_id__transmittal__number"]}
  />
</div>
  )
}

const DrcPage = (props:any) => {
    return (
        <div>
           <LayoutContainer
           
           >
           <h1>{props.dictFunc('drc')}</h1>
         <Lala
         {...props}
         ></Lala>   
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(DrcPage)


