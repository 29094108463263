import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import TableUniveralSet from '../components/table/tableUniveralset'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { RequestSettings } from './TodoPage'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import NceRev from '../components/ui/NceRev'

const Lala = ({dictFunc}:any) => {
  let is_submitted: number | undefined = RequestSettings('interface/settings', 'id', 7, 'value') as number
  let team: number | undefined = RequestSettings('interface/settings', 'id', 8, 'value') as number
  let subtype: number | undefined = RequestSettings('interface/settings', 'id', 9, 'value') as number
  team <0 && (team=undefined)
  subtype <0 && (subtype=undefined)

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register3'])
  let expandedRowContent2:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/files'])


  return (
<div>
          <NavbarManagement/>
          <div
            style={{"display": "flex"}}
          >

          <DropdownUniveral 
              itemsApiPath= {'task/bool'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {7}
              finalFunction ={()=>1+1}
          />
            <DropdownUniveral 
              itemsApiPath= {'transmittal/subtypeoout'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {9}
              finalFunction ={()=>1+1}
          />
          <DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {8}
              finalFunction ={()=>1+1}
          />
          </div>
<TableUniveralSet
          apiPath={'transmittal/extensionout'}
          headers={[]}
/>
<TableUniveralSet
          apiPath={'transmittal/subtypeoout'}
          headers={[]}
/>

  <TableUniveral
  tableHeader='Исходящие Трансмиталы'
  headers={[
    "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Дата",
    "Команда",
    "Выгружен?",
    "Комментарий",
    "Применчание",
    "Уведомлено",  
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtypeoout', "value":'id',"label":'code'},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code'},
      "is_notified": {"apiPath":'task/bool', "value":'id',"label":'code'},
  }
  

  }

  editableCols={[
    "comments",
    "comments2",
    "number",
    "date",
  ]}

  apiPath='transmittal/register3'
  apiParametres={{"is_submitted":is_submitted, "team": team, "subtype": subtype}}
  cols={[
    "id",
    "number",
    "name",
    "subtype",
    "date",
    "team",
    "is_submitted",
    "comments",
    "comments2",
    "is_notified",
]}
  > 


{expandedRowContent &&
  <>
<TableUniveral 
apiPath='letters/notification'
apiParametres={{"transmittal":expandedRowContent.id}}
postColsData={{"transmittal":expandedRowContent.id}}
headers={[]}
cols={['comments','team', ]}
editableCols={['comments']}
postCols={['comments']}
isRemoveIdex={true}
selectors={{"team": {"apiPath":'task/teams', "value":'id',"label":'name'},}}
tableHeader='Уведомления'
/> 
<TableUniveral 
apiPath={'mom/outboxrelation'} 
apiParametres={{"transmittal_relation":expandedRowContent.id}}
// cols={[
//   // "id":1,
//   // "transmittal_relation":3400,
//   "outbox_relation"
// ]}
headers={[]}            
tableHeader='История уведомлений'    
                />


<TableUniveral 
apiPath={'transmittal/connections'} 
apiParametres={{"child":expandedRowContent.id}}
cols={[
  // "id":1,
  // "transmittal_relation":3400,
  "comments",
  "parent",
  "subject",
  "child"
]}
editableCols={[
  "comments", "parent"
]}
postColsData={{"parent":1, "child":expandedRowContent.id}}
headers={[  
  "Комментарий",
  "Трансмиттал-Предшественник",
  "Письмо-уведомление",
  "child"]
}
postCols={["comments"]}            
tableHeader='Предшественник трансмиттала'  
selectors={
  {
    "subject": {"apiPath":'mom/subject', "value":'id',"label":'code_new'},
}}  
                />




<TableUniveral
        tableHeader='фильтрованные письма'
        apiPath='mom/messagesfilter'
        // apiParametres={{'search':'222'}}
        apiParametres={{"search":expandedRowContent.number}}
        headers={[
          // "id",
          // "subject",
          "message_id_subject",
          // "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",
        ]}
        cols={[
          // "id",
          // "subject",
          "subject_common",
          // "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",

        ]}
        editableCols={[
          'comment',
          "short_body",
          "message_id_date"
        
        ]}
        selectors={
          {
            "from_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "to_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "subject_common": {"apiPath":'mom/subject', "value":'id',"label":'code_new', isLocked:true},
            // "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
            // "move_up": {"apiPath":'task/bool', "value":'id',"label":'code'},

        }}

      />







  <TableUniveral
  apiPath='transmittal/files'
  apiParametres={{"transmittal":expandedRowContent.id}}
   headers={[

  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}

  cols={  [      
    // "id",
    "file_name",
    // "isFolder",
    // "size",
    // "local_path",
    "global_path",
    // "date_created",
    // "date_modified",
    // "transmittal",
    // "parentId",
    // "extension",         
    ]}
    editableCols={["global_path"]}

  > 



{expandedRowContent2 &&


<NceRev
  doc_name={expandedRowContent2.file_name}
/>
  //  <TableUniveral 
  //  tableHeader='Ревизии NCE'
  //  apiPath={'revision/getncerevisionstatus'} 
  // //  headers={[]}
  //  apiParametres={{"doc_name":expandedRowContent2.file_name}} 
  //  copyColumnName={["file_name"]}
  //  isRemoveIdex={true}  
  //  headers={[
  //   "имя файла", 'N ТРМ','Тип трансмиттала',"Дата",
  // ]}
  //  cols={[
  //   "file_name", 'trm_no','trm_type',"date_modified",
  // ]}               
  //  />
   }
   
{!expandedRowContent2 &&
<></>
}




  </TableUniveral>
  
  
  
  
  </>
}
{!expandedRowContent &&
<></>
}




  
  
  </TableUniveral> 






</div>
    
  )
}
const RegisterPage3 = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(RegisterPage3)


