import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'

const initialState = {
  status: 'idle',
  entities: {},
  files: [],
  address: []
}

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddress(state, action) {
      const fil = action.payload
      state.address = fil

    },

  }
})



//===========================THUNK================================
export const getAddressThunkCreator =() => {
  return (dispatch:any) => {
     gccApi.getAddressList().then(data => {
     dispatch(setAddress(data))    
         })
 }
 }





export const { setAddress } = addressSlice.actions
export default addressSlice.reducer