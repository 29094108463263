import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'

const ReportsMilestonePage = ({dictFunc}:any) => {
  const [isUpdated, setIsUpdated] = useState(true)
  useEffect(() => {
    console.log('rrrr')
    // gccApi.prepareNotification().then(data => {
    //   console.log(data)
      setIsUpdated(true)
    //       }) 
  },[])
  
return (
<div>
  <LayoutContainer>
  <NavbarReports/>
    <h1>Отчеты</h1>
    {isUpdated &&
      <TableUniveral
        rowsLimit={20}
        tableHeader='Отчеты'
        apiPath='transmittal/documents'
        headers={[]}
        // cols={[
        //   'mdr',
        //   'files',
        //   'version',
        //   'issue',
        //   'doc_class',
        //   'date',
        //   'date_id',
        // ]}

      />
    }
  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(ReportsMilestonePage)


