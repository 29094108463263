import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
// import { isEditable } from '@testing-library/user-event/dist/utils'
import TableUniveralSet from '../components/table/tableUniveralset'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { RequestSettings } from './TodoPage'
import NceRev from '../components/ui/NceRev'



const Lala = ({dictFunc}:any) => {

  let finalisation: number | undefined = RequestSettings('interface/settings', 'id', 2, 'value') as number
  let team: number | undefined = RequestSettings('interface/settings', 'id', 10, 'value') as number
  let subtype: number | undefined = RequestSettings('interface/settings', 'id', 11, 'value') as number
  team <0 && (team=undefined)
  subtype <0 && (subtype=undefined)

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register2'])
  let expandedRowContent2:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/files'])


  return (
<div>
<NavbarManagement/>
<div
  style={{"display": "flex"}}
>
<DropdownUniveral 
              itemsApiPath= {'transmittal/finalization'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {2}
              finalFunction ={()=>1+1}
          />
{/* <DropdownUniveral 
              itemsApiPath= {'transmittal/subtype'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {11}
              finalFunction ={()=>1+1}
          /> */}
<DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {10}
              finalFunction ={()=>1+1}
          />
</div>
<TableUniveralSet
          apiPath={'transmittal/extension'}
          headers={[]}
/>
  <TableUniveral
  tableHeader='Входящие трансмиталы'
  headers={[
    "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Номер DRC",
    "Разослан?",
    "Дата",
    "Команда",
    "Срок выполнения",
    "Статус",
    "Комментарий",
    "Применчание",
    
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtype', "value":'id',"label":'code'},
      "finalization": {"apiPath":'transmittal/finalization', "value":'id',"label":'code'},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code'},
  }
  

  }

  editableCols={[

    "drc_number",
    "comments",
    "comments2",
    "due_date",

  ]}

  apiPath='transmittal/register2'
  apiParametres={{"finalization":finalisation, "team":team, "subtype":subtype}}
  cols={[
    "id",
    "number",
    "name",
    "subtype",
    "drc_number",
    "is_submitted",
    "date",
    "team",
    "due_date",
    "finalization",
    "comments",
    "comments2",
]}
  > 

{expandedRowContent &&

<>
<TableUniveral 
apiPath='letters/notification'
apiParametres={{"transmittal":expandedRowContent.id}}
postColsData={{"transmittal":expandedRowContent.id}}
headers={[]}
cols={['comments','team', ]}
editableCols={['comments']}
postCols={['comments']}
isRemoveIdex={true}
selectors={{"team": {"apiPath":'task/teams', "value":'id',"label":'name'},}}
tableHeader='Уведомления'
/> 

<TableUniveral 
apiPath={'mom/outboxrelation'} 
apiParametres={{"transmittal_relation":expandedRowContent.id}}
// cols={[
//   // "id":1,
//   // "transmittal_relation":3400,
//   "outbox_relation"
// ]}
headers={[]}            
tableHeader='История уведомлений'    
                />
<TableUniveral 
apiPath={'transmittal/connections'} 
apiParametres={{"child":expandedRowContent.id}}
cols={[
  // "id":1,
  // "transmittal_relation":3400,
  "comments",
  "parent",
  "subject",
  // "child"
]}
editableCols={[
  "comments", "parent"
]}
postColsData={{"parent":1, "child":expandedRowContent.id, "subject":1}}
headers={[  
  "Комментарий",
  "Трансмиттал-Предшественник",
  "Письмо-предшественник",
  // "child"
]
}
postCols={["comments"]}            
tableHeader='Предшественник трансмиттала'  
selectors={
  {
    "subject": {"apiPath":'mom/subject', "value":'id',"label":'code_new'},
}}  
                />


<TableUniveral 
apiPath={'transmittal/connections2'} 
apiParametres={{"parent":expandedRowContent.id}}
cols={[
  // "id":1,
  // "transmittal_relation":3400,
  "comments",
  "child",
  "subject",
  // "parent",
]}
editableCols={[
  "comments", "child"
]}
postColsData={{"parent":expandedRowContent.id, "child":1, "subject":1}}
headers={[  
  "Комментарий",
  "Трансмиттал-Последователь",
  "Письмо-последователь",
  // "родитель"
]
}
postCols={["comments"]}            
tableHeader='Последователь трансмиттала'  
selectors={
  {
    "subject": {"apiPath":'mom/subject', "value":'id',"label":'code_new'},
}}  
                />


<TableUniveral
        tableHeader='фильтрованные письма'
        apiPath='mom/messagesfilter'
        // apiParametres={{'search':'222'}}
        apiParametres={{"search":expandedRowContent.number}}
        headers={[
          // "id",
          // "subject",
          "message_id_subject",
          // "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",
        ]}
        cols={[
          // "id",
          // "subject",
          "subject_common",
          // "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",

        ]}
        editableCols={[
          'comment',
          "short_body",
          "message_id_date"
        
        ]}
        selectors={
          {
            "from_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "to_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "subject_common": {"apiPath":'mom/subject', "value":'id',"label":'code_new', isLocked:true},
            // "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
            // "move_up": {"apiPath":'task/bool', "value":'id',"label":'code'},

        }}

      />





                


  <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":expandedRowContent.id}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [      
                    // "id",
                    "file_name",
                    // "isFolder",
                    // "size",
                    // "local_path",
                    "global_path",
                    // "date_created",
                    // "date_modified",
                    // "transmittal",
                    // "parentId",
                    // "extension",         
                    ]}
                    editableCols={["global_path"]}
                    tableHeader='Файлы'

                  >

{expandedRowContent2 &&

                <NceRev
                  doc_name={expandedRowContent2.file_name}
                />



                  //  <TableUniveral 
                  //  tableHeader='Ревизии NCE'
                  //  apiPath={'revision/getncerevisionstatus'} 
                  // //  headers={[]}
                  //  apiParametres={{"doc_name":expandedRowContent2.file_name}} 
                  //  copyColumnName={["file_name"]}
                  //  isRemoveIdex={true}  
                  //  headers={[
                  //   "имя файла", 'N ТРМ','Тип трансмиттала',"Дата",
                  // ]}
                  //  cols={[
                  //   "file_name", 'trm_no','trm_type',"date_modified",
                  // ]}               
                  //  />
                   }
                   
{!expandedRowContent2 &&
<></>
}

                    
                    </TableUniveral> 

                
                  </>
}
{!expandedRowContent &&
<></>
}

  </TableUniveral> 






</div>
    
  )
}
const RegisterPage2 = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(RegisterPage2)


