import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'


function NavbarTranslator({dictFunc}:any) {
    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()

    return (
        <div className="wrapper2">

            <div className=""><NavLink className="item" to={team + "/translatorpro"}>ПРО</NavLink></div>
            {/* <div className=""><NavLink className="item" to={team + "/translatordoc"}>Документы</NavLink></div> */}
            <div className=""><NavLink className="item" to={team + "/translatordocone"}>По одному</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/translatordoconeeng"}>По одному ENG</NavLink></div>
            
            
            <div className=""><NavLink className="item" to={team + "/translatordoclist"}>Список</NavLink></div>

            <div className=""><NavLink className="item" to={team + "/translatorfile"}>Скачать файл</NavLink></div>



       
        </div>
    )
}




export default compose (
    withSettings
  )(NavbarTranslator)