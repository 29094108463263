import { useDispatch } from "react-redux"
import DropdownUniveral from "../../components/dropdown/dropdownUniversal"
import { TableSelectorType, selectorTypeInner, simpleDict } from "../../types/types"
import DropdownUniveral2 from "../dropdown/dropdownUniversal2"

export type oneCellType = {
	rowNumber: number
	colName: string
	value: string
}

 const CellDropdown = (props:{
	isAddTime:boolean | undefined, 
	children:any,
	separate: Array<string> | undefined,
	colName:string
	path: string | undefined
	copyColumnName: Array<string> | undefined
	editableCols: Array<string> | undefined
	rowNumber: number
	tableName: string
    postCols:  Array<string> | undefined
	selectors?: TableSelectorType 
	id: number
	apiPath:string
	
}):any =>{
	let dispatch:any = useDispatch()
	let itemsApiPath:string = ''
	let itemsColumn:string = ''
	let storageApiPath:string = ''
	let storageColumn:string = ''
	let storageId:number = -1
	let isLocked:boolean = false

	if (props.selectors){
		let obj:selectorTypeInner = props.selectors[props.colName]
		itemsApiPath = obj.apiPath
		itemsColumn = obj.label
		isLocked = obj.isLocked as boolean

		
		// storageApiPath = 
		
		
	}

	return (
	<td>

<DropdownUniveral2
              itemsApiPath= {itemsApiPath}
              itemsColumn= {itemsColumn}
              storageApiPath= {props.apiPath}
              storageColumn=  {props.colName}
              storageId = {props.id}
			  isLocked = {isLocked}
			  
              finalFunction ={()=>1+1}
			//   isLock = {}
          />
	</td>
	)
}

export default CellDropdown