import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
import { TableApiType, TableRowApiType, VirtualTableType, setCellTablePayloadType, simpleDict } from '../types/types'
import  { RootState } from './store'
import { utilities } from './utilities'
import { setDeletedStatusThunk, setSavedStatusThunk } from './rootSlice'

type tablesStateType = {
  [key:string]: Array<TableRowApiType>  
}

type tablesPropertiesType = {
  [key:string]: tablePropetiesType
}

type tablePropetiesType = {
  prefix?: string 
  parameters?: simpleDict
}

type isSaveType = {
  [key:string]: boolean
}

type expandedRowStateType = {
  [key:string]: number
}

type expandedRowContentType = {
  [key:string]: simpleDict
}


type filterStateType = {
  [key:string]: {[key:string]:any}
}
type initialStateType = {
  tables: tablesStateType
  talbesFilters: filterStateType
  lastModified: setCellTablePayloadType
  isNoChanges: boolean

  isSave: isSaveType
  tablesProperties: tablesPropertiesType
  expandedRowNumber: expandedRowStateType
  expandedRowContent: expandedRowContentType
}

const initialState:initialStateType = {
  lastModified : {
    "celName": '',
    "data": 22,
    "rowNumber": -100,
    "tableName": ''
  },
  tables: {'name':[]},
  talbesFilters: {'name':{'a':''}},
  isSave: {'name':false},
  tablesProperties: {'name':{}},
  isNoChanges: false,
  expandedRowNumber: {'name':-1000},
  expandedRowContent: {}
}
const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {

    setExpandedRowNumber(state, action){
      const {tableName, RowNumber}:{tableName: string, RowNumber:number} = action.payload
      state.expandedRowNumber[tableName] = RowNumber
      // state.expandedRowContent[tableName] = state.tables[tableName][RowNumber] 
    },

    setExpandedRow(state, action){
      const {tableName, RowNumber}:{tableName: string, RowNumber:number} = action.payload
      state.expandedRowNumber[tableName] = RowNumber
      state.expandedRowContent[tableName] = state.tables[tableName][RowNumber]

      
    },
 

    setTableProperty (state, action) {
      const {tableName, prefix, param}:setTablePropertyiePayloadType = action.payload
      state.tablesProperties[tableName] = {}
      state.tablesProperties[tableName].prefix = prefix
      state.tablesProperties[tableName].parameters = param
      // state.expandedRowNumber[tableName] = -1000

    },
    setTable(state, action) {
      const {tableName, data}:setTablePayloadType = action.payload
      state.tables[tableName] = data
      state.talbesFilters[tableName] = {}
      // state.expandedRowNumber[tableName] = -1000

    },

    setTable2(state, action) {
      const {tableName, data}:setTablePayloadType = action.payload
      state.tables[tableName] = data
      // state.expandedRowNumber[tableName] = -1000

    },

    setSaveSta(state, action) {
      const {isSave, tableName} = action.payload
      state.isSave[tableName] = isSave

    },



    initiateFilter(state, action) {
      const {tableName}:setTablePayloadType = action.payload
      state.talbesFilters[tableName] = {}
    },
    setFilter(state, action) {
      const {tableName, column, value}:setFilterPayloadType = action.payload
      state.talbesFilters[tableName][column] = value
    },

    clearTable(state, action):any {
      let lastModifiedTable = state.lastModified.tableName
      state.tables[lastModifiedTable] = []
    },

    setCell(state, action):any {
      const {tableName, rowNumber, celName, data}:setCellTablePayloadType = action.payload
      
      // if (state.tables[tableName][rowNumber][celName] === data){
      //   state.isNoChanges = true
      // } else {
      //   state.isNoChanges = false
      // }
      
      state.tables[tableName][rowNumber][celName] = data
      state.lastModified = action.payload
    },

  }
})

//===========================  T  H  U  N  K  ================================
type setTablePayloadType = {
  data: any
  tableName: string
}

export type setTablePropertyiePayloadType ={
  tableName: string,
  prefix?: string,
  param?: simpleDict
}

export type setFilterPayloadType = {
  column: string
  value: any
  tableName: string
}


export type setTableType = {
  apiPath:string
  postCols?:Array<string>
  prefix: string | undefined 
  parameters: simpleDict | undefined 

}

export const refreshThunk =(path:string) => {
  return (dispatch:any, getState:any) => {

dispatch(setTableThunk({
  apiPath: path,
    postCols:undefined,
    prefix:undefined,
    parameters:undefined,
}


))
  }



}

export const setTableThunk =(
  {
    apiPath, 
    postCols,
    prefix,
    parameters,
  }: setTableType
) => {
  return (dispatch:any, getState:any) => {
    gccApi.getTable({path:apiPath, params: parameters}).then(data => {
      prefix && (data = utilities.pathToObject(data, prefix))
      let filteredData:Array<simpleDict> = data
      let tableName = utilities.convertPathToName(apiPath)
      
      if (postCols) {
        let oneRow = data[0]
        let oneRowReset = utilities.resetDictValues(oneRow)
        oneRowReset['id'] = -101
        filteredData.unshift(oneRowReset)
      }
      let payload:setTablePayloadType = {data:filteredData, tableName:tableName}
      dispatch(setTable(payload))
          })
 }
 }

 export const applyFilterThunk =(apiPath:string, postCols?:Array<string>) => {
  return (dispatch:any, getState:any) => {
    let state:RootState = getState()
    let parameters = state.tables.tablesProperties[apiPath].parameters
    gccApi.getTable({path:apiPath, params: parameters}).then(data => {
      let filteredData:Array<simpleDict> = []
      let prefix = state.tables.tablesProperties[apiPath].prefix 
      prefix && (data = utilities.pathToObject(data, prefix))
      let filterDict = state.tables.talbesFilters[apiPath]
      if (Object.keys(filterDict).length!==0){
      filteredData = utilities.filterDictRecursiveComplex(data, filterDict)
       } else {
        filteredData = data
       }
      let tableName = utilities.convertPathToName(apiPath)
      if (postCols) {
        let oneRow = data[0]
        let oneRowReset = utilities.resetDictValues(oneRow)
        oneRowReset['id'] = -101
        filteredData.unshift(oneRowReset)
      }
      let payload:setTablePayloadType = {data:filteredData, tableName:tableName}
      dispatch(setTable2(payload))
    })        
 }
 }




export const modifyVirtualTableThunk = ({
  fireFunction,
  tableName,
  rowNumber,
  celName,
  data,
}:VirtualTableType
) =>{
  return (dispatch:any, getState:any) => {
    

    let state1:RootState = getState()
    let content = state1.tables.tables[tableName]
    let rowNumber2=content.findIndex((item:any)=>item.id === rowNumber)
    // console.log(content[rowNumber2][celName])
    rowNumber = rowNumber2
    dispatch(setCell({tableName, rowNumber, celName, data}))
    let state:RootState = getState()
    let lastModified = state.tables.lastModified
    
    let lastModifiedRow = state.tables.tables[lastModified.tableName][lastModified.rowNumber]
    let apiObject:TableApiType = {
      path:lastModified.tableName,
      id:lastModifiedRow.id,
      rowContent: lastModifiedRow,
    }

    gccApi.editTable(apiObject).then(data => {
      fireFunction()
    })
  }
}


 export const modifyTableThunk =(
  postCols?:Array<string>, 
  postColsData? :simpleDict,
  isHideSaveStatus?: boolean,
  onSave?: Function,
) => {
  return (dispatch:any, getState:any) => {
    let state:RootState = getState()
    let lastModified = state.tables.lastModified
    let lastModifiedTable = lastModified.tableName
    let lastModifiedRow = state.tables.tables[lastModified.tableName][lastModified.rowNumber]
    let lastModifiedValue = lastModified.data
    let apiObject:TableApiType = {
      path:lastModified.tableName,
      id:lastModifiedRow.id,
      rowContent: lastModifiedRow,
    }

    dispatch(setSaveSta({isSave:false, tableName:lastModifiedTable}))
    if (lastModifiedValue ==='-ya-' && lastModifiedRow.id !==-101)  {
      gccApi.deleteTable(apiObject).then(data => {
        onSave && onSave()
        dispatch(setDeletedStatusThunk())
        dispatch(setSaveSta({isSave:true, tableName:lastModifiedTable}))
        dispatch(applyFilterThunk(lastModified.tableName,postCols))
      })
    } else if (lastModifiedRow.id === -101 ) {
      let ifFilled:boolean = utilities.checkIfSelectedFilled(lastModifiedRow, postCols as Array<string>)
      delete apiObject.id
      if (apiObject.rowContent) {
        let newRow = utilities.deleteUselessKeys(apiObject.rowContent, postCols as Array<string>)
        apiObject.rowContent = newRow
        if (postColsData){
          for (let it of Object.keys(postColsData)){
            apiObject.rowContent[it] = postColsData[it]
          }
        }
      }
      ifFilled && (
        
        gccApi.postTable(apiObject).then(data => {
          onSave && onSave()
          dispatch(applyFilterThunk(lastModified.tableName,postCols))
          dispatch(setSavedStatusThunk())
          dispatch(setSaveSta({isSave:true, tableName:lastModifiedTable}))
      })
      )
    
    } else {      
      gccApi.editTable(apiObject).then(data => {
        onSave && onSave()
        dispatch(applyFilterThunk(lastModified.tableName,postCols))
        !isHideSaveStatus && dispatch(setSavedStatusThunk())
        !isHideSaveStatus && dispatch(setSaveSta({isSave:true, tableName:lastModifiedTable}))
      })
    }
 }
}


export const postTableThunk =(apiObject:TableApiType) => {
  return (dispatch:any, getState:any) => {
    
     
        
        gccApi.postTable(apiObject).then(data => {
          console.log('visit')
      })
      
    
    } 
 }


export const { setTable, setCell, clearTable, 
  setFilter, initiateFilter, setTable2, setSaveSta, setTableProperty, setExpandedRow, setExpandedRowNumber } = tableSlice.actions
export default tableSlice.reducer