import { useDispatch } from "react-redux"
import { setCopyedStatusThunkCreator } from "../../redux/rootSlice"
import { utilities } from "../../redux/utilities"
import { useState } from "react"
import CellEdit from "./cellEdit"
import DateParser from "../date_parser/dateParser"
import copy from "../Copy/copy"
import { simpleDict } from "../../types/types"

export type oneCellType = {
	rowNumber: number
	colName: string
	value: string
}

const CellSimple = (props:{
	isAddTime:boolean | undefined, 
	children:any,
	separate: Array<string> | undefined,
	colName:string
	path: string | undefined
	copyColumnName: Array<string> | undefined
	editableCols: Array<string> | undefined
	// modifyCellFunc: Function
	rowNumber: number
	tableName: string
    postCols:  Array<string> | undefined
	postColsData: simpleDict | undefined
	onSave?: Function
	
}):any =>{
	let dispatch:any = useDispatch()

	let path:string = props.path || ""
	let isForCopy:boolean = false

	let isIndex:boolean 
	props.colName==="rowIndex" ? isIndex = true : isIndex = false

	if (props.copyColumnName){
		if (props.copyColumnName.includes(props.colName)){
			path  = "\\" + path.slice(7)
			isForCopy = true		
		}
	}
	
	let onClickCopy = () => {
		let result:boolean = copy(path)
		result && dispatch(setCopyedStatusThunkCreator())
		}

	let isEditable:boolean = false
	if (props.editableCols){
		if (props.editableCols.includes(props.colName)){
			isEditable = true
		}
	}



	let isSeparateCell:boolean = false
	let leftContent:string=''
	let rigthContent:string=''
	if (props.separate) {
		let separateSymbol = props.separate[1]
		let sepatedColumn = props.separate[0]
		if (props.colName === sepatedColumn){
			isSeparateCell = true
			leftContent = utilities.sepateCellBySlash(props.children,separateSymbol)[0]
			rigthContent = utilities.sepateCellBySlash(props.children,separateSymbol)[1]
		}
	}
	const [isHover, setIsHover] = useState(false);
	const handleMouseEnter = () => {
	   setIsHover(true);
	};
	const handleMouseLeave = () => {
	   setIsHover(false);
	};
	const boxStyle = {
		cursor: 'pointer',
		backgroundColor: isHover ? 'lightblue' : 'rgb(255, 255, 255)',
	 };
	return (
	<>
	{isEditable &&
	<>
		<CellEdit 
		content={1}
		colName={props.colName}
		rowNumber={props.rowNumber}
		tableName = {props.tableName}
		postCols = {props.postCols}
		postColsData = {props.postColsData}
		onSave = {props.onSave}
		
		// modifyCellFunc = {props.modifyCellFunc}
		>
			{/* <DateParser
				content = {props.children}
				isAddTime = {props.isAddTime}
			/> */}

{props.children}
		</CellEdit>
	</>
	}

	{isSeparateCell && !isEditable &&
		<>
			<td>{leftContent}</td>
			<td>{rigthContent}</td>
		</>
	}
	{!isSeparateCell && isForCopy && !isEditable &&
	<>
		<td	
		style={boxStyle}
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
		onClick={onClickCopy}
		>
			{props.children}
		</td>
	</>
	}
	{!isSeparateCell && !isForCopy && !isIndex && !isEditable &&
	<>
		<td>
			<DateParser
				content = {props.children}
				isAddTime = {props.isAddTime}
			/>
		</td>
		</>
	}
	{isIndex && !isEditable &&
	<>
		<td
		style={
			{
				"color": "lightGray",
				"width":"1%",
				"fontSize": "10px",
			}
		}
		>
			 {props.children}
		</td>
		</>
	}
	</>
	)
}

export default CellSimple