import React, { FocusEvent, ChangeEvent, MutableRefObject, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
export type  onChangeInputType = (event: ChangeEvent<HTMLInputElement>) => void;
export type  onChangeTextType = (event: ChangeEvent<HTMLTextAreaElement>) => void;

// ChangeEventHandler<HTMLTextAreaElement>

export type  onFocusInputType =  (event: FocusEvent<HTMLInputElement, Element>)=> void  
export type InputType= {
    handleOnChange: onChangeInputType
    handleOnBlur: any
    handleFocus: onFocusInputType
    value: string | number
    refObj: MutableRefObject<HTMLInputElement>;
}

const Input = ({  
    handleOnChange,
    handleOnBlur,
    handleFocus,
    value,
    refObj,
}:InputType) => {
    let isTextEdit:boolean = true

    // const setText = () => setIsFinalEdit(true)
    if (typeof(value) ==='string'){
      let i = 0
      if (value.length > 10){
        isTextEdit=true
        // setText()
        
      }
    }


      
    
    
    let handleOnChangeText : any = (handleOnChange as any)

   
  return (
 <>
    {!isTextEdit &&
        <input 
        autoFocus
        onBlur={handleOnBlur}
        value = {value}	
        ref={refObj}
        onChange={handleOnChange}
        onFocus={handleFocus}
    /> 
    }
    {isTextEdit  &&
        <TextareaAutosize 
        style={{
            // "fontSize": "23px",
            // "border":"1px solid #FAF0E6",
            "width":"100%",
            "resize": "none",
            // "margin":"5px 0",
            // "padding":"3px",
          }}
        //   minRows={2}
          autoFocus
          onChange ={handleOnChangeText} 
          onFocus={handleFocus as any}
          onBlur={handleOnBlur as any}
          value={value}
          ref={refObj as any}

/>
} 
</>
  )
}

export default Input

