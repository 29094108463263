import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
import { GlossaryTypeItemApi } from '../types/types'
import store, { RootState } from './store'
import { oneCellType } from '../components/table/cellSimple'
import { setGlossaryStatus, setGlossaryStatusThunk, setLoadingStatus, setLoadingStatusThunk, setSavedStatusThunk, setSaveddStatus } from './rootSlice'
import { setSaveSta } from './tableSlice'



type initialStateType = {
  status: number,
  text: string,
  translation: string,
  session_type: number,
  session_id: number,
  session_count: number,
  // log: Array<any>,
  // glossary: Array<GlossaryTypeItemApi>
}



const initialState:initialStateType = {
  status: 0,
  text: '',
  translation: '',
  session_type: -1,
  session_id: -1,
  session_count: 0,
  // log: [],
  // glossary: []
}

const translatorSlice = createSlice({
  name: 'translator',
  initialState,
  reducers: {


    setTranslationStatus(state, action) {
      const fil = action.payload
      state.status = fil
    },

    setTextArea(state, action) {
      const fil = action.payload
      state.text = fil
    },
    setTranslator(state, action) {
      const fil = action.payload
      state.translation = fil
    },
    // setLog(state, action) {
    //   const fil = action.payload
    //   state.log = fil
    // },
    resetTranslator(state, action) {
      state.translation = ""
      state.text = ""
    },
    setStatusTranslator(state, action) {
      const fil = action.payload
      state.status = fil
    },
    setIdSession(state, action) {
      const fil = action.payload
      state.session_id = fil

    },

    setTypeSession(state, action) {
      const fil = action.payload
      state.session_type = fil

    },
    setCountSession(state, action) {
      const fil = action.payload
      state.session_count = fil
      
    },
  }
})



//===========================THUNK================================
export const setTranslatorRevertedThunkCreator =(body:string, type: number) => {
  return (dispatch:any, getState:any) => {
    let state = getState().translator
    let session_id:number = state.session_id
    let session_type:number = state.session_type
    let session_count:number = state.session_count


    dispatch(setTranslationStatus(1))

    if ((type === session_type) && (session_count === 1) )
    {
      // console.log('новый  ид')
      session_id = -1
      dispatch(setCountSession(1))
    }else if (session_id === -1){
      // console.log('begin новый ид')
      session_id = -1
      dispatch(setCountSession(1))
    } else if ((type !== session_type) && (session_count === 1) ){
      // console.log('trans старый ид')
      dispatch(setCountSession(2))
    } else if ((type !== session_type) && (session_count === 2)) {
      // console.log('погнали новый ид')
      session_id = -1
      dispatch(setCountSession(1))
    } else if ((type === session_type) && (session_count === 2) )
    {
      // console.log('старый ид  ид')
      
      dispatch(setCountSession(2))
    }
    
    if (type===0) {
      dispatch(setSaveSta({isSave:false, tableName:'translationlog'})) 
     gccApi.postTranslation(body, 1, session_id).then(data => {
     dispatch(setTranslator(data[0]))
     dispatch(setIdSession(data[1]))
     dispatch(setTypeSession(1))
     dispatch(setSaveSta({isSave:true, tableName:'translationlog'})) 
    //  dispatch(setTranslatorLogThunkCreator())

         })
    } else {
      dispatch(setSaveSta({isSave:false, tableName:'translationlog'})) 
      gccApi.postTranslation(body, 0, session_id).then(data => {
      dispatch(setTextArea(data[0]))
      dispatch(setIdSession(data[1]))
      dispatch(setTypeSession(0)) 
     dispatch(setSaveSta({isSave:true, tableName:'translationlog'})) 

      // dispatch(setTranslatorLogThunkCreator())

            })
    }
    dispatch(setTranslationStatus(1))
 }
 }





export const setTranslatorThunkCreator =(body:string, type: number) => {
  return (dispatch:any, getState:any) => {
    let state = getState().translator
    let session_id:number = state.session_id
    let session_type:number = state.session_type
    let session_count:number = state.session_count


    dispatch(setTranslationStatus(1))

    if ((type === session_type) && (session_count === 1) )
    {
      // console.log('новый  ид')
      session_id = -1
      dispatch(setCountSession(1))
    }else if (session_id === -1){
      // console.log('begin новый ид')
      session_id = -1
      dispatch(setCountSession(1))
    } else if ((type !== session_type) && (session_count === 1) ){
      // console.log('trans старый ид')
      dispatch(setCountSession(2))
    } else if ((type !== session_type) && (session_count === 2)) {
      // console.log('погнали новый ид')
      session_id = -1
      dispatch(setCountSession(1))
    } else if ((type === session_type) && (session_count === 2) )
    {
      // console.log('старый ид  ид')
      
      dispatch(setCountSession(2))
    }
    
    if (type===0) {
      dispatch(setSaveSta({isSave:false, tableName:'translationlog'})) 
     gccApi.postTranslation(body, 0, session_id).then(data => {
     dispatch(setTranslator(data[0]))
     dispatch(setIdSession(data[1]))
     dispatch(setTypeSession(0))
     dispatch(setSaveSta({isSave:true, tableName:'translationlog'})) 
    //  dispatch(setTranslatorLogThunkCreator())

         })
    } else {
      dispatch(setSaveSta({isSave:false, tableName:'translationlog'})) 
      gccApi.postTranslation(body, 1, session_id).then(data => {
      dispatch(setTextArea(data[0]))
      dispatch(setIdSession(data[1]))
      dispatch(setTypeSession(1)) 
     dispatch(setSaveSta({isSave:true, tableName:'translationlog'})) 

      // dispatch(setTranslatorLogThunkCreator())

            })
    }
    dispatch(setTranslationStatus(1))
 }
 }

 export const timerTranslatorThunkCreator =(body:string, type: number, timer:number=3000) => {
  return (dispatch:any, getState:any) => {
    if (type === 0) {
      dispatch(setTextArea(body)) 
    } else {
      dispatch(setTranslator(body)) 
    }
    let teme =  () => {
      dispatch(setTranslatorThunkCreator(body, type))
    } 
      let timeout:any  = setTimeout(teme,timer);
          while (timeout--) {
      window.clearTimeout(timeout); // will do nothing if no timeout with id is present
  } 
 }
 }

//  export const setTranslatorLogThunkCreator =() => {
//   return (dispatch:any, getState:any) => {

//     gccApi.getTranslationLog().then(data => {

//       // setTable(state, action) {
//       //   const {tableName, data}:setTablePayloadType = action.payload
//       //   state.tables[tableName] = data
//       //   state.talbesFilters[tableName] = {}
//       //   state.isSave[tableName] = false
//       // },


//       dispatch(setLog(data))
//           })
//  }
//  }

 export const updateGlossaryThunk =() => {
  return (dispatch:any, getState:any) => {
    let state:RootState = getState()
    gccApi.setGlossary().then(data => {
// console.log(data)

let finalString:string = ''
let session_type:number = state.translator.session_type
let leftText:string = state.translator.text
let rightText:string = state.translator.translation

if (session_type===0) {
  finalString = leftText
} else {
  finalString = rightText
}



dispatch(setTranslatorThunkCreator(finalString, session_type))
      dispatch(setGlossaryStatusThunk())
 
          })
    
 }}


export const { resetTranslator, setTextArea,  setTranslator, setStatusTranslator,
  setIdSession,setTypeSession, setCountSession, setTranslationStatus } = translatorSlice.actions
export default translatorSlice.reducer