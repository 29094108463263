import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import TableUniveralSet from '../components/table/tableUniveralset'
import { useState } from 'react'

const CalendarPage = ({dictFunc}:any) => {

  let [isUpdate, setIsUpdate] = useState(false)
  let t = 0
  let onSave=() =>{

    setIsUpdate(true)
    console.log('iiiiii')
    setIsUpdate(false)

  }

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['task/years'])

    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>


<TableUniveralSet
  headers={[]}
  apiPath={'task/initiatecalendar'}
  apiParametres={{"start":'2023'}}
/>


<TableUniveral 
        apiPath='task/years'
        headers={[]}
        tableHeader='Годы'
    //    editableCols={['code']}
    //    postCols={['code']}
        cols={['code']}
        isRemoveIdex
> 

{expandedRowContent &&
<>

<TableUniveral 
apiPath='task/holydays'
apiParametres={{"year_id":expandedRowContent.id}}
headers={[
// "id",
"holiday_name",
"code",
"start",
"end",
"year",
"day_type",
]}
tableHeader='Праздники'
editableCols={['code', 'start', 'end']}
postCols={['code']}
cols={[
// "id",
"holiday_name",
"code",
"start",
"end",
"year",
"day_type",
]}
onSave={onSave}
selectors={
{
  "day_type": {"apiPath":'task/daytypes', "value":'id',"label":'code'},
  "holiday_name": {"apiPath":'task/holydayname', "value":'id',"label":'code'},
  "year": {"apiPath":'task/years', "value":'id',"label":'code', isLocked:true},

}}
/> 


<TableUniveral 
                   apiPath='task/calendar'
                   apiParametres={{'year':expandedRowContent.code}}
                   headers={[
                    "id",
                    "date_calendar",
                    "day",
                    "month",
                    "year",
                    "weekday",
                    "day_type",
                    "holiday_type",
                    "date_design",
                    // "workday_count",
                    // "workday_count_fill",
                    // "total_month",
                    // "total_week",
                   ]}
                   tableHeader='calendar1'
                   cols={[        
                    "id",
                    "date_calendar",
                    "day",
                    "month",
                    "year",
                    "weekday",
                    "day_type",
                    "holiday_type",
                    "date_design",
                    // "workday_count",
                    // "workday_count_fill",
                    // "total_month",
                    // "total_week",
                ]}


                   selectors={
                    {
                      "day_type": {"apiPath":'task/daytypes', "value":'id',"label":'code', isLocked:true},
                      "holiday_type": {"apiPath":'task/holydays', "value":'id',"label":'code', isLocked:true},
                      "weekday": {"apiPath":'task/daynames', "value":'id',"label":'code', isLocked:true},
                      "month": {"apiPath":'task/monthnames', "value":'id',"label":'code', isLocked:true},
                  }
                }
               />


</>}
{!expandedRowContent &&<></>}




</TableUniveral>



            
                <TableUniveral 
                   apiPath='task/holydayname'
                   headers={[
                    "code",
                    "start",
                    "end",
                    "region",
                    "is_active",

                   ]}
                   tableHeader='Типовые праздники'
                   editableCols={['code','start','end']}
                   postCols={['code']}
                   cols={[
                    "code",
                    "start",
                    "end",
                    "region",
                    "is_active",
                    ]}

                    selectors={
                        {
                          "is_active": {"apiPath":'task/bool', "value":'id',"label":'code'},
                          "region": {"apiPath":'task/region', "value":'id',"label":'code'},
                      }
                    }

               /> 

                {/* <TableUniveral 
                   apiPath='task/country'
                   headers={['code']}
                   tableHeader='Страны'
                   editableCols={['code']}
                   postCols={['code']}
                   cols={['code']}
               />  */}

                <TableUniveral 
                   apiPath='task/region'
                   headers={[
                    "code",
                    "country",
                    "timezone_field",
                    "language",
                   ]}
                   tableHeader='регионы'
                   editableCols={[
                    "code",
                    "country",
                    "timezone_field",
                    "language",

                   ]}
                   postCols={['code']}
                   cols={[
                    "code",
                    "country",
                    "timezone_field",
                    "language",
                   ]}
                   selectors={
                    {
                      "country": {"apiPath":'task/country', "value":'id',"label":'code'},
                      "language": {"apiPath":'interface/languages', "value":'id',"label":'code'},
                  }}
                   
               />       



 
                {/* <TableUniveral 
                   apiPath='task/daytypes'
                   headers={[]}
                   tableHeader='Типы дней'
                   editableCols={['code']}
                   postCols={['code']}
                   cols={['code']}
               />   */}
                             
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(CalendarPage)




//Нельзя изменять прошлое
//Будующее изменять можно
//Нужно проверять 
//календарь на один год вперед 

//историю пересчитываем только по выходным дням
//иницииируем календарь - исходные данные начальная дата - до текущей даты плюс один год
//инициируем календарь только если таблица пустая