import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  TableUniversalType } from '../../types/types'
import { initiateFilter, setTableProperty, setTablePropertyiePayloadType, setTableThunk, setTableType } from '../../redux/tableSlice'
import { utilities } from '../../redux/utilities'

const TableUniveralSet = (props:TableUniversalType) => {
    let dispatch:any = useDispatch()
    let apiPath = props.apiPath
    let prefix = props.apiPrefix
    let apiParametres = props.apiParametres


    useEffect(() => {
      let payloadTable:setTableType  = {
        apiPath : apiPath,
        postCols : props.postCols,
        prefix: props.apiPrefix,
        parameters: props.apiParametres
      }
      let payloadProperty:setTablePropertyiePayloadType = {
        tableName: apiPath,
        prefix: prefix,
        param: props.apiParametres
      }
      dispatch(setTableThunk(payloadTable))
      dispatch(setTableProperty(payloadProperty))
      dispatch(initiateFilter(apiPath))
    },[dispatch, apiPath, props.postCols, apiParametres, prefix,props.apiParametres, props.apiPrefix,])
    

  return (
<></>
  )
}

export default TableUniveralSet
