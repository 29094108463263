import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import NavbarNotification from '../components/navbar/NavbarNotification'
import Button from '../components/button/button'
import { useNavigate } from 'react-router-dom'
import TableUniveralSet from '../components/table/tableUniveralset'

const NotificationPage = ({dictFunc}:any) => {






    return (
        <div>

           <LayoutContainer>
           <NavbarManagement/>
           <NavbarNotification/>





                <TableUniveral 
                   apiPath='mom/notifications'
                   headers={[
                    "name",
                    "subject",
                    "body_text",
                    "is_inbox",
                    "type_inbox",
                    "type_outbox",
                    "from_addr",
                    "comment",

                   ]}
                   tableHeader='Текст уведомлений'
                  //  editableCols={['code','start','end']}
                   postCols={['name']}
                   cols={[
                    "name",
                    "subject",
                    "body_text",
                    "table_number",
                    "type_inbox",
                    "type_outbox",
                    "from_addr",
                    "comment",
                    ]
                  }
                    editableCols={
                      [
                        "name",
                        "body_text",
                        "comment",
                        "table_number",
                        "subject",
                        ]
                      
                    }
                    selectors={
                      {
                        "type_inbox":  {"apiPath":'transmittal/subtype', "value":'id',"label":'code'},
                        "type_outbox": {"apiPath":'transmittal/subtypeoout', "value":'id',"label":'code'},
                        "table_number": {"apiPath":'task/bool', "value":'id',"label":'code'},
                        "from_addr": {"apiPath":'mom/emailaddress', "value":'id',"label":'email'},
                    }
                    
                  
                    }



               /> 
<p>

&#60;header&#62;
&#60;footer&#62;
&#60;responsible&#62;
&#60;trmno&#62;
&#60;date&#62;
&#60;drcno&#62;
&#60;adrcno&#62;
&#60;path&#62;
&#60;filepath&#62;
&#60;deadline&#62;
&#60;comments&#62;
&#60;sitelink&#62;
&#60;fromname&#62;

</p>



                           
<TableUniveral 
                   apiPath='mom/notificationscopy'
                   headers={[

                    'номер',
                    'Принадлежность группе адресата',
                    'адрес',
                    'указывать в to'
   

                   ]}
                   tableHeader='Кого ставить в копию'
                  //  editableCols={['code','start','end']}
                   postCols={['comment']}
                   cols={[
                    'comment',
                    'team_type',
                    'to_addr',
                    'move_up'
                    ]
                  }
                    editableCols={
                      [
                        'comment'

                        ]
                      
                    }
                    selectors={
                      {
                        "to_addr": {"apiPath":'mom/emailaddress', "value":'id',"label":'email'},
                        "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
                        "move_up": {"apiPath":'task/bool', "value":'id',"label":'code'},

                    }
                    
                  
                     }



               /> 



   
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(NotificationPage)
