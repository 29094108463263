import React, { useState, useEffect, useRef, MutableRefObject } from 'react'
import "./dropdown.css"
import DropdownButton from './dropdownbutton'
import DropdownContent from './dropdowncontent'
import { DropdownType, WithSettingsType } from '../../types/types'
import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'



const Dropdown = (props:DropdownType & WithSettingsType) => {
  
   
   
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const toggleDropdown = () => {
    setOpen(open => !open)
  }
  useEffect(() => {
    const hander = (eve:any) => {
      if (dropdownRef.current &&
        !dropdownRef.current.contains(eve.target)){
          setOpen(false)
        }
      }
        document.addEventListener('click',
        hander)
        return ()=> {
          document.removeEventListener('click', 
          hander)
        }
  },[dropdownRef])
  return (
    <div 
    className='dropdown' ref={dropdownRef}
    >
    <DropdownButton
    open = {open}
    togle = {toggleDropdown}
    > 
      {props.dictFunc(props.buttonText)}
    </DropdownButton>
    <DropdownContent
    open = {open}
    function ={props.function}
    toggle = {toggleDropdown}
    dictFunc={props.dictFunc}

    
    >
      {props.children}
    </DropdownContent>
    </div>
 
  )
}

// export default Dropdown

export default compose (
  withSettings
)(Dropdown)