import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
// import { selectorTotalType } from '../components/mdr_selector/MdrChoice'

 export type drcType = {



    "number": number,
    "drcfiles__file_name": string,
    "drcfiles__file_id__transmittal__number": number,

}



interface StringDict {
  [key: number]: string;
}

 type selectorTypeDictApi ={
  
  'type': StringDict,
}


 type itemSelectorType = {
  id: number
  groupId: number
  dbId: number
  buttonState: number
}

type initialStateType ={
  status: string,
  drc: Array<drcType>
  dictSelector: selectorTypeDictApi
  newSelector: Array<itemSelectorType>
}


const initialState:initialStateType = {
  newSelector: [],
  status: 'idle',
  drc: [],
  dictSelector: {
    'type': 
      {
        0: "NCE-CCE",
        1: "CCE-NCE",
        2: "EMA-NCE-CCE",
        3: "EMA-CCE-NCE",
        4: "ASSAI-CCE-IPMT",
        5: "ASSAI-IPMT-CCE"
    },
  },



}

const drcSlice = createSlice({
  name: 'drc',
  initialState,
  reducers: {
    setMdr(state, action) {
      const fil = action.payload
      state.drc = fil

    },

    setSelectorNew(state, action) {
      const fil = action.payload
      state.newSelector = fil
    },
    setSelectorDict(state, action) {
      const fil = action.payload
      state.dictSelector = fil
    },
    makeClick(state, action){
      const fil = action.payload

  

      state.newSelector[fil[1]].buttonState=fil[0]
    }

  }
})

//===========================THUNK================================


export  const DrcHandleClickThunkCreator =() => {
  return (dispatch:any ,getState:any) => {    
    gccApi.getDrcList().then(data => {
      dispatch(setMdr(data))    
    })
  }
 }


 export  const AdrcHandleClickThunkCreator =() => {
  return (dispatch:any ,getState:any) => {    
    gccApi.getAdrcList().then(data => {
      dispatch(setMdr(data))    
    })
  }
 }



export const { setMdr, setSelectorDict, makeClick, setSelectorNew } = drcSlice.actions
export default drcSlice.reducer
