import React from 'react'
import "./dropdowncontent.css"
import DropdownItem from './dropdownitem'
import { WithSettingsType } from '../../types/types'

const DropdownContent = (props:any&WithSettingsType) => {

  
  
return (
  <div 
    className={`dropdown-content ${props.open ? "content-open" : null}`}
  >
    {
    Object.keys(props.children).map((tag:any, index:any) => (
      <DropdownItem 
      function= {props.function}
      toggle = {props.toggle}
      row_id = {tag}
    

      key={index}>
        {props.dictFunc(props.children[tag])}
      </DropdownItem>
    ))  
   }
  </div>
)
}

export default DropdownContent





// import React from 'react'
// import "./dropdowncontent.css"
// import DropdownItem from './dropdownitem'
// import { WithSettingsType } from '../../types/types'

// const DropdownContent = (props:any&WithSettingsType) => {

  
  
// return (
//   <div 
//     className={`dropdown-content ${props.open ? "content-open" : null}`}
//   >
//     {
//     Object.values(props.children).map((tag:any, index:any) => (
//       <DropdownItem 
//       function= {props.function}
//       toggle = {props.toggle}
    

//       key={index}>
//         {props.dictFunc(tag)}
//       </DropdownItem>
//     ))  
//    }
//   </div>
// )
// }

// export default DropdownContent