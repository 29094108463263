import DateParser from "../date_parser/dateParser"

const CellRowspan = (props: {isOff:boolean, rowSpanNum:number, children?:any,
	isAddTime?: boolean | undefined
}) => {
	return (
<>
{!props.isOff && props.rowSpanNum > 0 &&
	<td
		rowSpan={props.rowSpanNum}
	>
		<DateParser
			content = {props.children}
			isAddTime = {props.isAddTime}
		/>	
	</td>
}
{!props.isOff && props.rowSpanNum === 0 &&
	<td>
		<DateParser
			content = {props.children}
			isAddTime = {props.isAddTime}
		/>
	</td>
}
</>
  )
}

export default CellRowspan