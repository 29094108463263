import React, { useEffect, useRef, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize';
import { translatorSelector } from '../selectors/selectors'
import { setTextArea, setTranslationStatus, setTranslator, setTranslatorRevertedThunkCreator, setTranslatorThunkCreator, updateGlossaryThunk } from '../redux/translatorSlice'
import { MutableRefObject } from "react"
import s from './TranslatorPage.module.css'
import TableUniveral from '../components/table/tableUniveral';
import withSettings from '../hoc/withSettings';
import NavbarTranslator from '../components/navbar/NavbarTranslator';
import NavbarIterate from '../components/navbar/NavbarIterate';
import { RootState } from '../redux/store';
import { modifyTableThunk, setCell } from '../redux/tableSlice';


const Lala = (props:any):any => {

  const [isPostChanged, setIsPostChanged] = useState(false)

  let dispatch:any = useDispatch()
  const transRef = props.transRef
  let transRefeng = props.transRefeng
  const translator:any = useSelector(translatorSelector)  
  
  let selectRow2 = useSelector((state:RootState) => state.tables.expandedRowContent['docreveal2'])
  let translationValue:string =''
  let selectRow = useSelector((state:RootState) => state.tables.tables['docreveal'])
  let glossary = useSelector((state:RootState) => state.tables.tables['glossary'])

  let rusText:any
  if (selectRow2) {
    rusText = selectRow2.paragraph_left
  } else {
    rusText = ''
  }

  if (selectRow){
    if (selectRow.length >0){
      if (selectRow[0]['is_translated'] == 0 ) {
        translationValue = translator.translation
      } else {
        translationValue = selectRow[0]['paragraph_right']
      }
    }
  }

  // translationValue = translator.translation


  let onTranslate = () => {


    console.log(translator['status'])
    let body = transRef.current.value
    dispatch(setTextArea(body))
 
    if (selectRow){
      if (selectRow.length >0){

        if (selectRow[0]['is_translated'] == 0 && (translator['status']==0))
          dispatch(setTranslatorRevertedThunkCreator(body, 0))
    }}
  }
    
  let onPostChangeEng = () => {
    // setIsPostChanged(true)
    let body = transRefeng.current.value

    dispatch(setTranslator(body))
    dispatch(setCell({
      'tableName':'docreveal', 
      'rowNumber':0, 
      'celName':'is_translated', 
      'data':1 }))

      dispatch(setCell({
        'tableName':'docreveal', 
        'rowNumber':0, 
        'celName':'is_finish', 
        'data':0 }))

    dispatch(setCell({
      'tableName':'docreveal', 
      'rowNumber':0, 
      'celName':'paragraph_right', 
      'data':body }))




    dispatch(setCell({
      'tableName':'docreveal', 
      'rowNumber':0, 
      'celName':'is_modified', 
      'data':1 }))
  }






 

  useEffect(() => {
    onTranslate()
    // setIsPostChanged(false)
  },[selectRow])

  return (
    
<div>
<div   className={s.wrapper2}>
  <div className={s.wrapper}>
    <TextareaAutosize
        style={{
          "fontSize": "23px",
          // "border":"1px solid #FAF0E6",
          "width":"100%",
          "margin":"5px 0",
          "padding":"3px",
          "border": "none",
          "resize": "none",
                
        }}
        minRows={2}
        value={rusText}
        ref={transRef}
    />
  </div>
  <div  className={s.wrapper}>
    <TextareaAutosize
        style={{
          "fontSize": "23px",
          // "border":"0px solid #FAF0E6",
          "width":"100%",
          "margin":"5px 0",
          "padding":"3px",
          "border": "none",
          "resize": "none",
        }}
        minRows={2}
        onChange={onPostChangeEng} 
        
        value={translationValue}
        ref={transRefeng}
    />
  </div>

</div>





</div>  
  )
}

const TranslatorContent = (props:any) => {
  let dispatch:any = useDispatch()
  const translator:any = useSelector(translatorSelector)  
// console.log(translator)

    const updateGlossary = () => {


      dispatch(setTextArea(props.transRef.current.value))

      dispatch(setCell({
        'tableName':'docreveal', 
        'rowNumber':0, 
        'celName':'is_translated', 
        'data':0 }))
        dispatch(setCell({
          'tableName':'docreveal', 
          'rowNumber':0, 
          'celName':'is_modified', 
          'data':0 }))

          dispatch(setCell({
            'tableName':'docreveal', 
            'rowNumber':0, 
            'celName':'is_finish', 
            'data':0 }))

        dispatch(setCell({
          'tableName':'docreveal', 
          'rowNumber':0, 
          'celName':'is_glosary', 
          'data':1 }))


      dispatch(updateGlossaryThunk())
      

    }

 
  
  return (
    <div
    style={{
      "display": "flex",
      "padding":"20px",
      
    
    }}
    >
      <div>
        <Lala
        transRefeng ={props.transRefeng}
        transRef={props.transRef}
  
        />
      </div>
      <div>

      <TableUniveral
    headers={['Russian','English']}
    apiPath={'glossary'}
    cols={["word_right", "word_left"]}
    editableCols = {["word_left","word_right"]}
    postCols =  {["word_left","word_right"]}
    isRemoveIdex = {true}
    onSave = {updateGlossary}
    tableHeader={'glossary_header'}
    />
      </div>
    </div>
  )
}

const TranslatorPageDocumentOneEng = (props:any) => {
    const transRefeng = useRef() as MutableRefObject<HTMLTextAreaElement>;
    const transRef = useRef() as MutableRefObject<HTMLTextAreaElement>;

    let dispatch:any = useDispatch()
    let selectRow = useSelector((state:RootState) => state.tables.expandedRowContent['docreveal2'])
    let id:number
    selectRow ? id = selectRow.id : id = 0
    let onPostSave = () => {
      let translation = transRefeng.current.value

      dispatch(setTranslationStatus(0))

      dispatch(setCell({
        'tableName':'docreveal', 
        'rowNumber':0, 
        'celName':'paragraph_right', 
        'data':translation }))

        dispatch(setCell({
          'tableName':'docreveal', 
          'rowNumber':0, 
          'celName':'is_translated', 
          'data':1 }))
  
        dispatch(setCell({
          'tableName':'docreveal', 
          'rowNumber':0, 
          'celName':'is_finish', 
          'data':1 }))
      dispatch(modifyTableThunk(undefined,undefined,true))
    }
    return (
        <div>
           <LayoutContainer>
            
               <NavbarTranslator/>
               <TableUniveral
        apiPath={'docreveal'}
        headers={[

          // "id",
          // "paragraph_left",
          // "paragraph_right",
          "comments",
          // "is_translated",
          // "is_glosary",
          // "is_modified",
          // "is_finish",
          "responsible",
        ]}
        isRemoveIdex={true}
        apiParametres={{id:id}}
        cols={[
          // "id",
          // "paragraph_left",
          // "paragraph_right",
          "comments",
          // "is_translated",
          // "is_glosary",
          // "is_modified",
          // "is_finish",
          "responsible",
          ]}
          selectors={
            {
              "responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
              "is_translated": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_glosary": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_modified": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_finish": {"apiPath":'task/bool', "value":'id',"label":'code'},

          }}

          editableCols={[
            "paragraph_right",
            "comments",

          ]}
/>

               <NavbarIterate
               apiPath={'docreveal2'}
              //  apiParametres={{'is_finish':'0'}}
               func = {onPostSave}               
               />
               <TranslatorContent
               transRefeng ={transRefeng}
               transRef={transRef}

               />


           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TranslatorPageDocumentOneEng)