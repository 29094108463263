import { createSlice } from '@reduxjs/toolkit'
import { utilities } from './utilities'

export type transmittalType = {
  'id':number
  'file_name':string
  'local_path':string
  'extension':number
  'transmittal__type': number
  'transmittal__number':number
  'transmittal__revision':number
  'transmittal__date': string
}

export type selectorTransmittalTypeApi ={
  'extension': Array<number>,
  'type': Array<number>,
  'number': Array<number>,
  'revision': Array<number>
}

interface StringDict {
  [key: number]: string;
}

export type selectorTypeDictApi ={
  
  'type': StringDict,
}


export type itemSelectorType = {
  id: number
  groupId: number
  dbId: number
  buttonState: number
}

type initialStateType ={
  status: string,
  transmittal: Array<transmittalType>
  dictSelector: selectorTypeDictApi
  newSelector: Array<itemSelectorType>
}


const initialState:initialStateType = {
  newSelector: [],
  status: 'idle',
  transmittal: [],
  dictSelector: {
    'type': 
      {
        0: "NCE-CCE",
        1: "CCE-NCE",
        2: "EMA-NCE-CCE",
        3: "EMA-CCE-NCE",
        4: "ASSAI-CCE-IPMT",
        5: "ASSAI-IPMT-CCE"
    },
  },
}

const transmittalSlice = createSlice({
  name: 'transmittal',
  initialState,
  reducers: {
    setMdr(state, action) {
      const fil = action.payload
      state.transmittal = fil
    },

    setSelectorNew(state, action) {
      const fil = action.payload
      state.newSelector = fil
      
    },
    setSelectorDict(state, action) {
      const fil = action.payload
      state.dictSelector = fil
    },
    makeClick(state, action){
      const fil = action.payload
      state.newSelector[fil[1]].buttonState=fil[0]
    }
  }
})

//===========================THUNK================================
 export const TranHandleClickThunkCreator =(id: number) => {
  return (dispatch:any ,getState:any) => {
    let bigArray = utilities.buildMainSelector([[0,1,4,5]])
    dispatch(setSelectorNew(bigArray)) 
    
    let listSelector:Array<itemSelectorType> = getState().transmittal.newSelector;
    let buttonState = listSelector[id].buttonState
    let newState: number
    // buttonState === 0 ? newState =1 : newState = 0
    if (buttonState===0){
      newState =1
    } else if (buttonState===2){
      newState = 1
    } else {
      newState = 0
    }
    dispatch(makeClick([newState, id]))
  }
 }

export const { setMdr, setSelectorDict, makeClick, setSelectorNew } = transmittalSlice.actions
export default transmittalSlice.reducer
