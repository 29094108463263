import { NavLink, useParams } from "react-router-dom"
import s from './Header.module.css'
import { useDispatch, useSelector } from "react-redux"
import { rootSelector } from "../../selectors/selectors"
import { RootState } from "../../redux/store"
import TableUniveral from "../table/tableUniveral"
import withSettings from "../../hoc/withSettings"
import { compose } from "@reduxjs/toolkit"
import DropdownUniveral from "../dropdown/dropdownUniversal"
import { refreshThunk, setTableThunk } from "../../redux/tableSlice"
import TableUniveralSet from "../table/tableUniveralset"



// const Header = () => {
//   // const tableState = useSelector((state:RootState) => state.tables.tables['interface/settings']) 
//   // let lan = 100
//   // if (tableState) {
    
//   //   lan = (tableState.filter((row)=>row.key ==='language')[0]['value'])
//   //   console.log(lan)
//   // }

//   return( 
//   <>
//     {/* <TableUniveral 
//   apiPath='interface/content'
//   headers={[]}
//   apiParametres={{'language':lan}}
//   tableHeader='Settings'
//   isHideTable={true}
// /> */}
//   <Param />
  
//   </>)
// }

export const Header = (props:any) => {
  const dispatch:any = useDispatch()
  const rootInfo = useSelector((state:RootState) => state.root)  
  let isCopyed = rootInfo.isCopyed
  let isSaved:boolean = rootInfo.isSaved
  let isDeleted:boolean = rootInfo.isDeleted
  let isSaveToGlassary:boolean = rootInfo.isSaveToGlassary
  let isMistake:boolean = rootInfo.isMistake
  let isLoading:boolean = rootInfo.isLoading


  const params = useParams()
  let team = params.team as string


  const finalFunction = () =>{

 dispatch(refreshThunk('interface/content'))
  }


  return (
    <div>
          <TableUniveralSet
           apiPath='task/teams'
           headers={[]}

           />
          <TableUniveralSet
           apiPath='task/teamsrev'
           headers={[]}

           />

{/* <TableUniveralSet
           apiPath='task/allteams'
           apiParametres={{'type':2}}

           headers={[]}

           /> */}
<div
style={{
  "justifyContent": "space-between",
  "display": "flex",
  "flexWrap": "wrap",
  "padding": "1px",
  // "border": "3px solid"
}}
>
        <div
        
        style={{
          "margin": "5px"
          // "border": "3px solid"
          // "width":  "50%", 
          // margin: 10px;
          // border: 3px solid;
          // border-radius: 15px;
          // padding: 10px;
          // border-color: lightgray;
        }}
        
        >
          <NavLink className={s.item} to="/"> 
          <svg width="98" height="24" viewBox="0 0 49 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_57_33)">
<path d="M1.13086 0.46875V9H0V0.46875H1.13086ZM6.28125 0.46875L2.73633 4.44727L0.744141 6.51562L0.556641 5.30859L2.05664 3.65625L4.92188 0.46875H6.28125ZM5.19141 9L2.0332 4.83984L2.70703 3.94336L6.53906 9H5.19141Z" fill="white"/>
<path d="M8.54883 2.66016V9H7.45898V2.66016H8.54883ZM7.37695 0.978516C7.37695 0.802734 7.42969 0.654297 7.53516 0.533203C7.64453 0.412109 7.80469 0.351562 8.01562 0.351562C8.22266 0.351562 8.38086 0.412109 8.49023 0.533203C8.60352 0.654297 8.66016 0.802734 8.66016 0.978516C8.66016 1.14648 8.60352 1.29102 8.49023 1.41211C8.38086 1.5293 8.22266 1.58789 8.01562 1.58789C7.80469 1.58789 7.64453 1.5293 7.53516 1.41211C7.42969 1.29102 7.37695 1.14648 7.37695 0.978516Z" fill="white"/>
<path d="M12.9434 2.66016V3.49219H9.51562V2.66016H12.9434ZM10.6758 1.11914H11.7598V7.42969C11.7598 7.64453 11.793 7.80664 11.8594 7.91602C11.9258 8.02539 12.0117 8.09766 12.1172 8.13281C12.2227 8.16797 12.3359 8.18555 12.457 8.18555C12.5469 8.18555 12.6406 8.17773 12.7383 8.16211C12.8398 8.14258 12.916 8.12695 12.9668 8.11523L12.9727 9C12.8867 9.02734 12.7734 9.05273 12.6328 9.07617C12.4961 9.10352 12.3301 9.11719 12.1348 9.11719C11.8691 9.11719 11.625 9.06445 11.4023 8.95898C11.1797 8.85352 11.002 8.67773 10.8691 8.43164C10.7402 8.18164 10.6758 7.8457 10.6758 7.42383V1.11914Z" fill="white"/>
<path d="M16.8691 2.66016V3.49219H13.4414V2.66016H16.8691ZM14.6016 1.11914H15.6855V7.42969C15.6855 7.64453 15.7188 7.80664 15.7852 7.91602C15.8516 8.02539 15.9375 8.09766 16.043 8.13281C16.1484 8.16797 16.2617 8.18555 16.3828 8.18555C16.4727 8.18555 16.5664 8.17773 16.6641 8.16211C16.7656 8.14258 16.8418 8.12695 16.8926 8.11523L16.8984 9C16.8125 9.02734 16.6992 9.05273 16.5586 9.07617C16.4219 9.10352 16.2559 9.11719 16.0605 9.11719C15.7949 9.11719 15.5508 9.06445 15.3281 8.95898C15.1055 8.85352 14.9277 8.67773 14.7949 8.43164C14.666 8.18164 14.6016 7.8457 14.6016 7.42383V1.11914Z" fill="white"/>
<path d="M19.9219 8.34375L21.6855 2.66016H22.8457L20.3027 9.97852C20.2441 10.1348 20.166 10.3027 20.0684 10.4824C19.9746 10.666 19.8535 10.8398 19.7051 11.0039C19.5566 11.168 19.377 11.3008 19.166 11.4023C18.959 11.5078 18.7109 11.5605 18.4219 11.5605C18.3359 11.5605 18.2266 11.5488 18.0938 11.5254C17.9609 11.502 17.8672 11.4824 17.8125 11.4668L17.8066 10.5879C17.8379 10.5918 17.8867 10.5957 17.9531 10.5996C18.0234 10.6074 18.0723 10.6113 18.0996 10.6113C18.3457 10.6113 18.5547 10.5781 18.7266 10.5117C18.8984 10.4492 19.043 10.3418 19.1602 10.1895C19.2812 10.041 19.3848 9.83594 19.4707 9.57422L19.9219 8.34375ZM18.627 2.66016L20.2734 7.58203L20.5547 8.72461L19.7754 9.12305L17.4434 2.66016H18.627Z" fill="white"/>
<path d="M27.7383 7.76953V0H28.8281V9H27.832L27.7383 7.76953ZM23.4727 5.90039V5.77734C23.4727 5.29297 23.5312 4.85352 23.6484 4.45898C23.7695 4.06055 23.9395 3.71875 24.1582 3.43359C24.3809 3.14844 24.6445 2.92969 24.9492 2.77734C25.2578 2.62109 25.6016 2.54297 25.9805 2.54297C26.3789 2.54297 26.7266 2.61328 27.0234 2.75391C27.3242 2.89062 27.5781 3.0918 27.7852 3.35742C27.9961 3.61914 28.1621 3.93555 28.2832 4.30664C28.4043 4.67773 28.4883 5.09766 28.5352 5.56641V6.10547C28.4922 6.57031 28.4082 6.98828 28.2832 7.35938C28.1621 7.73047 27.9961 8.04688 27.7852 8.30859C27.5781 8.57031 27.3242 8.77148 27.0234 8.91211C26.7227 9.04883 26.3711 9.11719 25.9688 9.11719C25.5977 9.11719 25.2578 9.03711 24.9492 8.87695C24.6445 8.7168 24.3809 8.49219 24.1582 8.20312C23.9395 7.91406 23.7695 7.57422 23.6484 7.18359C23.5312 6.78906 23.4727 6.36133 23.4727 5.90039ZM24.5625 5.77734V5.90039C24.5625 6.2168 24.5938 6.51367 24.6562 6.79102C24.7227 7.06836 24.8242 7.3125 24.9609 7.52344C25.0977 7.73438 25.2715 7.90039 25.4824 8.02148C25.6934 8.13867 25.9453 8.19727 26.2383 8.19727C26.5977 8.19727 26.8926 8.12109 27.123 7.96875C27.3574 7.81641 27.5449 7.61523 27.6855 7.36523C27.8262 7.11523 27.9355 6.84375 28.0137 6.55078V5.13867C27.9668 4.92383 27.8984 4.7168 27.8086 4.51758C27.7227 4.31445 27.6094 4.13477 27.4688 3.97852C27.332 3.81836 27.1621 3.69141 26.959 3.59766C26.7598 3.50391 26.5234 3.45703 26.25 3.45703C25.9531 3.45703 25.6973 3.51953 25.4824 3.64453C25.2715 3.76562 25.0977 3.93359 24.9609 4.14844C24.8242 4.35938 24.7227 4.60547 24.6562 4.88672C24.5938 5.16406 24.5625 5.46094 24.5625 5.77734Z" fill="white"/>
<path d="M30.2285 5.90039V5.76562C30.2285 5.30859 30.2949 4.88477 30.4277 4.49414C30.5605 4.09961 30.752 3.75781 31.002 3.46875C31.252 3.17578 31.5547 2.94922 31.9102 2.78906C32.2656 2.625 32.6641 2.54297 33.1055 2.54297C33.5508 2.54297 33.9512 2.625 34.3066 2.78906C34.666 2.94922 34.9707 3.17578 35.2207 3.46875C35.4746 3.75781 35.668 4.09961 35.8008 4.49414C35.9336 4.88477 36 5.30859 36 5.76562V5.90039C36 6.35742 35.9336 6.78125 35.8008 7.17188C35.668 7.5625 35.4746 7.9043 35.2207 8.19727C34.9707 8.48633 34.668 8.71289 34.3125 8.87695C33.9609 9.03711 33.5625 9.11719 33.1172 9.11719C32.6719 9.11719 32.2715 9.03711 31.916 8.87695C31.5605 8.71289 31.2559 8.48633 31.002 8.19727C30.752 7.9043 30.5605 7.5625 30.4277 7.17188C30.2949 6.78125 30.2285 6.35742 30.2285 5.90039ZM31.3125 5.76562V5.90039C31.3125 6.2168 31.3496 6.51562 31.4238 6.79688C31.498 7.07422 31.6094 7.32031 31.7578 7.53516C31.9102 7.75 32.0996 7.91992 32.3262 8.04492C32.5527 8.16602 32.8164 8.22656 33.1172 8.22656C33.4141 8.22656 33.6738 8.16602 33.8965 8.04492C34.123 7.91992 34.3105 7.75 34.459 7.53516C34.6074 7.32031 34.7188 7.07422 34.793 6.79688C34.8711 6.51562 34.9102 6.2168 34.9102 5.90039V5.76562C34.9102 5.45312 34.8711 5.1582 34.793 4.88086C34.7188 4.59961 34.6055 4.35156 34.4531 4.13672C34.3047 3.91797 34.1172 3.74609 33.8906 3.62109C33.668 3.49609 33.4062 3.43359 33.1055 3.43359C32.8086 3.43359 32.5469 3.49609 32.3203 3.62109C32.0977 3.74609 31.9102 3.91797 31.7578 4.13672C31.6094 4.35156 31.498 4.59961 31.4238 4.88086C31.3496 5.1582 31.3125 5.45312 31.3125 5.76562Z" fill="white"/>
<path d="M39.8965 8.22656C40.1543 8.22656 40.3926 8.17383 40.6113 8.06836C40.8301 7.96289 41.0098 7.81836 41.1504 7.63477C41.291 7.44727 41.3711 7.23438 41.3906 6.99609H42.4219C42.4023 7.37109 42.2754 7.7207 42.041 8.04492C41.8105 8.36523 41.5078 8.625 41.1328 8.82422C40.7578 9.01953 40.3457 9.11719 39.8965 9.11719C39.4199 9.11719 39.0039 9.0332 38.6484 8.86523C38.2969 8.69727 38.0039 8.4668 37.7695 8.17383C37.5391 7.88086 37.3652 7.54492 37.248 7.16602C37.1348 6.7832 37.0781 6.37891 37.0781 5.95312V5.70703C37.0781 5.28125 37.1348 4.87891 37.248 4.5C37.3652 4.11719 37.5391 3.7793 37.7695 3.48633C38.0039 3.19336 38.2969 2.96289 38.6484 2.79492C39.0039 2.62695 39.4199 2.54297 39.8965 2.54297C40.3926 2.54297 40.8262 2.64453 41.1973 2.84766C41.5684 3.04688 41.8594 3.32031 42.0703 3.66797C42.2852 4.01172 42.4023 4.40234 42.4219 4.83984H41.3906C41.3711 4.57812 41.2969 4.3418 41.168 4.13086C41.043 3.91992 40.8711 3.75195 40.6523 3.62695C40.4375 3.49805 40.1855 3.43359 39.8965 3.43359C39.5645 3.43359 39.2852 3.5 39.0586 3.63281C38.8359 3.76172 38.6582 3.9375 38.5254 4.16016C38.3965 4.37891 38.3027 4.62305 38.2441 4.89258C38.1895 5.1582 38.1621 5.42969 38.1621 5.70703V5.95312C38.1621 6.23047 38.1895 6.50391 38.2441 6.77344C38.2988 7.04297 38.3906 7.28711 38.5195 7.50586C38.6523 7.72461 38.8301 7.90039 39.0527 8.0332C39.2793 8.16211 39.5605 8.22656 39.8965 8.22656Z" fill="white"/>
<path d="M47.332 7.31836C47.332 7.16211 47.2969 7.01758 47.2266 6.88477C47.1602 6.74805 47.0215 6.625 46.8105 6.51562C46.6035 6.40234 46.291 6.30469 45.873 6.22266C45.5215 6.14844 45.2031 6.06055 44.918 5.95898C44.6367 5.85742 44.3965 5.73438 44.1973 5.58984C44.002 5.44531 43.8516 5.27539 43.7461 5.08008C43.6406 4.88477 43.5879 4.65625 43.5879 4.39453C43.5879 4.14453 43.6426 3.9082 43.752 3.68555C43.8652 3.46289 44.0234 3.26562 44.2266 3.09375C44.4336 2.92188 44.6816 2.78711 44.9707 2.68945C45.2598 2.5918 45.582 2.54297 45.9375 2.54297C46.4453 2.54297 46.8789 2.63281 47.2383 2.8125C47.5977 2.99219 47.873 3.23242 48.0645 3.5332C48.2559 3.83008 48.3516 4.16016 48.3516 4.52344H47.2676C47.2676 4.34766 47.2148 4.17773 47.1094 4.01367C47.0078 3.8457 46.8574 3.70703 46.6582 3.59766C46.4629 3.48828 46.2227 3.43359 45.9375 3.43359C45.6367 3.43359 45.3926 3.48047 45.2051 3.57422C45.0215 3.66406 44.8867 3.7793 44.8008 3.91992C44.7188 4.06055 44.6777 4.20898 44.6777 4.36523C44.6777 4.48242 44.6973 4.58789 44.7363 4.68164C44.7793 4.77148 44.8535 4.85547 44.959 4.93359C45.0645 5.00781 45.2129 5.07812 45.4043 5.14453C45.5957 5.21094 45.8398 5.27734 46.1367 5.34375C46.6562 5.46094 47.084 5.60156 47.4199 5.76562C47.7559 5.92969 48.0059 6.13086 48.1699 6.36914C48.334 6.60742 48.416 6.89648 48.416 7.23633C48.416 7.51367 48.3574 7.76758 48.2402 7.99805C48.127 8.22852 47.9609 8.42773 47.7422 8.5957C47.5273 8.75977 47.2695 8.88867 46.9688 8.98242C46.6719 9.07227 46.3379 9.11719 45.9668 9.11719C45.4082 9.11719 44.9355 9.01758 44.5488 8.81836C44.1621 8.61914 43.8691 8.36133 43.6699 8.04492C43.4707 7.72852 43.3711 7.39453 43.3711 7.04297H44.4609C44.4766 7.33984 44.5625 7.57617 44.7188 7.75195C44.875 7.92383 45.0664 8.04688 45.293 8.12109C45.5195 8.19141 45.7441 8.22656 45.9668 8.22656C46.2637 8.22656 46.5117 8.1875 46.7109 8.10938C46.9141 8.03125 47.0684 7.92383 47.1738 7.78711C47.2793 7.65039 47.332 7.49414 47.332 7.31836Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.6521 0C30.6686 0.0579886 30.6864 0.11528 30.7056 0.171875C30.8385 0.5625 31.0299 0.904297 31.2799 1.19727C31.5338 1.48633 31.8385 1.71289 32.1939 1.87695C32.5494 2.03711 32.9498 2.11719 33.3951 2.11719C33.8404 2.11719 34.2388 2.03711 34.5904 1.87695C34.9459 1.71289 35.2486 1.48633 35.4986 1.19727C35.7525 0.904297 35.9459 0.5625 36.0787 0.171875C36.0979 0.11528 36.1158 0.0579886 36.1322 0H35.0079C34.9384 0.195905 34.848 0.374291 34.7369 0.535156C34.5885 0.75 34.401 0.919922 34.1744 1.04492C33.9517 1.16602 33.692 1.22656 33.3951 1.22656C33.0943 1.22656 32.8306 1.16602 32.6041 1.04492C32.3775 0.919922 32.1881 0.75 32.0357 0.535156C31.9246 0.374291 31.8342 0.195905 31.7647 0H30.6521ZM37.4807 0C37.4946 0.0557804 37.5097 0.111119 37.526 0.166016C37.6431 0.544922 37.817 0.880859 38.0474 1.17383C38.2818 1.4668 38.5748 1.69727 38.9263 1.86523C39.2818 2.0332 39.6978 2.11719 40.1744 2.11719C40.6236 2.11719 41.0357 2.01953 41.4107 1.82422C41.7857 1.625 42.0885 1.36523 42.3189 1.04492C42.5525 0.721829 42.6794 0.373522 42.6996 0H41.6682C41.6481 0.236702 41.5682 0.448291 41.4283 0.634766C41.2877 0.818359 41.108 0.962891 40.8892 1.06836C40.6705 1.17383 40.4322 1.22656 40.1744 1.22656C39.8385 1.22656 39.5572 1.16211 39.3306 1.0332C39.108 0.900391 38.9303 0.724609 38.7974 0.505859C38.7057 0.350111 38.6327 0.181491 38.5785 0H37.4807Z" fill="white"/>
</g>
<defs>

</defs>
</svg>

          
          {/* {props.dictFunc('wellcome header')} */}
          </NavLink> 
        </div>

        <div
                style={{
                  // "width":  "50%", 
                  "margin": "10px",
                  "display": "flex",
                  // "border": "3px solid"
                  // border-radius: 15px;
                  // padding: 10px;
                  // border-color: lightgray;
                }}
        ><div
        style={{
          // "marginRight": "10px",
          "padding": '5px',
          // "paddingLeft": '25px',
          // "paddingRight": '25px',
          "fontSize": '15px',
          // "backgroundColor": "orange",

          // "border": "1px solid",
          // "borderRadius": "5px",
          // padding: 10px;
          // border-color: lightgray;
        }}
        
        >{team.toUpperCase()}</div>
          <DropdownUniveral 
              itemsApiPath= {'interface/languages'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {0}
              finalFunction ={finalFunction}
          />
        </div>
</div>

   {isCopyed && <div className={s.notification}>{props.dictFunc('copy')}</div>}
   {isSaved && <div className={s.notification}>{props.dictFunc('saved')}</div>}
   {isDeleted && <div className={s.notification}>{props.dictFunc('deleted')}</div>}
   {isSaveToGlassary && <div className={s.notification}>{props.dictFunc('glossary')}</div>}
   {isMistake && <div className={s.notification}>{props.dictFunc('mistake')}</div>}
   {isLoading && <div className={s.notification}>{props.dictFunc('loading')}</div>}
    </div>
  )
}


export default compose (
  withSettings
)(Header)