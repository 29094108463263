import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
import { useDispatch } from 'react-redux';



interface TeamApiList {
  [key: string]: string;
}

export type rootTypeDictApi ={
  'team': TeamApiList; 
}



export type RootStateinitialStateType ={
  show_modal: boolean,
  status: string,
  team: TeamApiList,
  isCopyed: boolean,
  isSaved:boolean
  isDeleted:boolean
  isSaveToGlassary:boolean 
  isMistake:boolean
  isLoading:boolean

}


const initialState:RootStateinitialStateType = {
  show_modal: false,
  status: 'idle',
  team: {1:'11'},
  isCopyed: false,
  isSaved: false,
  isDeleted: false,
  isSaveToGlassary:false,
  isMistake: false,
  isLoading: false
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setRoot(state, action) {
      const fil = action.payload
      state.team = fil
    
    },
    setCopyedStatus(state, action) {
      state.isCopyed = action.payload
    },
    setSaveddStatus(state, action) {
      state.isSaved = action.payload
    },
    setDeletedStatus(state, action) {
      state.isDeleted = action.payload
    },
    setGlossaryStatus(state, action) {
      state.isSaveToGlassary = action.payload
    },
    setMistakeStatus(state, action) {
      state.isMistake = action.payload
    },
    setLoadingStatus(state, action) {
      state.isLoading = action.payload
    },

  }
})

//===========================THUNK================================

let timeout = 1000
export  const getRootThunkCreator =() => {
  return (dispatch:any ,getState:any) => {

    gccApi.getRootDict().then(data => {
      dispatch(setRoot(data.team)) 
    }) 
  }
 }

 export  const setCopyedStatusThunkCreator =() => {
  return (dispatch:any) => {
    dispatch(setCopyedStatus(true))
    setTimeout(()=>{
      dispatch(setCopyedStatus(false))
    },timeout)
  }
 }

 export  const setSavedStatusThunk =() => {
  return (dispatch:any) => {
    dispatch(setSaveddStatus(true))
    setTimeout(()=>{
      dispatch(setSaveddStatus(false))
    },timeout)
  }
 }

 export  const setDeletedStatusThunk =() => {
  return (dispatch:any) => {
    dispatch(setDeletedStatus(true))
    setTimeout(()=>{
      dispatch(setDeletedStatus(false))
    },timeout)
  }
 }

 export  const setGlossaryStatusThunk =() => {
  return (dispatch:any) => {
    dispatch(setGlossaryStatus(true))
    setTimeout(()=>{
      dispatch(setGlossaryStatus(false))
    },timeout)
  }
 }

 export  const setMistakeStatusThunk =() => {
  return (dispatch:any) => {
    dispatch(setMistakeStatus(true))
    setTimeout(()=>{
      dispatch(setMistakeStatus(false))
    },timeout)
  }
 }

 export  const setLoadingStatusThunk =() => {
  return (dispatch:any) => {
    dispatch(setLoadingStatus(true))
    setTimeout(()=>{
      dispatch(setLoadingStatus(false))
    },timeout)
  }
 }


export const { setRoot, setCopyedStatus, setSaveddStatus, setDeletedStatus, 
  setGlossaryStatus, setMistakeStatus, setLoadingStatus} = rootSlice.actions
export default rootSlice.reducer







    // let teme =  () => {
    //   dispatch(setCopyedStatus(false))
    // } 
    //   let timeout:any  = setTimeout(teme,1000);
    //       while (timeout--) {
    //   window.clearTimeout(timeout); // will do nothing if no timeout with id is present
    // } 