import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import NavbarNotification from '../components/navbar/NavbarNotification'

const NotificationSentPage = ({dictFunc}:any) => {
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
           <NavbarNotification/>
                <TableUniveral 
                   apiPath='mom/outbox'
                   headers={[
                    "date",
                    "subject",
                    "body",
                    "fro_addr",
                    "to_addr",
                    "cc_addr",
      
                   ]}
                   cols={[
                    "date",
                    "subject",
                    "body",
                    "fro_addr",
                    "to_addr",
                    "cc_addr",
      
                   ]}
                   isAddTime={true}
                   tableHeader='Отправленные сообщения'



               /> 

   
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(NotificationSentPage)
