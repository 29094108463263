import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'

const TeamsPage = ({dictFunc}:any) => {
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
               <TableUniveral 
                   apiPath='task/teams'
                   headers={[
                    "name",
                    "login",
                    "comment",
                    "team_type",
                    "isGroup",
                    "address",
                    "isManager",
                    "isVip",
                    "isAdmin",
                    "isPerson",
                    "isLinear",

                   ]}
                   tableHeader='Команды'
                   cols={[
                    // "id",
                    "name",
                    "login",
                    "comment",
                    "team_type",
                    "isGroup",
                    "address",
                    "isManager",
                    "isVip",
                    "isAdmin",
                    "isPerson",
                    "isLinear",
                ]}
                postCols={["name"]}
                editableCols={[                    
                    "name",
                    "comment",
                    "login"
                ]}
                selectors={
                    {

                      "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
                      "isGroup": {"apiPath":'task/bool', "value":'id',"label":'code'},
                      "isPerson": {"apiPath":'task/bool', "value":'id',"label":'code'},
                      "isManager": {"apiPath":'task/bool', "value":'id',"label":'code'},
                      "isVip": {"apiPath":'task/bool', "value":'id',"label":'code'},
                      "isAdmin": {"apiPath":'task/bool', "value":'id',"label":'code'},
                      "address": {"apiPath":'mom/emailaddress', "value":'id',"label":'email'},
                      "isLinear": {"apiPath":'task/bool', "value":'id',"label":'code'},


                    }}
               />              
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(TeamsPage)
