import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useSelector } from 'react-redux'
import MdrChoice from '../components/mdr_selector/MdrChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import { selectorType } from '../redux/mdrSlice'
import { utilities } from '../redux/utilities'
import withSettings from '../hoc/withSettings'
import NceRev from '../components/ui/NceRev'
import FilesUi from '../components/ui/FilesUi'

const Lala = ({dictFunc}:any) => {

  const buttonState = useSelector((state:RootState)=>state.mdr.newSelector)
  let selector:selectorType = utilities.mainSelectorToIndexes(buttonState, 1)
  let fac:string = utilities.listToStringQuery(selector.fac)
  let sub:string = utilities.listToStringQuery(selector.sub)
  let mark:string = utilities.listToStringQuery(selector.mark)
  let doc:string = utilities.listToStringQuery(selector.doc)

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mdrfilter'])

  

  return (
<div>
      <MdrChoice/>

      <TableUniveral
    apiPath='mdrfilter'

    apiParametres={      
      {
      'facility': fac,
      'subtitle': sub,
      'mark': mark,
      'doc_type': doc,
    }}
    
    headers={
      [
      // "id",
      "doc_number",
      "doc_title",
      "doc_title",
      // "ifr_plan",
      "ifc_plan",
      "key_milestone",
      // "mileston",
      "nce_milestone_id",
      "spb_milestone_id",
      "comments",
      // "revision",
      "status_id",
      ]
    }
    cols={[
      // "id",
      "doc_number",
      "doc_title",
      // "ifr_plan",
      "ifc_plan",
      "key_milestone",
      // "mileston",
      "nce_milestone_id",
      "spb_milestone_id",
      "comments",
      // "revision",
      "status_id",

    ]}
    selectors={
      {
        "status_id": {"apiPath":'mdrstatus', "value":'id',"label":'code', isLocked:true},
        "nce_milestone_id": {"apiPath":'milestonence', "value":'id',"label":'code', isLocked:true},
        "spb_milestone_id": {"apiPath":'milestonespb', "value":'id',"label":'code', isLocked:true},
    }
    
    }
    // editableCols={[
    //   'status'
    // ]}
    separate ={["doc_title",'/']}
  //   rowSpanCols={[      
  //   "ifr_plan",
  //   "ifc_plan",
  //   "key_milestone",
  //   "status_id",
  //   "revision",
  // ]}    
    >

{expandedRowContent &&
<>
{/* <TableUniveral
  headers={[
    "имя файла", 'N ТРМ','Тип трансмиттала',"Дата",
  ]}
  apiPath='transmittal/files'
  apiParametres={{
    'search': expandedRowContent.doc_number, 
    'extension':'0',
  }}
  cols={[
    "file_name", 'trm_no','trm_type',"date_modified",
  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}
/> */}

<FilesUi
  doc_name={expandedRowContent.doc_number}
/>
<NceRev
  doc_name={expandedRowContent.doc_number}
/>



</>
}
{!expandedRowContent &&<></>}

    </TableUniveral>






</div>   
  )
}

const MdrPage = (props:any) => {
    return (
        <div>
           <LayoutContainer>
               <h1>{props.dictFunc('mdr')}</h1>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(MdrPage)
// {dictFunc}:any
// {dictFunc('email')}
// export default compose (
// withSettings
// )(EmailPage)
// {...props}