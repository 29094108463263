import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useSelector } from 'react-redux'
import MdrChoice from '../components/mdr_selector/MdrChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import { selectorType } from '../redux/mdrSlice'
import { utilities } from '../redux/utilities'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { RequestSettings } from './TodoPage'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import NavbarMdr from '../components/navbar/NavbarMdr'
import TableUniveralSet from '../components/table/tableUniveralset'
import { gccApi } from '../api/api'
import Button from '../components/button/button'

const Lala = ({dictFunc}:any) => {


  let doc_type: number | undefined = RequestSettings('interface/settings', 'id', 17, 'value') as number
  let team: number | undefined = RequestSettings('interface/settings', 'id', 16, 'value') as number
  let complete_status: number | undefined = RequestSettings('interface/settings', 'id', 15, 'value') as number
  let mark: number | undefined = RequestSettings('interface/settings', 'id', 14, 'value') as number
  let spb_milestone: number | undefined = RequestSettings('interface/settings', 'id', 13, 'value') as number
  let nce_milestone: number | undefined = RequestSettings('interface/settings', 'id', 12, 'value') as number
  let mdr_status: number | undefined = RequestSettings('interface/settings', 'id', 18, 'value') as number
  let facility: number | undefined = RequestSettings('interface/settings', 'id', 19, 'value') as number
  let subtitle: number | undefined = RequestSettings('interface/settings', 'id', 20, 'value') as number
  




  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mdr22'])



  
// doc_type <0 && (doc_type=undefined)
team <0 && (team=undefined)
complete_status <0 && (complete_status=undefined)
// mark <0 && (mark=undefined)
spb_milestone <=0 && (spb_milestone=undefined)
nce_milestone <=0 && (nce_milestone=undefined)






  return (
<div>


<TableUniveralSet 
apiPath={'mdrnumber'} 
headers={[]}

/>

<TableUniveral
apiPath='mdr22'
postColsData = {{
  "doc_type": doc_type,
  "complete_status": complete_status,
  "team": team,
  "mark": mark,
  "facility": facility,
  "subtitle": subtitle,
  "spb_milestone": spb_milestone,
  "nce_milestone": nce_milestone,


}}

apiParametres={{
  // "doc_type": doc_type, 
  "team": team,
  "complete_status": complete_status,
  // "mark": mark,
  "spb_milestone": spb_milestone,
  // "nce_milestone": nce_milestone,
  // "status": mdr_status,
}}






headers={
  [
    "doc_number",
    "doc_title",
    "facility",
    "subtitle",
    "mark",
    "doc_type",
    "serial_number",
    "nce_milestone",
    "spb_milestone",
    "complete_status",
    "deadline",
    "team",
    "comments",

  ]
}
cols={[
  "doc_number",
  "doc_title",
  "facility",
  "subtitle",
  "mark",
  "doc_type",
  "serial_number",
  "nce_milestone",
  "spb_milestone",
  "complete_status",
  "deadline",
  "team",
  "comments",
]}
selectors={
  {

    "facility": {"apiPath":'facility', "value":'id',"label":'code'},
    "subtitle": {"apiPath":'subtitle', "value":'id',"label":'code_new'},
    "mark": {"apiPath":'mark', "value":'id',"label":'code'},
    "doc_type": {"apiPath":'doctype', "value":'id',"label":'code'},


    "status": {"apiPath":'mdrstatus', "value":'id',"label":'code', isLocked:true},
    
    "nce_milestone": {"apiPath":'milestonence', "value":'id',"label":'code'},
    "spb_milestone": {"apiPath":'milestonespb', "value":'id',"label":'code'},
    // "deadline": {"apiPath":'task/calendar', "value":'id',"label":'date_calendar'},
    "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
    "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
}

}
postCols={['doc_number']}
editableCols={[
  'doc_number', 'deadline', "serial_number", "doc_title",  "comments"
  
]}


>




</TableUniveral>



</div>   
  )
}

const MdrCreatePage = (props:any) => {
  let onClick = ()=>{

    gccApi.getIncludeMdr().then(data => {

      console.log(data)

          })    

  }
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
           <NavbarMdr/>
           <div
            style={{"display": "flex"}}
          >

          {/* <DropdownUniveral 
              itemsApiPath= {'task/bool'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {7}
              finalFunction ={()=>1+1}
          /> */}



          Команда<DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {16}
              finalFunction ={()=>1+1}
          />
          NCE milestone<DropdownUniveral 
              itemsApiPath= {'milestonence'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {12}
              finalFunction ={()=>1+1}
          />
          SPB milestone<DropdownUniveral 
              itemsApiPath= {'milestonespb'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {13}
              finalFunction ={()=>1+1}
          />
          Complete status<DropdownUniveral 
              itemsApiPath= {'task/completestatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {15}
              finalFunction ={()=>1+1}
          />

          Не в фильтре, но для создания:

          {/* "facility": {"apiPath":'facility', "value":'id',"label":'code'},
    "subtitle": {"apiPath":'subtitle', "value":'id',"label":'code_new'},
    "mark": {"apiPath":'mark', "value":'id',"label":'code'},
    "doc_type": {"apiPath":'doctype', "value":'id',"label":'code'}, */}

          Фасилити<DropdownUniveral 
              itemsApiPath= {'facility'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {19}
              finalFunction ={()=>1+1}
          />
                    Подтитул<DropdownUniveral 
              itemsApiPath= {'subtitle'}
              itemsColumn= {'code_new'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {20}
              finalFunction ={()=>1+1}
          />
                              Марка<DropdownUniveral 
              itemsApiPath= {'mark'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {14}
              finalFunction ={()=>1+1}
          />
                                        Тип док<DropdownUniveral 
              itemsApiPath= {'doctype'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {17}
              finalFunction ={()=>1+1}
          />
          {/* MDR status<DropdownUniveral 
              itemsApiPath= {'mdrstatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {18}
              finalFunction ={()=>1+1}
          /> */}
          
          {/* "nce_milestone": {"apiPath":'milestonence', "value":'id',"label":'code'},
          "spb_milestone": {"apiPath":'milestonespb', "value":'id',"label":'code'}, */}


          </div>
           <Button
            onClick={onClick}
            content='Добавить в МДР'
            type={1}
            groupId={1}
            id={1}
            />
               <h1>{props.dictFunc('mdr')}</h1>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(MdrCreatePage)
// {dictFunc}:any
// {dictFunc('email')}
// export default compose (
// withSettings
// )(EmailPage)
// {...props}