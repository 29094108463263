import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'

const Lala = ({dictFunc}:any) => {
  return (
<div>
  <TableUniveral
    headers={[
     dictFunc('document_number'),
     dictFunc('transmittal_inbox_number'),

    ]}
  apiPath='revision/adrc'
  cols={[  
    "number",
    "drcfiles__file_id__transmittal__number"]}
  />
</div>  
  )
}

const AdrcPage = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
           <h1>{props.dictFunc('adrc')}</h1>
         <Lala
         {...props}
         ></Lala>
               
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(AdrcPage)

