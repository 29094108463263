import React, { useEffect, useRef } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize';
import { translatorSelector } from '../selectors/selectors'
import { timerTranslatorThunkCreator, updateGlossaryThunk } from '../redux/translatorSlice'
import { MutableRefObject } from "react"
import s from './TranslatorPage.module.css'
import withSettings from '../hoc/withSettings';
export const TranslatorContent = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()  
    const updateGlossary = () => dispatch(updateGlossaryThunk())
  return (
      <div>
        <Lala/>
      </div>
 
  )
}
const Lala = () => {
  let dispatch:any = useDispatch()
  const translator:any = useSelector(translatorSelector)  
  
  const transRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const transRefeng = useRef() as MutableRefObject<HTMLTextAreaElement>;
  
  let onPostChange = () => {
    let body = transRef.current.value
    dispatch(timerTranslatorThunkCreator(body, 0))
  }

  let onPostChangeEng = () => {
    let body = transRefeng.current.value
    dispatch(timerTranslatorThunkCreator(body, 1))
  }
  
return (
<>
<div   className={s.wrapper2}>
<div className={s.wrapper}>
<div style={{"color": "#D3D3D3"}}>по-русски:</div>
<TextareaAutosize
style={{
"fontSize": "23px",
// "border":"1px solid #FAF0E6",
"width":"100%",
"margin":"5px 0",
"padding":"3px",
"border": "none",
"resize": "none",
}}
minRows={2}
onChange={onPostChange} 
value={translator.text}
ref={transRef}
/>
</div>
<div  className={s.wrapper}>
<div style={{"color": "#D3D3D3"}}>in english:</div>

<TextareaAutosize
style={{
"fontSize": "23px",
// "border":"0px solid #FAF0E6",
"width":"100%",
"margin":"5px 0",
"padding":"3px",
"border": "none",
"resize": "none",
}}
minRows={2}
onChange={onPostChangeEng} 
value={translator.translation}
ref={transRefeng}
/>
</div>
</div>

</>
)
}

const TranslatorPage = (props:any) => {

return (
<div>
<LayoutContainer>
<div className={s.wrapper3}>{props.dictFunc('Russian')}-{props.dictFunc('English')}</div>
{/* <Lala></Lala> */}
<TranslatorContent
{...props}
/>

</LayoutContainer>
</div>
)
}

export default compose (
withSettings
)(TranslatorPage)

