import React, { useEffect, useRef } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize';
import { filesSelector, translatorSelector } from '../selectors/selectors'
import { timerTranslatorThunkCreator, updateGlossaryThunk } from '../redux/translatorSlice'
import { MutableRefObject } from "react"
import s from './TranslatorPage.module.css'
// import Table from '../components/table/table'
import { GlossaryTypeItemApi } from '../types/types';
// import TableEdit from '../components/table/tableEdit';
import TableRowspan from '../components/table/tableRowspan';
import TableUniveral from '../components/table/tableUniveral';
import withSettings from '../hoc/withSettings';
import Upload from '../components/upload/Upload';
import App3 from '../components/upload/Upload2';
import App4 from '../components/upload/Upload3';
import MyApp from '../components/upload/Upload4';
import { getFilesThunkCreator } from '../redux/filesSlice';
import TableUniveralSet from '../components/table/tableUniveralset';
import NavbarTranslator from '../components/navbar/NavbarTranslator';



const TranslatorPageDocument = (props:any) => {


  const Lala = () => {
    // let dispatch:any = useDispatch()
    // const filesList:any = useSelector(filesSelector)
    
  
    // useEffect(() => {
    //   dispatch(getFilesThunkCreator())
     
    // },[dispatch])
  
    
    
    return (
      
  <div>

  <Upload/>
  {/* <App3/>
  <App4/>
  <MyApp/> */}
  <TableUniveralSet
          apiPath={'translatedoc'}
          headers={[]}
/>
  <TableUniveral
          apiPath={'docreveal'}
          headers={[]}
          // cols={[
          //   'paragraph_left',
          //   'paragraph_right',
          //   ]}
/>
  
  
  </div>

  
      
    )
  }

  
    return (
        <div>
           <LayoutContainer>
            <NavbarTranslator/>
               <div className={s.wrapper3}>перевод файла</div>
               <Lala></Lala>


           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TranslatorPageDocument)

