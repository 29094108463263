import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'

const initialState = {
  status: 'idle',
  entities: {},
  files: []
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUpload(state, action) {
      const fil = action.payload
      state.files = fil

    },
    todoAdded(state, action) {
      const todo = action.payload
      state.entities[todo.id] = todo
    },
    todoToggled(state, action) {
      const todoId = action.payload
      const todo = state.entities[todoId]
      todo.completed = !todo.completed
    }
  }
})



//===========================THUNK================================
export const getFilesThunkCreator =() => {
  return (dispatch) => {
     gccApi.getFilesList().then(data => {
     dispatch(setFiles(data))    
         })
 }
 }





export const { todoAdded, todoToggled, setFiles } = uploadSlice.actions
export default uploadSlice.reducer