import React from 'react'
import "./dropdownbutton.css"
import {FaChevronDown, FaChevronUp} from 'react-icons/fa'

const DropdownButton = (props:any) => {
return (
  <div 
  className={`dropdown-btn ${props.open ? "button-open" : null}`}
  onClick={props.togle}
  >
    {props.children}
    <span className='toggle-icon'>
      {props.open ? <FaChevronUp/> : <FaChevronDown/>}
    </span>
  </div>
)
}

export default DropdownButton