import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import OneTeam from '../components/one_team_ui/OneTeam'
import TableUniveralSet from '../components/table/tableUniveralset'
import { GetIsManager, GetMyID, GetMyTypeID } from './TodoPage'
import NavbarTeams from '../components/navbar/NavbarTeams'
import { useParams } from 'react-router-dom'

const TaskTotalPage = ({dictFunc}:any) => {
  const params = useParams()
  let subTeam:any  = params.teamid 
  let subTeamId: number | undefined = Number(subTeam)

  isNaN(subTeamId) && (subTeamId = undefined)

  
    let isManager = GetIsManager()
    let myTeamId = GetMyID()
    let team_type: any = GetMyTypeID()
    let lineral: any = undefined

    // if (subTeamId === -1000)
    // 

    let myTeamIdSub:number | undefined 
if (subTeamId===undefined) {
  myTeamIdSub = myTeamId
  team_type=undefined
} else if (subTeamId && subTeam > -1000){
  myTeamIdSub = subTeamId
  team_type=undefined

} else if (subTeamId===-1000){
  myTeamIdSub = undefined
  lineral = 0
}


    return (
        <div>
           <LayoutContainer>
           <TableUniveralSet
           apiPath='task/allteams'
           apiParametres={{'myid':myTeamId, 'linear': 0}}

           headers={[]}

           />
           
           {isManager &&
           <NavbarTeams
           path={"tasks"}
           isAddAll = {true}


           />
}
           {/* <TableUniveralSet
           apiPath='task/teams'
           headers={[]}

           /> */}

             <OneTeam 
             myTeamId={myTeamIdSub}
             type = {team_type}
             lineral = {lineral}
             
             />
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(TaskTotalPage)
