import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'



const Lala = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()
  const buttonState = useSelector((state:RootState)=>state.transmittal.newSelector)
  let selected:any = buttonState.find(string=>string.buttonState===1)?.dbId || 0
  useEffect(() => {
    dispatch(TranHandleClickThunkCreator(0))
  },[dispatch])
  return (
<div>

  <TableUniveral
  headers={['name', 'number', 'date'
  ]}
  apiPath='transmittal/register'
  cols={["name","number","date"]}



  /> 
</div>
    
  )
}
const FilesPage = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(FilesPage)


