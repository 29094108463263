import React from 'react'
// import s from './modal.module.css'


import "./modal.css"

const Modal = (props:any) => {
  
  
  let a:boolean = props.active
  let b = props.setActive
  
  
  return (
    <div className={a ? 'modal active' : 'modal'} onClick={() => b(false)}>
        <div className={a ? 'modal__content active' : 'modal__content'} onClick={e => e.stopPropagation()}>
          props.content
          {props.children} 
        </div>
    </div>
  )
}

export default Modal