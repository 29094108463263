import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { GetMyID } from './TodoPage'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import NavbarDocs from '../components/navbar/NavbarDocs'

const Lala = ({dictFunc}:any) => {
  let listTeam:any = []
let id = GetMyID()
let myTeamId:any = undefined
  const teamsWithRevisions  = useSelector((state:RootState) => state.tables.tables['task/teamsrev']) as any

  if (teamsWithRevisions) {
    for (let item of teamsWithRevisions){
      listTeam.push(item.team)
    }
    if (listTeam.includes(id)){
      myTeamId = id
    }
  }

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register2'])

  return (
<div>



<TableUniveral
  tableHeader={'Входящие документы'}
  headers={[
    // "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Номер DRC",
    // "Разослан?",
    // "Дата",
    "Команда",
    "Срок выполнения",
    "Статус",
    "Комментарий",
    "Применчание",
    
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtype', "value":'id',"label":'code', isLocked:true},
      "finalization": {"apiPath":'transmittal/finalization', "value":'id',"label":'code', isLocked:true},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name', isLocked:true},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code', isLocked:true},
  }
  

  }

  editableCols={[

    // "drc_number",
    // "comments",
    // "comments2",
    // "due_date",

  ]}

  apiPath='transmittal/register2'
  apiParametres={{"team":myTeamId,}}
  cols={[
    // "id",
    "number",
    "name",
    "subtype",
    "drc_number",
    // "is_submitted",
    // "date",
    "team",
    "due_date",
    "finalization",
    "comments",
    "comments2",
]}
  >



{/* 
  <TableUniveral
  tableHeader='Исходящие документыa'
  headers={[
    // "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Дата",
    "Команда",
    "Выгружен?",
    "Комментарий",
    "Применчание",  
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtypeoout', "value":'id',"label":'code', isLocked:true},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name', isLocked:true},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code', isLocked:true},
  }
  

  }

  editableCols={[
    // "comments",
    // "comments2",
  ]}

  apiPath='transmittal/register3'
  apiParametres={{"team": myTeamId}}
  cols={[
    // "id",
    "number",
    "name",
    "subtype",
    "date",
    "team",
    "is_submitted",
    "comments",
    "comments2",
]}
  >  */}
  
{expandedRowContent &&
  <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":expandedRowContent.id, "extension":"0"}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [                  
                    'file_name',
                    "global_path",
                    ]}

                  /> 
}
{!expandedRowContent &&
<></>
}




  
  
  </TableUniveral> 
</div>
    
  )
}
const DocsInPage = (props:any) => {

  
    return (
        <div>
           <LayoutContainer>
           <NavbarDocs/>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(DocsInPage)






















// ===

// import React, { useEffect } from 'react' 
// import { compose } from 'redux'
// import LayoutContainer from '../components/containers/LayoutContainer'
// import { useDispatch, useSelector } from 'react-redux'
// import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
// import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
// import TableUniveral from '../components/table/tableUniveral'
// import { RootState } from '../redux/store'
// import withSettings from '../hoc/withSettings'
// import DropdownUniveral from '../components/dropdown/dropdownUniversal'
// // import { isEditable } from '@testing-library/user-event/dist/utils'
// import TableUniveralSet from '../components/table/tableUniveralset'
// import NavbarManagement from '../components/navbar/NavbarManagement'
// import { GetMyID, RequestSettings } from './TodoPage'





// const Lala = ({dictFunc}:any) => {
// let listTeam:any = []
// let id = GetMyID()
// let myTeamId:any = undefined
//   const teamsWithRevisions  = useSelector((state:RootState) => state.tables.tables['task/teamsrev']) as any
//   if (teamsWithRevisions) {
//     for (let item of teamsWithRevisions){
//       listTeam.push(item.team)
//     }
//     if (listTeam.includes(id)){
//       myTeamId = id
//     }
//   }
//   return (
// <div>


//   <TableUniveral
//   tableHeader={'Входящие документы'}
//   headers={[
//     // "id DB",
//     "N TRM",
//     "Имя",
//     "Тип",
//     "Номер DRC",
//     // "Разослан?",
//     // "Дата",
//     "Команда",
//     "Срок выполнения",
//     "Статус",
//     "Комментарий",
//     "Применчание",
    
//   ]}
//   selectors={
//     {
//       "subtype": {"apiPath":'transmittal/subtype', "value":'id',"label":'code', isLocked:true},
//       "finalization": {"apiPath":'transmittal/finalization', "value":'id',"label":'code', isLocked:true},
//       "team": {"apiPath":'task/teams', "value":'id',"label":'name', isLocked:true},
//       "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code', isLocked:true},
//   }
  

//   }

//   editableCols={[

//     // "drc_number",
//     // "comments",
//     // "comments2",
//     // "due_date",

//   ]}

//   apiPath='transmittal/register2'
//   apiParametres={{"team":myTeamId,}}
//   cols={[
//     // "id",
//     "number",
//     "name",
//     "subtype",
//     "drc_number",
//     // "is_submitted",
//     // "date",
//     "team",
//     "due_date",
//     "finalization",
//     "comments",
//     "comments2",
// ]}
//   /> 
// </div>
    
//   )
// }
// const DocsInPage = (props:any) => {
  
//     return (
//         <div>
//            <LayoutContainer>
//                <Lala
//                {...props}
//                ></Lala>
//            </LayoutContainer>
//         </div>
//     )
// }

// export default compose (
//   withSettings
// )(DocsInPage)


