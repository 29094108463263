
import { useDispatch, useSelector } from 'react-redux'

import { GlossaryTypeItemApi, TableSelectorType, simpleDict } from '../../types/types'
import CellSelector from './cellSelector'
import { useState } from 'react'
import { RootState } from '../../redux/store'
import { setExpandedRow } from '../../redux/tableSlice'

const Row = (props: 
	{content: {[key:string]:any}, 
	cols: Array<string>,
	isAddTime: boolean | undefined,
	rowSpanDict: {[key:string]:Array<string|number>},
	rowSpanCols:  Array<string>,
	rowNumber: number
	separate: Array<string> | undefined
	rowIndex:number
	isAddIndex:boolean
	copyColumnName: Array<string> | undefined
	editableCols: Array<string> | undefined
	postCols: Array<string> | undefined
	postColsData?: simpleDict | undefined
	tableName: string
	selectors?: TableSelectorType
	id:number
	apiPath:string
	tableChildren?: any
	onSave?: Function

}) => {
	// const [open, setOpen] = useState(false)
	let cells = props.content
	let cols = props.cols
	let rowSpanDict = props.rowSpanDict
	let isExpand:boolean = false
	let expandedRowNumber:number
	
	
	expandedRowNumber = useSelector((state:RootState) => state.tables.expandedRowNumber[props.tableName])
	// expandedRowNumber = 0


	if (expandedRowNumber === props.rowNumber){
		isExpand = true
	}
	let dispatch:any = useDispatch()
	let onClick =()=>{
		dispatch(setExpandedRow({'tableName':props.tableName,  'RowNumber':props.rowNumber}))

	}
	let onClick2 =()=>{
		dispatch(setExpandedRow({'tableName':props.tableName,  'RowNumber':-1000}))

	}

	
	


	return (
<>
<tr>

{!isExpand &&  props.tableChildren && 
<td 
style={{
	// "backgroundColor":"#B0E0E6",  
	"cursor": "pointer" }}
onClick={onClick}>
{/* <div
style={{
	"transform": "rotate(270deg)"
 }}
> */}
<svg 

style={{
	"transform": "rotate(270deg)"
 }}

fill="#000000" height="10px" width="10px" version="1.1" id="Capa_1" viewBox="0 0 490.00 490.00"  >
<g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="245,456.701 490,33.299 0,33.299 "></polygon> 
</g></svg>
{/* </div> */}
</td>}

{isExpand && props.tableChildren &&
	<td 
style={{
	// "backgroundColor":"#B0E0E6",  
	"cursor": "pointer" }}
onClick={onClick2}>
{/* <div
style={{
	"transform": "rotate(0deg)"
 }}
> */}
<svg fill="#000000" height="10px" width="10px" version="1.1" id="Capa_1" viewBox="0 0 490.00 490.00"  >
<g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="245,456.701 490,33.299 0,33.299 "></polygon> 
</g></svg>
{/* </div> */}

</td>
}
	
	{cols.map((tag, index:number) =>
	// <td>{tag}</td>
	<CellSelector
		rowIndex = {props.rowIndex}
		isAddIndex = {props.isAddIndex}
		key={index}
		colName = {tag}
		isAddTime = {props.isAddTime}
		rowNumber={props.rowNumber}
		rowSpanDict =  {rowSpanDict}
		rowSpanCols = {props.rowSpanCols}
		separate = {props.separate}
		copyColumnName = {props.copyColumnName}
		path={props.content["local_path"]}
		editableCols = {props.editableCols}
		tableName = {props.tableName}
		postCols = {props.postCols}
		postColsData = {props.postColsData}

		selectors = {props.selectors}
		id = {props.id}
		apiPath = {props.apiPath}
		onSave = {props.onSave}
		// isPostRow = {isPostRow}
		// modifyCellFunc = {modifyCell}

	>

		{props.content[tag]}
	</CellSelector>
	)}
</tr>
{

}
{/* {isExpand && props.tableChildren &&
<tr
style={{"backgroundColor":"#B0E0E6",  "cursor": "pointer"}}
><td colSpan={20}
onClick={onClick2}
>Свернуть</td></tr>
} */}
{/* {!isExpand &&  props.tableChildren &&
<tr
style={{"backgroundColor":"#B0E0E6",  "cursor": "pointer" }}
><td colSpan={20}
// onClick={()=>setExpandedRow({'tableName':props.tableName,  'RowNumber':props.rowNumber})}
onClick={onClick}
>Развернуть</td></tr>
} */}
{isExpand &&  props.tableChildren &&
<tr
style={{"backgroundColor":"#F5F5F5"}}
><td colSpan={20}>{props.tableChildren}</td></tr>}
	
	</>
	)
}





export default Row
