import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
// import { selectorTotalType } from '../components/mdr_selector/MdrChoice'
import { utilities } from './utilities'

 export type ticketType = {
    "id": number,
    "start": string | null,
    "end": string | null,
    "comment": string | null,
    "is_seen": boolean,
    "is_off": boolean,
    "is_rejected": boolean,
    "is_complete": boolean,
    "task": number,
    "action": number,
    "team": number | null
}

export type taskType = {
  "id": number,
  "name": string | null,
  "description": string | null,
  "date_start": string | null,
  "days": number,
  "is_important": boolean,
  "type": number,
  "drc": number | null,
  "transmittal": number | null,
  "status": number,
  "team": number | null

}


 type selectorTransmittalTypeApi ={
  'extension': Array<number>,
  'type': Array<number>,
  'number': Array<number>,
  'revision': Array<number>
}

interface StringDict {
  [key: number]: string;
}

 type selectorTypeDictApi ={
  
  'type': StringDict,
}


 type itemSelectorType = {
  id: number
  groupId: number
  dbId: number
  buttonState: number
}

export type initialStateType ={
  show_modal: boolean,
  status: string,
  task: Array<taskType>
  ticket: Array<ticketType>
  dictSelector: selectorTypeDictApi
  newSelector: Array<itemSelectorType>
}


const initialState:initialStateType = {
  show_modal: false,
  newSelector: [],
  status: 'idle',
  task: [],
  ticket: [],
  dictSelector: {
    'type': 
      {
        0: "NCE-CCE",
        1: "CCE-NCE",
        2: "EMA-NCE-CCE",
        3: "EMA-CCE-NCE",
        4: "ASSAI-CCE-IPMT",
        5: "ASSAI-IPMT-CCE"
    },
  },



}

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTask(state, action) {
      const fil = action.payload
      state.task = fil

    },
    setTicket(state, action) {
      const fil = action.payload
      state.ticket = fil

    },

    setSelectorNew(state, action) {
      const fil = action.payload
      state.newSelector = fil
    },
    setSelectorDict(state, action) {
      const fil = action.payload
      state.dictSelector = fil
    },
    makeClick(state, action){
      const fil = action.payload

  

      state.newSelector[fil[1]].buttonState=fil[0]
    },
    setShowModalWindow(state, action) {
      
      
      
      state.show_modal = action.payload
    },

  }
})

//===========================THUNK================================


export  const taskHandleClickThunkCreator =() => {
  return (dispatch:any ,getState:any) => { 
    let listSelector:Array<itemSelectorType> = getState().drc;

    gccApi.getTasksList().then(data => {
      dispatch(setTask(data))    
    })
    gccApi.getTicketsList().then(data => {
      dispatch(setTicket(data))    
    })
  }
 }


 export  const selectorClickThunkCreator =(item:number, type:number) => {
  return (dispatch:any ,getState:any) => { 
    let listSelector:Array<itemSelectorType> = getState().drc;
    console.log(item, type)


    // gccApi.getTasksList().then(data => {
    //   dispatch(setTask(data))    
    // })
    // gccApi.getTicketsList().then(data => {
    //   dispatch(setTicket(data))    
    // })
  }
 }


export const { setTask, setTicket, setSelectorDict, makeClick, setSelectorNew, setShowModalWindow } = taskSlice.actions
export default taskSlice.reducer
