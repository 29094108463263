import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'


function NavbarManagemene({dictFunc}:any) {
    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()

    return (
        <div className="wrapper2">

            <div className=""><NavLink className="item" to={team + "/management/register2"}>Входящие</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/register3"}>Исходящие</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/notifications"}>Уведомления</NavLink></div>

            <div className=""><NavLink className="item" to={team +"/management/register4"}>Ревизии</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/alltask"}>Контроль</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/todo"}>Туду</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/teams"}>Команды</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/address"}>Люди</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/calendar"}>Календарь</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/letters"}>Письма</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/mdr"}>MDR</NavLink></div>
           
            <div className=""><NavLink className="item" to={team + "/management/visits"}>Статистика</NavLink></div>


            <div className=""><NavLink className="item" to={team + "/management/tables"}>{dictFunc('Настройки')}</NavLink></div>      
       
        </div>
    )
}




export default compose (
    withSettings
  )(NavbarManagemene)

