import React from 'react'
import { utilities } from '../../redux/utilities'

const DateParser = (props:{content:string, isAddTime?:boolean}) => {

  let isAddTime = props.isAddTime
  let string = props.content
  let isDate:boolean = utilities.isDate(string)
  return (
    <>
      {isDate &&
      <>
        <span>{utilities.parseDate(string)[0]}&nbsp;</span>
        {isAddTime && <span>{utilities.parseDate(string)[1]}</span>}
      </>
      }
      {!isDate &&
      <>
        {string}
      </>
      }
    </>



  )
}

export default DateParser