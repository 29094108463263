 
import Uploady from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";

const MyApp = () => (
    <Uploady destination={{ url: "my-server.com/upload" }}>
        <UploadDropZone 
          onDragOverClassName="drag-over"
          htmlDirContentParams={{ recursive: true }}
        >
            <span>Drag &Drop File(s) or Folder(s) Here</span>            
        </UploadDropZone>
    </Uploady>
);

export default MyApp;
