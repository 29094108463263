import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { useParams } from 'react-router-dom'

export const RequestSettings = (path:string, first_col:string, id:number | string, columnName:string):string | number  => {
    const finalisationSele  = useSelector((state:RootState) => state.tables.tables[path]) as any
    
    
    let finalisation = 0
    if (finalisationSele){
      // const result = words.filter((word) => word.length > 6);
      try {
      finalisation = finalisationSele.filter((item:any) => item[first_col] ===id)[0][columnName]
      } catch (e){console.log('er')}
    }
    return finalisation
}

export const RequestSecondarySettings = (path:string, first_col:string, id:number | string, columnName:string, columnName2:string):string | number  => {
    let tag_id: number = RequestSettings(path, first_col, id, columnName) as number
    let finalization: number = 0
    const finalisationSele  = useSelector((state:RootState) => state.tables.tables[path]) as any

    if (finalisationSele){
        // const result = words.filter((word) => word.length > 6);
        try {
            finalization = finalisationSele.filter((item:any) => item.id ===tag_id)[0][columnName2]
        } catch (e){console.log('er')}
      }
    return finalization
}



export const GetMyID = () => {
    const params = useParams()
    let team = params.team as string
    const myTeamId:number = RequestSettings('task/teams', 'name', team.toUpperCase(), 'id') as number
    return myTeamId
}
export const GetMyTypeID = () => {
    const params = useParams()
    let team = params.team as string
    const myTeamId:number = RequestSettings('task/teams', 'name', team.toUpperCase(), 'team_type') as number
    return myTeamId
}
export const GetIsAdmin = () => {
    const params = useParams()
    let team = params.team as string
    const myTeamId:number = RequestSecondarySettings('task/teams', 'name', team.toUpperCase(), 'id', 'isAdmin') as number
    return myTeamId
}

export const GetIsManager = () => {
    const params = useParams()
    let team = params.team as string
    const myTeamId:number = RequestSecondarySettings('task/teams', 'name', team.toUpperCase(), 'id', 'isManager') as number
    return myTeamId
}

const TodoPage = ({dictFunc}:any) => {
    let myId = GetMyID()
    let complete_status = RequestSettings('interface/settings', 'id', 3, 'value') as number
    let author:number | undefined = RequestSettings('interface/settings', 'id', 4, 'value') as number
    let responsible:number | undefined = RequestSettings('interface/settings', 'id', 5, 'value') as number
    let todo_type:number | undefined = RequestSettings('interface/settings', 'id', 6, 'value') as number
    
    author <0 && (author=undefined)
    responsible <0 && (responsible=undefined)
    todo_type <0 && (responsible=undefined)

    return (
        <div>

           <LayoutContainer>
               <NavbarManagement/>
            <div
            style={{
                "display": "flex"

            }}
            >
           <DropdownUniveral 
              itemsApiPath= {'task/completestatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {3}
              finalFunction ={()=>1+1}
          />
            <DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {4}
              finalFunction ={()=>1+1}
          /> 


        <DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {5}
              finalFunction ={()=>1+1}
          />
        {/* <DropdownUniveral 
              itemsApiPath= {'task/taskstatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {6}
              finalFunction ={()=>1+1}
          /> */}

{/* <DropdownUniveral 
              itemsApiPath= {'task/todotype'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {6}
              finalFunction ={()=>1+1}
          /> */}
          </div>


               <TableUniveral
                  apiParametres={{"complete_status":complete_status, "author":author, "responsible":responsible, "todo_type":todo_type}} 
                   apiPath='task/all'
                   
                   headers={[
                    "date",
                    "Тип задачи",
                    "Наименование задачи",
                    "Подробное описание",
                    "Ответ",
                    "responsible",
                    "urgent_status",
                    "deadline",
                    "author",
                    "complete_status",
                   ]}
                   tableHeader='Задачи'
                   cols={[
                   "date",
                   "todo_type",
                   "text1",
                   "text2",
                   "response",
                   "responsible",
                   "urgent_status",
                   "deadline",
                   "author",
                   "complete_status",
                ]}
                postCols={["text1"]}
                postColsData ={
                    {
                     "author":myId,   
                    }
                }
                editableCols={["text1", "text2", 'deadline']}
                selectors={
                    {
                        "todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name'},
                      "responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
                      "author": {"apiPath":'task/teams', "value":'id',"label":'name'},
                      "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
                      "urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},
                  }}
               />              
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TodoPage)
