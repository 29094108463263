import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'
import NavbarEmail from '../components/navbar/NavbarEmail'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import Button from '../components/button/button'

const EmailFilteredPage = ({dictFunc}:any) => {

  // let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/messagessimple'])
  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/messagesfilter'])

  // console.log(expandedRowContent.id)
  let onClick = ()=>{

    gccApi.getMessageFetch().then(data => {
      console.log(data)
      // console.log(555)

          



      // navigate("../sent", { relative: "path" })

         })    

  }


  
return (
<div>
  <LayoutContainer>







    <h1>Письма</h1>
    <NavbarEmail/>

    <Button
            onClick={onClick}
            content='Скачать новые'
            type={1}
            groupId={1}
            id={1}
            />
    <TableUniveral
        tableHeader='Статистика электронной почты'
        apiPath='mom/getstat'
        headers={[
          "Номер ящика",
          "Новых в почте",
          "Новых в БД",
          "Всего в почте",
          "Всего в БД",
        ]}
        cols={[
          "box_number",
          "new_server",
          "new_db",
          "total_server",
          "total_db",
        ]}


      />
      <TableUniveral
        tableHeader='фильтрованные письма'
        apiPath='mom/messagesfilter'
        headers={[
          // "id",
          // "subject",
          "message_id_subject",
          "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",
        ]}
        cols={[
          // "id",
          // "subject",
          "message_id_subject",
          "subject_common",
          "short_body",
          "comment",
          // "message_id",
          "from_name",
          "to_name",
          "is_delete",
          "message_id_date",
          "type",

        ]}
        editableCols={[
          'comment',
          "short_body",
          "message_id_date"
        
        ]}
        selectors={
          {
            "from_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "to_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "subject_common": {"apiPath":'mom/subject', "value":'id',"label":'code_new', isLocked:true},
            // "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
            // "move_up": {"apiPath":'task/bool', "value":'id',"label":'code'},

        }}

      >
{expandedRowContent &&

<TableUniveral
  tableHeader='получатели'
  apiPath='mom/recipients'
  headers={[]}
  apiParametres={{"message_id":expandedRowContent.message_id}}
  rowSpanCols={[
    "recipient_type_code",
  ]}
  isRemoveIdex={true}
/>
}
{!expandedRowContent &&<></>}


      </TableUniveral>
    
  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(EmailFilteredPage)


