import React from 'react'
import "./dropdownitem.css"

const DropdownItem = (props:any) => {
  
  
let handleClick = () => {
  props.function(props.row_id)
  props.toggle()
}


return (
    <div 
    // style={{
    //   "color":"black"
    // }}
      onClick = {handleClick}
      className='dropdown-item'
    >
      {props.children}    
    </div>
  )
}

export default DropdownItem