import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
// import store from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { rootSelector, taskModalSelector, taskSelector, ticketSelector } from '../selectors/selectors'
import { selectorClickThunkCreator, setShowModalWindow, taskHandleClickThunkCreator, taskType, ticketType } from '../redux/taskSlice'
import PrepareTask from '../components/tickets/PripareTask'
import Modal from '../components/modal/modal'
import Dropdown from '../components/dropdown/dropdown'
import { rootTypeDictApi } from '../redux/rootSlice'
// import withAuthRedirect from '../hoc/withAuthRedirect'


const Lala = () => {
  let dispatch:any = useDispatch()
  const taskList: Array<taskType> = useSelector(taskSelector)
  const ticketList: Array<ticketType> = useSelector(ticketSelector)
  
  useEffect(() => {
    dispatch(taskHandleClickThunkCreator())
   
  },[dispatch])

  
  
  return (
<div>
      <h1>тикеты:</h1>

    {ticketList.map((tag, index:any) => 

      <PrepareTask
        key={index}
        id= {tag.id}
        action = {tag.action}
        comment = {tag.comment}
        start = {tag.start}
        end = {tag.end}
        task= {tag.task}
        team = {tag.team}
        is_complete = {tag.is_complete}
        is_off = {tag.is_off}
        is_rejected = {tag.is_rejected}
        is_seen = {tag.is_seen}
      />


      // <p key={index}>{tag.id} - {tag.action} - {tag.task} - команда: {tag.team}</p>
    )}
    

</div>
    
  )
}

const TicketPage = () => {
  // const isModalWindow:boolean = useSelector(taskModalSelector)
  const isActive: boolean = useSelector(taskModalSelector)
  const teamList: rootTypeDictApi = useSelector(rootSelector)
  // const teamList2 = (teamList.team as Array<string>)


  

  
  
  let dispatch:any = useDispatch()

  let fufu = (f:boolean) => {
    dispatch(setShowModalWindow(f))
  }

  let handleDrapdown = (item:number, string:number) => {
    dispatch(selectorClickThunkCreator(item, string))
  }


//  const [isActive, setActive] = useState(false)


  let exeHandleClick = () => {
    fufu(true)
 
    
  }

    return (
        <div>
           <LayoutContainer>

              {/* <Dropdown
              buttonText = 'Drop down button'
              content = {teamList}
              function={handleDrapdown}
              
              /> */}
                <Lala/>
               <Modal
                active ={isActive}
                setActive = {fufu}
                content = {'dadfasdf'}
               >
                <p> тест скрин ddf</p>   <p> тест скрин ddf</p>   <p> тест скрин ddf</p> 
               </Modal> 

           </LayoutContainer>
        </div>
    )
}

export default compose (
  // withAuthRedirect
)(TicketPage)