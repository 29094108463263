import React, { MutableRefObject, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { applyFilterThunk, setFilter, setFilterPayloadType } from '../../redux/tableSlice'

const HeaderCell = ({
    children,
    num,
    tableName,
    cells,
    headersNew,
	postCols,    
}:{
    children:string
    tableName: string
    num:number
    cells: Array<string>
    headersNew: Array<string>
	postCols: Array<string> | undefined
}) => {

	

    let dispatch:any = useDispatch()
    let colName = cells[num]
    const [isEdit, setIsEdit] = useState(false)
    const transRef = useRef() as MutableRefObject<HTMLInputElement>;
    let filterContent= useSelector((state:RootState) => state.tables.talbesFilters[tableName][colName])

	let _handleKeyDown = (e:any) => {
		if (e.key === 'Enter') {
		  modifyCell()
		} else if (e.key === 'Escape'){
			setIsEdit(false)
		}
	  }
      let modifyCell= () => {
		dispatch(applyFilterThunk(tableName, postCols))

			setIsEdit(false)
	}

    let setContentCell = (currentValue:any) => {
		let payload:setFilterPayloadType = {
			tableName:tableName, 
			column:colName,
            value:currentValue
		}
		dispatch(setFilter(payload))
		dispatch(applyFilterThunk(tableName, postCols))
	}

    let turnEditOn=() => {
		setIsEdit(true)
	}

	let onInputChange=() =>{
		let currentValue = transRef.current.value
		setContentCell(currentValue)
	}

const handleFocus = (event:any) => event.target.select()

  return (
	<th

	style={{
		"cursor": "pointer"
	}}
	onClick={children!=='数' ? turnEditOn : ()=>{console.log()}}
	onKeyDown={_handleKeyDown}



	>
	{isEdit &&
		<input 
        autoFocus
		onBlur={modifyCell}
		value = {filterContent}	
		ref={transRef}
		onChange={onInputChange}
		onFocus={handleFocus}
		/>
	}

	{!isEdit &&
	<span

	>
		{children}    {filterContent && '<' + filterContent.slice(0,5) + '>'}</span>
	}
	</th>

  )
}

export default HeaderCell