import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
// import store from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { getFilesThunkCreator } from '../redux/filesSlice'
import { filesSelector } from '../selectors/selectors'
// import withAuthRedirect from '../hoc/withAuthRedirect'


const Lala = () => {
  let dispatch:any = useDispatch()
  const filesList:any = useSelector(filesSelector)
  

  useEffect(() => {
    dispatch(getFilesThunkCreator())
   
  },[dispatch])

  
  
  return (
<div>
    {filesList.map((tag:any, index:any) => 
      <h1 key={index}>{tag.id} - {tag.data}</h1>
      )}

</div>
    
  )
}

const FilesPage = () => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala/>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  // withAuthRedirect
)(FilesPage)