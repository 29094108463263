import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { GetMyID } from './TodoPage'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

const Lala = ({dictFunc}:any) => {
  let listTeam:any = []
let id = GetMyID()
let myTeamId:any = undefined
  const teamsWithRevisions  = useSelector((state:RootState) => state.tables.tables['task/teamsrev']) as any

  if (teamsWithRevisions) {
    for (let item of teamsWithRevisions){
      listTeam.push(item.team)
    }
    if (listTeam.includes(id)){
      myTeamId = id
    }
  }

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register3'])

  return (
<div>




  <TableUniveral
  tableHeader='Исходящие документыa'
  headers={[
    // "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Дата",
    "Команда",
    "Выгружен?",
    "Комментарий",
    "Применчание",  
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtypeoout', "value":'id',"label":'code', isLocked:true},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name', isLocked:true},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code', isLocked:true},
  }
  

  }

  editableCols={[
    // "comments",
    // "comments2",
  ]}

  apiPath='transmittal/register3'
  apiParametres={{"team": myTeamId}}
  cols={[
    // "id",
    "number",
    "name",
    "subtype",
    "date",
    "team",
    "is_submitted",
    "comments",
    "comments2",
]}
  > 
  
{expandedRowContent &&
  <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":expandedRowContent.id, "extension":"0"}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [                  
                    'file_name'
                    ]}

                  /> 
}
{!expandedRowContent &&
<></>
}




  
  
  </TableUniveral> 
</div>
    
  )
}
const TestDocsOutPage = (props:any) => {

  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TestDocsOutPage)
