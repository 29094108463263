import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import NavbarManagement from '../components/navbar/NavbarManagement'

const TablesPage = () => {
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>



<TableUniveral 
    apiPath='letters/subtype'
    cols={['code', 'description']}
    headers={[]}
    editableCols = {['code', 'description']}
    postCols =  {['code']}
    tableHeader='Подтипы писем'
/>

<TableUniveral 
    apiPath='milestonence'
    cols={['code', 'description']}
    headers={['code', 'description']}
    editableCols = {['code', 'description']}
    postCols =  {['code']}
    tableHeader='Вехи НХИ'
/>





<TableUniveral 
    apiPath='milestonespb'
    cols={['code', 'description']}
    headers={['code', 'description']}
    editableCols = {['code', 'description']}
    postCols =  {['code']}
    tableHeader='Вехи СПБ'
/>


<TableUniveral 
    apiPath='letters/type'
    cols={['code', 'description']}
    headers={['code', 'description']}
    editableCols = {['code', 'description']}
    postCols =  {['code']}
    tableHeader='Типы официальных писем'
/>
<TableUniveral 
    apiPath='letters/letterlinktype'
    cols={['code', 'description']}
    headers={['code', 'description']}
    editableCols = {['code', 'description']}
    postCols =  {['code']}
    tableHeader='Типы связей писем'
/>



<TableUniveral 
    apiPath='letters/company'
    cols={['code', 'full_name']}
    headers={['code', 'full_name']}
    editableCols = {['code', 'full_name']}
    postCols =  {['code']}
    tableHeader='Компании'
/>


<TableUniveral 
    apiPath='letters/projects'
    cols={[
        'short_name',
        'project_code',
        'full_name',
        'date_start',
        'comment',
        'customer',
        'path_inbox',
        'path_outbox',
    ]}
    headers={[
        'short_name',
        'project_code',
        'full_name',
        'date_start',
        'comment',
        'customer',
        'path_inbox',
        'path_outbox',
    ]}
    editableCols = {[
        'short_name',
        'project_code',
        'full_name',
        'date_start',
        'comment',
        // 'customer_id',
        'path_inbox',
        'path_outbox',
    ]}

    
    postCols =  {['short_name']}
    tableHeader='Проекты'
    selectors={
        {
          "customer": {"apiPath":'letters/company', "value":'id',"label":'code'},
      }
    }
/>



<TableUniveral 
    apiPath='task/todotype'
    cols={['name']}
    headers={['Название типа задач']}
    editableCols = {['name']}
    postCols =  {['name']}
    tableHeader='Типы задач'
/>
<TableUniveral 
    apiPath='task/teamstype'
    cols={['code', 'comment']}
    headers={['Название типа команды', 'comments']}
    editableCols = {['code', 'comment']}
    postCols =  {['code']}
    tableHeader='Типы рабочих команд'
/>


              
           </LayoutContainer>
        </div>
    )
}


export default compose (
  // withAuthRedirect
)(TablesPage)
