import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'

type initialStateType ={
  status: string,
  entities: any,
  files: any
  mom:any
}


const initialState:initialStateType = {
  status: 'idle',
  entities: {},
  files: [],
  mom: []
}

const momSlice = createSlice({
  name: 'mom',
  initialState,
  reducers: {
    setMom(state, action) {
      const fil = action.payload
      state.mom = fil

    },

  }
})



//===========================THUNK================================
export const getMomThunkCreator =() => {
  return (dispatch:any) => {
     gccApi.getMomList().then(data => {
     dispatch(setMom(data))    
         })
 }
 }





export const { setMom } = momSlice.actions
export default momSlice.reducer