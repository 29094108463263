import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'



type initialStateType ={
  status: string,
  entities: any,
  files: any
}

const initialState:initialStateType = {
  status: 'idle',
  entities: {},
  files: []
}

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles(state, action) {
      const fil = action.payload
      state.files = fil

    },
    todoAdded(state, action) {
      const todo = action.payload
      state.entities[todo.id] = todo
    },
    todoToggled(state, action) {
      const todoId = action.payload
      const todo = state.entities[todoId]
      todo.completed = !todo.completed
    }
  }
})



//===========================THUNK================================
export const getFilesThunkCreator =() => {
  return (dispatch:any) => {
     gccApi.getFilesList().then(data => {
     dispatch(setFiles(data))    
         })
 }
 }





export const { todoAdded, todoToggled, setFiles } = filesSlice.actions
export default filesSlice.reducer