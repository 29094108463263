import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import { refreshThunk } from '../redux/tableSlice'
import NavbarManagement from '../components/navbar/NavbarManagement'

const AddressPage = ({dictFunc}:any) => {
let dispatch:any = useDispatch()
  const finalFunction = () =>{

    dispatch(refreshThunk('interface/content'))
     }

dictFunc('email_colum')
    return (
<div>
  <LayoutContainer>
  <NavbarManagement/>
  {/* <h1>{dictFunc('address')}</h1> */}
  {/* <DropdownUniveral 
              itemsApiPath= {'interface/languages'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {0}
              finalFunction ={finalFunction}
          /> */}
    <TableUniveral
    cols={
      [
        
        "email",
        "name",
        "phone",
        "comment",
        "site",


      ]
    }
    editableCols={[
      "email",
      "name",
      "phone",
      "comment",

    ]}
    postCols={[
      "email",


    ]}
    tableHeader={dictFunc('address')}
      headers={[
        "email",
        "name",
        "phone",
        "comment",
        "site",
    ]}
      apiPath='mom/emailaddress'
      // rowSpanCols={['site']}
    />
  </LayoutContainer>
</div>
    )
}
export default compose (
  withSettings
)(AddressPage)


