import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'

const ReportsMdrPage = ({dictFunc}:any) => {
  const [isUpdated, setIsUpdated] = useState(true)
  useEffect(() => {
    console.log('rrrr')
    // gccApi.prepareNotification().then(data => {
    //   console.log(data)
      setIsUpdated(true)
    //       }) 
  },[])
  
return (
<div>
  <LayoutContainer>
  <NavbarReports/>
    <h1>Отчеты</h1>

    {isUpdated &&
      <TableUniveral
        tableHeader='Отчет по MDR'
        apiPath='report/mdrreport'
        headers={[
          'Марка',
          // 'id',
          'Всего документов',
          'Подписано NCE (-в процессе)',
          '%',
          'Reviewed by NCE (-в процессе)',
          '%',

        ]}
        // cols={[
        //   'mdr',
        //   'files',
        //   'version',
        //   'issue',
        //   'doc_class',
        //   'date',
        //   'date_id',
        // ]}

      />
    }
  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(ReportsMdrPage)


