import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'
import NavbarEmail from '../components/navbar/NavbarEmail'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import Button from '../components/button/button'

const EmailPage = ({dictFunc}:any) => {

  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/messagessimple'])

  // console.log(expandedRowContent.id)
  let onClick = ()=>{

    gccApi.getMessageFetch().then(data => {
      console.log(data)
      // console.log(555)

          



      // navigate("../sent", { relative: "path" })

         })    

  }


  
return (
<div>
  <LayoutContainer>







    <h1>Письма</h1>
    <NavbarEmail/>

    <Button
            onClick={onClick}
            content='Скачать новые'
            type={1}
            groupId={1}
            id={1}
            />
    <TableUniveral
        tableHeader='Статистика электронной почты'
        apiPath='mom/getstat'
        headers={[
          "Номер ящика",
          "Новых в почте",
          "Новых в БД",
          "Всего в почте",
          "Всего в БД",
        ]}
        cols={[
          "box_number",
          "new_server",
          "new_db",
          "total_server",
          "total_db",
        ]}


      />
      <TableUniveral
        tableHeader='Письма'
        apiPath='mom/messagessimple'
        headers={[
          "box_type",
          "subject",
          "text",
          "date",
        ]}
        cols={[
          // "id": 1701,
          // "uid": 8803,
          "box_type",
          "subject",
          "text",
          "date",
          // "size": 159920,
          // "number_attachments": 4
        ]}
        editableCols={['text']}

      >
{expandedRowContent &&
<TableUniveral
  tableHeader='получатели'
  apiPath='mom/recipients'
  headers={[]}
  apiParametres={{"message_id":expandedRowContent.id}}
  rowSpanCols={[
    "recipient_type_code",
  ]}
  isRemoveIdex={true}
/>
}
{!expandedRowContent &&<></>}


      </TableUniveral>
    
  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(EmailPage)


