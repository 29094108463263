import React from 'react'
import "./table.css"
import { utilities } from '../../redux/utilities'
import Row from './row'
import HeaderCell from './header'
import { TableSelectorType, simpleDict } from '../../types/types'

type TableRowType = {[key: string] : any}
type TalbePropsType = {
  headers: Array<string>
  content: Array<TableRowType>
  cols?: Array<string>
  isAddTime?:boolean
  rowSpanCols?: Array<string>
  isRemoveIdex?: boolean
  separate?: Array<string>
  copyColumnName?: Array<string>
  editableCols?: Array<string>
  postCols?: Array<string>
  tableName: string
  tableHeader?: string
  selectors?: TableSelectorType
  apiPath:string
  postColsData?: simpleDict
  rowsLimit?:number
  children?: any
  onSave?:Function
}

const Table = (props:TalbePropsType) => {
	
	let headers = props.headers
	let rowsContent = props.content
	let rowsContent2 =rowsContent

	props.rowsLimit && (rowsContent2 = rowsContent.slice(0,props.rowsLimit))




	let rowSpanCols = props.rowSpanCols
	let rawSpanCols2: Array<string>
	let isAddIndex:boolean = !props.isRemoveIdex 
	let arrayCells:Array<string> = []
	let amountRows = rowsContent2.length
	let cols = props.cols
	!rowSpanCols ? rawSpanCols2 = [] : rawSpanCols2 = rowSpanCols
	let rowSpanDict = utilities.getDictFromDictByKey(props.content, rawSpanCols2)
	let headersNew:Array<string> =[] 
	if (isAddIndex){
		headers.length > 0 ? headersNew = ['数', ...headers] : headersNew = headers

		rowsContent2 = utilities.addIndexDict(props.content)
	} else {headersNew = headers}
	rowsContent2[0] ? arrayCells = Object.keys(rowsContent2[0]) : arrayCells = []
	if (cols) {
		arrayCells = cols
	} 
	if (isAddIndex){	
		arrayCells = utilities.moveRowIndexBegin(arrayCells)
	}
	
  return (
<>
<table className="table">
{props.tableHeader && <caption>{props.tableHeader}</caption>}
	<thead>
		<tr>	
		{props.children && headers.length > 0 && <th></th>}
			{headersNew.map((tag, index:number) => 
				<HeaderCell 
				key={index}
				num={index}
				tableName={props.tableName}
				cells={arrayCells}
				headersNew={headersNew}
				postCols={props.postCols}
				>
					{tag}
				</HeaderCell>
			)}
		</tr>
	</thead>
	<tbody>
		{rowsContent2.map((tag, index:number) => <>
			<Row
				key={index}
				rowIndex = {amountRows + 1 - index }
				isAddIndex = {isAddIndex}
				rowNumber = {index}
				content = {tag}
				copyColumnName = {props.copyColumnName}
				cols = {arrayCells}
				rowSpanDict = {rowSpanDict}
				rowSpanCols = {rawSpanCols2}
				isAddTime ={props.isAddTime}
				separate = {props.separate}
				editableCols = {props.editableCols}
				postCols =  {props.postCols}
				postColsData =  {props.postColsData}
				tableName = {props.tableName}
				selectors = {props.selectors}
				id = {tag.id}
				apiPath = {props.apiPath}
				tableChildren = {props.children}
				onSave = {props.onSave}
				
			/>

			
			</>
		)}
	</tbody>
</table>
</>
  )
}

export default Table