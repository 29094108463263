import { configureStore } from '@reduxjs/toolkit'

import filesReducer from './filesSlice'
import uploadReducer from './uploadSlice'
import momReducer from './momSlice'
import addressReducer from './addressSlice'
import translatorReducer from './translatorSlice'
import mdrReducer from './mdrSlice'
import transmittalReducer from './transmittalSlice'
import drcReducer from './drcSlice'
import taskReducer from './taskSlice'
import rootReducer from './rootSlice'
import messageReducer from './messagesSlice'
import tableReducer from './tableSlice'





const store = configureStore({
  reducer: {
    root: rootReducer,
    files: filesReducer,
    upload: uploadReducer,
    mom: momReducer,
    address: addressReducer,
    translator: translatorReducer,
    mdr: mdrReducer,
    transmittal: transmittalReducer,
    drc: drcReducer,
    task: taskReducer,
    messages: messageReducer,
    tables: tableReducer,
  },
})
export type RootState = ReturnType<typeof store.getState>



export default store