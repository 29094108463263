import React from 'react'
import TableUniveral from '../table/tableUniveral'

const CorrespondenceView = (props:{'trm_number':number}) => {
  return (


<TableUniveral
        tableHeader='Переписка'
        apiPath='mom/messagesfilter'
        apiParametres={{"search":props.trm_number}}
        headers={[
          // "Тема",
          // "Текст",
          // "Отправитель",
          // "Получатель",
          // "Дата",
        ]}
        cols={[
          // "id",
          // "subject",
          "subject_common",
          // "subject_common",
          "short_body",
          // "message_id",
          "from_name",
          "to_name",
          "message_id_date",

        ]}

        selectors={
          {
            "from_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "to_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "subject_common": {"apiPath":'mom/subject', "value":'id',"label":'code_new', isLocked:true},
        }}

      />

  )
}

export default CorrespondenceView