import React from 'react'
import TableUniveral from '../table/tableUniveral'

const FilesView = (props:{'trm_id':number}) => {
  return (
    <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":props.trm_id, "extension":"0"}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [                  
                    'file_name',
                    "global_path",

                    ]}

                  /> 
  )
}

export default FilesView