import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useSelector } from 'react-redux'
import MdrChoice from '../components/mdr_selector/MdrChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import { selectorType } from '../redux/mdrSlice'
import { utilities } from '../redux/utilities'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { RequestSettings } from './TodoPage'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import NavbarMdr from '../components/navbar/NavbarMdr'

const Lala = ({dictFunc}:any) => {


  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mdr2'])







  return (
<div>


<TableUniveral
  tableHeader='Статусы в МДР'
  apiPath='mdrstatus'
  headers={
  [
    "code",
  ]
  }
  cols={[
  "code",

  ]
}
/>



<TableUniveral
  tableHeader='типы документов'
  apiPath='doctype'
  headers={
  [
  "code",
  "title_eng",
  "title_rus"
  ]
  }
  cols={[
  "code",
  "title_eng",
  "title_rus",
  ]
}/>

<TableUniveral
  tableHeader='марки'
  apiPath='mark'
  headers={
  [
  "code",
  "title_eng",
  "title_rus"
  ]
  }
  cols={[
  "code",
  "title_eng",
  "title_rus",
  ]
}/>


<TableUniveral
  tableHeader='Дисциплины'
  apiPath='discipline'
  headers={
  [
  "code",
  "title_eng",
  "title_rus"
  ]
  }
  cols={[
  "code",
  "title_eng",
  "title_rus",
  ]
}/>


<TableUniveral
  tableHeader='Фасилити'
  apiPath='facility'
  headers={
  [
  "code",
  "title_eng",
  "title_rus"
  ]
  }
  cols={[
  "code",
  "title_eng",
  "title_rus",
  ]
}/>


<TableUniveral
  tableHeader='Подтитулы'
  apiPath='subtitle'
  headers={
  [
  "code",
  "title_eng",
  "title_rus",
  'facility',
  ]
  }
  cols={[
  "code",
  "title_eng",
  "title_rus",
  'facility',
  ]
}
// editableCols={[
//   "title_eng",
//   "title_rus",

// ]}
selectors={
  {
    "facility": {"apiPath":'facility', "value":'id',"label":'code', isLocked:true},

}

}


/>




{/* router.register(r'doctype', DocTypesViewSet, basename='doctype')
router.register(r'mark', MarkViewSet, basename='mark')
router.register(r'discipline', DisciplineViewSet, basename='discipline')
router.register(r'facility', FacilityViewSet, basename='facility')
router.register(r'subtitle', SubtitleViewSet, basename='subtitle') */}






</div>   
  )
}

const MdrSettingsPage = (props:any) => {
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
           <NavbarMdr/>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(MdrSettingsPage)
// {dictFunc}:any
// {dictFunc('email')}
// export default compose (
// withSettings
// )(EmailPage)
// {...props}