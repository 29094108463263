import React from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useSelector } from 'react-redux'
import MdrChoice from '../components/mdr_selector/MdrChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import { selectorType } from '../redux/mdrSlice'
import { utilities } from '../redux/utilities'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { RequestSettings } from './TodoPage'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import NavbarMdr from '../components/navbar/NavbarMdr'

const Lala = ({dictFunc}:any) => {


  let doc_type: number | undefined = RequestSettings('interface/settings', 'id', 17, 'value') as number
  let team: number | undefined = RequestSettings('interface/settings', 'id', 16, 'value') as number
  let complete_status: number | undefined = RequestSettings('interface/settings', 'id', 15, 'value') as number
  let mark: number | undefined = RequestSettings('interface/settings', 'id', 14, 'value') as number
  let spb_milestone: number | undefined = RequestSettings('interface/settings', 'id', 13, 'value') as number
  let nce_milestone: number | undefined = RequestSettings('interface/settings', 'id', 12, 'value') as number
  let mdr_status: number | undefined = RequestSettings('interface/settings', 'id', 18, 'value') as number
  
  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mdr2'])


  
// doc_type <0 && (doc_type=undefined)
team <0 && (team=undefined)
complete_status <0 && (complete_status=undefined)
// mark <0 && (mark=undefined)
spb_milestone <=0 && (spb_milestone=undefined)
nce_milestone <=0 && (nce_milestone=undefined)





  return (
<div>

<div
            style={{"display": "flex"}}
          >

          {/* <DropdownUniveral 
              itemsApiPath= {'task/bool'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {7}
              finalFunction ={()=>1+1}
          /> */}



          Команда<DropdownUniveral 
              itemsApiPath= {'task/teams'}
              itemsColumn= {'name'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {16}
              finalFunction ={()=>1+1}
          />
          NCE milestone<DropdownUniveral 
              itemsApiPath= {'milestonence'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {12}
              finalFunction ={()=>1+1}
          />
          SPB milestone<DropdownUniveral 
              itemsApiPath= {'milestonespb'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {13}
              finalFunction ={()=>1+1}
          />
          Complete status<DropdownUniveral 
              itemsApiPath= {'task/completestatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {15}
              finalFunction ={()=>1+1}
          />
          MDR status<DropdownUniveral 
              itemsApiPath= {'mdrstatus'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {18}
              finalFunction ={()=>1+1}
          />
          
          {/* "nce_milestone": {"apiPath":'milestonence', "value":'id',"label":'code'},
          "spb_milestone": {"apiPath":'milestonespb', "value":'id',"label":'code'}, */}


          </div>



<TableUniveral
apiPath='mdr2'
apiParametres={{
  // "doc_type": doc_type, 
  "team": team,
  "complete_status": complete_status,
  // "mark": mark,
  "spb_milestone": spb_milestone,
  "nce_milestone": nce_milestone,
  "status": mdr_status,
}}






headers={
  [
    "id",
    "doc_number",
    "doc_title",
    "doc_title2",
    "ifr_plan",
    "ifc_plan",
    "key_milestone",
    "mileston",
    "nce_milestone_id",
    "spb_milestone_id",
    "comments",
    "revision",
    "status_id",

    "complete_status",
    "deadline", 
    "team",


  ]
}
cols={[
  "id",
  "doc_number",
  "doc_title",
  "ifr_plan",
  "ifc_plan",
  "key_milestone",
  "mileston",
  "nce_milestone",
  "spb_milestone",
  "comments",
  "revision",
  "status",
  "complete_status",
  "deadline",
  "team",


]}
selectors={
  {
    "status": {"apiPath":'mdrstatus', "value":'id',"label":'code', isLocked:true},
    "nce_milestone": {"apiPath":'milestonence', "value":'id',"label":'code'},
    "spb_milestone": {"apiPath":'milestonespb', "value":'id',"label":'code'},
    // "deadline": {"apiPath":'task/calendar', "value":'id',"label":'date_calendar'},
    "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
    "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
}

}
editableCols={[
  'comments', 'deadline'
]}
separate ={["doc_title",'/']}
//   rowSpanCols={[      
//   "ifr_plan",
//   "ifc_plan",
//   "key_milestone",
//   "status_id",
//   "revision",
// ]} 

>


{expandedRowContent &&

<TableUniveral
  headers={[]}
  apiPath='transmittal/files'
  apiParametres={{
    'search': expandedRowContent.doc_number, 
    'extension':'0',
  }}
  cols={[
    "file_name", "date_modified",
  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}
/>
}
{!expandedRowContent &&<></>}

</TableUniveral>



</div>   
  )
}

const MdrPageAdmin = (props:any) => {
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
           <NavbarMdr/>
               <h1>{props.dictFunc('mdr')}</h1>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(MdrPageAdmin)
// {dictFunc}:any
// {dictFunc('email')}
// export default compose (
// withSettings
// )(EmailPage)
// {...props}