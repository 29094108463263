import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import { GetIsAdmin } from '../../pages/TodoPage'
import { useDispatch } from 'react-redux'
import { postTableThunk } from '../../redux/tableSlice'
import { TableApiType } from '../../types/types'



// export const getTeamName = () => {
//     const params = useParams()
//     let team  = params.team as string
//     team = '/' + team.toLowerCase()
// return team
// }

function Navbar({dictFunc}:any) {

    let dispatch:any = useDispatch()
  
    const [oldPath, setOldPath] = useState('')

    const isAdmin = GetIsAdmin()

    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()
    let currentAdress:string = window.location.href 

    let postTableObj:TableApiType = {
        path:'appstat/visits', 
        rowContent: {
            team:team.substring(1), 
            address:currentAdress}}



    if (oldPath===currentAdress){

    } else {

        setOldPath(currentAdress)
        dispatch(postTableThunk(postTableObj))
    }
  


    
        

    return (
        <div className="wrapper">
            <div className="item"><NavLink className="item" to={team + "/transmital"}>{dictFunc('transmittal_header')}</NavLink></div>
            <div className="item"><NavLink className="item" to={team + "/drc"}>Ревизии</NavLink></div>
            <div className="item"><NavLink className="item" to={team + "/mom"}>Протоколы</NavLink></div>

            {isAdmin===0 
            ? ( <div className="item"><NavLink className="item" to={team + "/translator"}>{dictFunc('translate-header')}</NavLink></div>)
            : (<div className="item"><NavLink className="item" to={team + "/translatorpro"}>{dictFunc('translate-header')}</NavLink></div>)
            }
    
            
            <div className="item"><NavLink className="item" to={team + "/mdr"}>{dictFunc('mdr_header')}</NavLink></div>   
            
            {isAdmin===1 &&
            (<div className="item"><NavLink className="item" to={team + "/management"}>Управление</NavLink></div> )
            }
            
             
            <div className="item">
                <span>
                <NavLink className="item" to={team + "/tasks"}>Задачи</NavLink>
                <NavLink className="item" to={team + "/createtask"}>+</NavLink>
                </span>
            </div>  
            {/* <div className="item"><NavLink className="item" to={team + "/outtasks"}>Исходящие задачи</NavLink></div>   */}

            
            {/* <div className="item"><NavLink className="item" to={team + "/createtask"}>Создать задачу</NavLink></div>   */}
            <div className="item"><NavLink className="item" to={team + "/documents/inbox"}>Документы</NavLink></div>  
            <div className="item"><NavLink className="item" to={team + "/reports"}>Отчеты</NavLink></div>  

            {isAdmin===1 &&
             ( <div className="item"><NavLink className="item" to={team + "/email"}>Почта</NavLink></div>)
            }

        </div>
    )
}




export default compose (
    withSettings
  )(Navbar)

