import React from 'react'
import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import TableUniveral from '../table/tableUniveral'
export const FilesUi = (props:any) => {
return (
<TableUniveral
 tableHeader='История документа'
  headers={[
    "имя файла", 'N ТРМ','Тип трансмиттала',"Дата",
  ]}
  apiPath='transmittal/files'
  apiParametres={{
    'search': props.doc_name, 
    'extension':'0',
  }}
  cols={[
    "file_name", 'trm_no','trm_type',"date_modified",
  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}
/>

)
}
export default compose (
withSettings
)(FilesUi)