import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'


function NavbarTeams(props:any) {
    
    let isAddAll = props.isAddAll
    
    const params = useParams()
    let team  = params.team as string
    // let responsible = params.responsible as string
    team = '/' + team.toLowerCase()
    // responsible = '/' + responsible.toLowerCase()


    let finalisationSele  = useSelector((state:RootState) => state.tables.tables['task/allteams']) 
    // let idArray  = useSelector((state:RootState) => state.tables.tables['task/allteams']) 
    
    // console.log(finalisationSele)
    let idArray: Array<any> = []
    let idArray2:Array<any> = []


    
    
    if (finalisationSele) {
        
    //     finalisationSele = finalisationSele.filter((a)=>a.isGroup===0)
        
    //  idArray = finalisationSele.map(a => a.id);
    idArray = finalisationSele 

        
    // if (isAddAll){
    //     let firstItem = {...idArray[0]}
    //     // firstItem.id = -1000
    //     // firstItem.name = '==Общие задачи=='

    //     idArray2 = [firstItem, ...idArray]
    //     // console.log(idArray2)


    // }


    }



    return (

        <>{finalisationSele && idArray &&
        <div className="wrapper2">

{isAddAll &&

            <div className="">
    <NavLink className="item" to={`${team}/${props.path}/${-1000}`}>

    =Общие задачи=

    </NavLink></div>}


            {idArray.map((tag, index)=>       
<div className="">
    {/* <NavLink className="item" to={team + props.path + finalisationSele[index]['id']}> */}
    <NavLink className="item" to={`${team}/${props.path}/${finalisationSele[index]['id']}`}>

    {finalisationSele[index]['name']}

    </NavLink></div>
            )}

       
        </div>
}
        </>
    )
}




export default compose (
    withSettings
  )(NavbarTeams)

