import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import withSettings from '../hoc/withSettings'
import { useNavigate } from 'react-router-dom'
import { gccApi } from '../api/api'
import { useState } from 'react'
import NavbarReports from '../components/navbar/NavbarReports'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import CompanyStatusView from '../components/one_trm_ui/CompanyStatusView'

const ReminderPage = ({dictFunc}:any) => {
const navigate = useNavigate();

const [isUpdated, setIsUpdated] = useState(false)
let expandedRowContent1:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslistsign'])
let expandedRowContent2:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslistsign1'])
let expandedRowContent3:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslistsign2'])
let expandedRowContent4:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslist'])
let expandedRowContent5:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslist1'])
let expandedRowContent6:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/deptorslist2'])

let onClick = ()=>{

gccApi.getSendNotification().then(data => {
console.log(data)
navigate("../sent", { relative: "path" })

})    

}
return (
  <div>
    <LayoutContainer>
      <NavbarReports/>
      <h1>Задолженность по трансмитталам</h1>
      <CompanyStatusView
        header={'Уфа'}
        team_type={0}
        expanded_row_sign={expandedRowContent1}
        expanded_row_rev={expandedRowContent4}
        path_sign={'deptorslistsign'}
        path_rev={'deptorslist'} 
        is_current={0}      
        />
      <CompanyStatusView 
        header={'Санкт-Петербург'}
        team_type={1}
        expanded_row_sign={expandedRowContent2}
        expanded_row_rev={expandedRowContent5}
        path_sign={'deptorslistsign1'}
        path_rev={'deptorslist1'} 
        is_current={0}      
        />
      <CompanyStatusView 
        header={'Чэнду'}
        team_type={2}
        expanded_row_sign={expandedRowContent3}
        expanded_row_rev={expandedRowContent6}
        path_sign={'deptorslistsign2'}
        path_rev={'deptorslist2'} 
        is_current={0}      
      />
      
    </LayoutContainer>
  </div>
)
}

export default compose (
withSettings
)(ReminderPage)