import React from 'react'
import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import TableUniveral from '../table/tableUniveral'
export const NceRev = (props:any) => {
return (
<TableUniveral 
                   tableHeader='Ревизии NCE'
                   apiPath={'revision/getncerevisionstatus'} 
                   apiParametres={{"doc_name":props.doc_name}} 
                   copyColumnName={["file_name"]}
                   isRemoveIdex={true}  
                   headers={[
                    "имя файла", 'N ТРМ','Тип трансмиттала',"Дата",
                  ]}
                   cols={[
                    "file_name", 'trm_no','trm_type',"date_modified",
                  ]}               
                   />

)
}
export default compose (
withSettings
)(NceRev)