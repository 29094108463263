import React from "react";
import { MutableRefObject, useRef, useState } from "react";
import { oneCellType } from "./cellSimple";
import { useDispatch, useSelector } from "react-redux";
import { modifyTableThunk, setCell } from "../../redux/tableSlice";
import { TableRowApiType, setCellTablePayloadType, simpleDict } from "../../types/types";
import { RootState } from "../../redux/store";
import Input, { onFocusInputType } from "../input/input";

const CellEdit = (props: {
	content:any | Element,
	children:any,
	colName:string,
	rowNumber:number
	tableName: string
	postCols: Array<string> | undefined
	postColsData?: simpleDict | undefined
	onSave?: Function
}) => {
	let dispatch:any = useDispatch()
	let _handleKeyDown = (e:any) => {
		if (e.key === 'Enter') {
		  modifyCell()
		} else if (e.key === 'Escape'){
			setIsEdit(false)
		}
	  }

	

	let modifyCell:any= () => {
		
		let cellObject:oneCellType = {
			rowNumber: props.rowNumber,
			colName: props.colName,
			value: transRef.current.value
		}
		setContentCell(transRef.current.value)
		dispatch(modifyTableThunk(props.postCols, props.postColsData, undefined, props.onSave))
		// props.onSave && props.onSave()
			// props.modifyCellFunc(111)
			setIsEdit(false)
	}

	const transRef = useRef() as MutableRefObject<HTMLInputElement>;


	const [isEdit, setIsEdit] = useState(false);

	let contentCell= useSelector((state:RootState) => state.tables.tables[props.tableName][props.rowNumber][props.colName])
	let setContentCell = (currentValue:any) => {
		let payload:setCellTablePayloadType = {
			tableName:props.tableName, 
			rowNumber:props.rowNumber, 
			celName:props.colName,
			data:currentValue 
		}
		dispatch(setCell(payload))
	}


	let isPost:boolean = false
	if (props.postCols){
		(props.rowNumber===0) && (isPost = true)
	}
	
	
	let isPostEdit:boolean = false
	
		if (props.postCols){
			props.postCols.includes(props.colName) && (props.rowNumber===0) && (isPostEdit = true)
		}


	let turnEditOn=() => {
		!isPost  && setIsEdit(true)
		isPost && isPostEdit && setIsEdit(true)
	}



	let onInputChange=() =>{

		// setContentCell(transRef.current.value)
		let currentValue = transRef.current.value
		setContentCell(currentValue)
		
	}

const handleFocus = (event:any) => event.target.select()



	

  return (

	<td
	style={{backgroundColor: "#F0FFF0", "cursor": "pointer"}}
	onClick={turnEditOn}
	onKeyDown={_handleKeyDown}
	>
	{isEdit &&
		<Input 
		handleOnBlur={modifyCell}
		value = {contentCell}	
		refObj={transRef}
		handleOnChange={onInputChange}
		handleFocus={handleFocus}



		/>
	}

	{!isEdit &&
	<span

	>{contentCell}</span>
	}
	</td>
  )
	}



export default CellEdit