import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'


function NavbarMdr({dictFunc}:any) {
    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()

    return (
        <div className="wrapper2">

            <div className=""><NavLink className="item" to={team + "/management/mdr/assign"}>Назначить</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/mdr/create"}>Создать</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/management/mdr/settings"}>Настройки</NavLink></div>
       

       
        </div>
    )
}




export default compose (
    withSettings
  )(NavbarMdr)