import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'
import NavbarEmail from '../components/navbar/NavbarEmail'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { RequestSettings } from './TodoPage'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'

const EmailSubjects = ({dictFunc}:any) => {
  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['mom/subject'])
  let is_delete: number | undefined = RequestSettings('interface/settings', 'id', 21, 'value') as number








  // console.log(expandedRowContent.id)


  
return (
<div>
  <LayoutContainer>



    <h1>Темы писем</h1>
    <NavbarEmail/>

    <DropdownUniveral 
              itemsApiPath= {'task/bool'}
              itemsColumn= {'code'}
              storageApiPath= {'interface/settings'}
              storageColumn=  {'value'}
              storageId = {21}
              finalFunction ={()=>1+1}
          />

<TableUniveral
tableHeader='Темы писем'
apiPath='mom/subject'
apiParametres={{
  "is_delete": is_delete
}}
headers={[          
"code",
'сообщения',
"is_delete",
"comment",

"date"

]}
cols={[
"code_new",
'mes_quantity',
"is_delete",
"comments",
"date"
]}
selectors={{
              "is_delete": {"apiPath":'task/bool', "value":'id',"label":'code'},
}}
isAddTime={true}
editableCols={[
"comments","date"
]}
// postCols={[
// "code"
// ]}
>
{expandedRowContent &&
<TableUniveral
        // tableHeader='фильтрованные письма'
        apiPath='mom/messagesfilter'
        apiParametres={{
          "subject_common":expandedRowContent.id,
        }}
        headers={[
          // "short_body",
          // // "comment",
          // "from_name",
          // "to_name",
          // "message_id_date",
          // // "type",
        ]}
        cols={[
          "short_body",
          // "comment",
          "from_name",
          "to_name",
          "subject_common",

          "message_id_date",

          // "type",
        ]}
        editableCols={["message_id_date","subject_common",]}
        selectors={
          {
            "from_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            "to_name": {"apiPath":'mom/emailaddress', "value":'id',"label":'email', isLocked:true},
            // "subject_common": {"apiPath":'mom/subject', "value":'id',"label":'code_new'},
            // "team_type": {"apiPath":'task/teamstype', "value":'id',"label":'code'},
            // "move_up": {"apiPath":'task/bool', "value":'id',"label":'code'},

        }}
      />
}{!expandedRowContent &&<></>}

</TableUniveral>






  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(EmailSubjects)


