import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
// import store from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { getFilesThunkCreator } from '../redux/filesSlice'
import { filesSelector } from '../selectors/selectors'
import Upload from '../components/upload/Upload'
import App3 from '../components/upload/Upload2'
import App4 from '../components/upload/Upload3'
import MyApp from '../components/upload/Upload4'
// import withAuthRedirect from '../hoc/withAuthRedirect'


const Lala = () => {
  let dispatch:any = useDispatch()
  const filesList:any = useSelector(filesSelector)
  

  useEffect(() => {
    dispatch(getFilesThunkCreator())
   
  },[dispatch])

  
  
  return (
    
<div><h1>загрузка</h1>
    {filesList.map((tag:any, index:any) => 
      <h1 key={index}>{tag.id} - {tag.data}</h1>
      )}
<Upload/>
<App3/>
<App4/>
<MyApp/>

</div>
    
  )
}

const UploadPage = () => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala/>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  // withAuthRedirect
)(UploadPage)