import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'


function NavbarEmail({dictFunc}:any) {
    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()

    return (
        <div className="wrapper2">

            <div className=""><NavLink className="item" to={team + "/email"}>все</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/email/filtered"}>Фильтрованные</NavLink></div>
            <div className=""><NavLink className="item" to={team + "/email/subjects"}>Темы</NavLink></div>
            {/* <div className=""><NavLink className="item" to={team + "/email/settings"}>Исходящие</NavLink></div> */}
            {/* <div className=""><NavLink className="item" to={team + "/email/settings"}>По отправителям</NavLink></div> */}
            <div className=""><NavLink className="item" to={team + "/email/settings"}>Настройки</NavLink></div>
       

       
        </div>
    )
}




export default compose (
    withSettings
  )(NavbarEmail)