import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import OneTeam from '../components/one_team_ui/OneTeam'
import TableUniveralSet from '../components/table/tableUniveralset'
import { GetMyID } from './TodoPage'

const OutTaskPage = ({dictFunc}:any) => {

    let myTeamId = GetMyID()
    return (
        <div>
           <LayoutContainer>


            
<TableUniveral 
                   apiPath='task/all'
                   apiParametres={{'complete_status':0, 'author':myTeamId}}
                   headers={[

                    // "date",
                    "Тип",
                    "Название",
                    "Описание",
                    "Ответ адресата",
                    "Адресат",
                    "Срочность",
                    "Срок выполнения",
                    // "author",
                    "complete_status",

                   ]}
                   tableHeader='Создать задачу'
                  //  rowsLimit={2}

                   selectors={
                    {
                        "todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name'},
                        "responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
                        // "author": {"apiPath":'task/teams', "value":'id',"label":'name', "isLocked":true},
                        "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
                        "urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},

                  }}
                  editableCols={[ "text1", 'text2']}
                  postCols={[ "text1",]}
                  postColsData={ {"author":myTeamId,}}


                   cols={[
                    // "date",
                    "todo_type",
                    "text1",
                    "text2",
                    "response",
                    "responsible",
                    "urgent_status",
                    "deadline",
                    // "author",
                    "complete_status",
                 ]}
               />  



           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(OutTaskPage)
