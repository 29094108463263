import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from './dropdown'
import { DropdownUniversalType } from '../../types/types'
import { RootState } from '../../redux/store'
import { utilities } from '../../redux/utilities'
import { compose } from '@reduxjs/toolkit'
// import withSettings from '../../hoc/withSettings'
import { modifyVirtualTableThunk } from '../../redux/tableSlice'
import TableUniveralSet from '../table/tableUniveralset'


const DropdownUniveral2 = (props:DropdownUniversalType) => {
  let dispatch:any = useDispatch()
  const languageList  = useSelector((state:RootState) => state.tables.tables[props.itemsApiPath]) as any
  
  const settings  = useSelector((state:RootState) => state.tables.tables[props.storageApiPath]) as any
  if (languageList && settings) {

    let newDict = utilities.reduceArrayToDict(languageList, 'id', props.itemsColumn)

    let chosenId = settings.filter((item:any)=>item.id===props.storageId)[0][props.storageColumn]
  

    const totalFunction = (data:any) => {
      dispatch(modifyVirtualTableThunk({
        fireFunction: props.finalFunction,
        tableName: props.storageApiPath,
        rowNumber: props.storageId,
        celName: props.storageColumn,
        data: data
      }))
    }
  
    return (
      <>
        {languageList && settings && newDict[chosenId] && !props.isLocked &&
          <Dropdown 
            function={totalFunction}
            buttonText= {newDict[chosenId]}
          >
            {newDict}
          </Dropdown>
        }

      {languageList && settings && newDict[chosenId] && props.isLocked &&
          <p>{newDict[chosenId]}</p>
        }
      </>
    )
  } else {

    return (
      <>
      {!languageList && <></>
      // <TableUniveralSet 
      // apiPath={props.itemsApiPath}
      // headers={[]}
      // tableHeader='Languages'
      // isHideTable={true}
      // /> error
      }
      {!settings &&<></>
      // <TableUniveralSet 
      // apiPath={props.storageApiPath}
      // headers={[]}
      // tableHeader='Settings'
      // isHideTable={true}
      // /> 
      }
      </>
    )
    
  }
}

export default DropdownUniveral2
// export default compose (
//   withSettings
// )(DropdownUniveral)