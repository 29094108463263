import React from 'react'
import TableUniveral from '../table/tableUniveral'
import FilesView from './FilesView'
import CorrespondenceView from './CorrespondenceView'

const CompanyStatusView = (props:{
  'is_current':number
  'header':string, 
  'team_type': number,
  'expanded_row_sign':any,
  'expanded_row_rev':any,
  'path_sign':string,
  'path_rev':string,
}) => {
  return (
    <>
    <TableUniveral
    apiPath={'mom/' + props.path_sign}
    apiParametres={{
      'team_type':props.team_type,
      'is_current':props.is_current
    }}
    headers={[
    ]}
 tableHeader={'Офис '  + props.header + '- подпись для строительства'}
 cols={[
   // 'id',
   'name',
   'team__name',
   'date',
   'comments2'


 ]}
 rowSpanCols={[
   "subtype__code",
   "team__name",
 ]}
 isDisapearIfEmpty={true}
>
{props.expanded_row_sign &&<>
<FilesView trm_id={props.expanded_row_sign.id} />
<CorrespondenceView trm_number={props.expanded_row_sign.number} />
</>

}
{!props.expanded_row_sign &&
<></>
}

</TableUniveral>

<TableUniveral 
    apiPath={'mom/' + props.path_rev}
    apiParametres={{
      'team_type':props.team_type,
      'is_current':props.is_current    
    }}
    headers={[
    ]}
 tableHeader={'Офис '  + props.header + '- ревизия документов'}
 rowSpanCols={[
   "subtype__code",
   "team__name",
 ]}
 isDisapearIfEmpty={true}

 cols={[
   // 'id',

   'name',
   'subtype__code',
   'team__name',
   'due_date',
   'comments2'
 ]}

>

{props.expanded_row_rev &&<>
  <FilesView trm_id={props.expanded_row_rev.id} />
  <CorrespondenceView trm_number={props.expanded_row_rev.number} />

</>
}
{!props.expanded_row_rev &&
<></>
}

</TableUniveral>


</>

    
  )
}

export default CompanyStatusView