import React, { useEffect } from 'react'
// import Table from './table'
import { useDispatch, useSelector } from 'react-redux'
import { TableRowApiType, TableUniversalType } from '../../types/types'
import { RootState } from '../../redux/store'
import { initiateFilter, setTableProperty, setTablePropertyiePayloadType, setTableThunk, setTableType } from '../../redux/tableSlice'
import { utilities } from '../../redux/utilities'
import Table from './table'
import TableUniveralSet from './tableUniveralset'

const TableUniveral = (props:TableUniversalType) => {
    let dispatch:any = useDispatch()
    let apiPath = props.apiPath
    let prefix = props.apiPrefix
    let apiParametres = props.apiParametres
    let tableName = utilities.convertPathToName(apiPath)
    let tableContent:Array<TableRowApiType>= useSelector((state:RootState) => state.tables.tables[tableName])
    
    let isSaveSignal:any = useSelector((state:RootState) => state.tables.isSave[tableName])

    let apiArraySelector: Array<string> = []
    if (props.selectors){
      let arraySelectors = Object.keys(props.selectors)  
      for (let item of arraySelectors){
        apiArraySelector.push(props.selectors[item].apiPath)
      }
    
    }

    let isShowTable: boolean = false

    if (tableContent) {

    if (tableContent.length > 0) {
      isShowTable = true 
    } else if (tableContent.length === 0 && props.isDisapearIfEmpty) {
      
      isShowTable = false
    } else {
      isShowTable = true
    }
  } else {
    isShowTable = false
  }
   

    useEffect(() => {
      let payloadTable:setTableType  = {
        apiPath : apiPath,
        postCols : props.postCols,
        prefix: props.apiPrefix,
        parameters: props.apiParametres
      }
      let payloadProperty:setTablePropertyiePayloadType = {
        tableName: apiPath,
        prefix: prefix,
        param: props.apiParametres
      }
      dispatch(setTableThunk(payloadTable))
      dispatch(setTableProperty(payloadProperty))
      dispatch(initiateFilter(apiPath))
    },[dispatch, apiPath, props.postCols, apiParametres, prefix,props.apiParametres, props.apiPrefix,])
    
    // useEffect(() => {
    //   let payloadTable:setTableType  = {
    //     apiPath : apiPath,
    //     postCols : props.postCols,
    //     prefix: props.apiPrefix,
    //     parameters: props.apiParametres
    //   }

    //   dispatch(initiateFilter(apiPath))
    //   isSaveSignal && dispatch(setTableThunk(payloadTable))
    //   isSaveSignal && props.onSave && props.onSave()
    // },[isSaveSignal, apiPath, props.postCols, props, dispatch, props.onSave, props.apiPrefix,])
  return (
<>{tableContent && !props.isHideTable &&


<>{isShowTable &&
    <Table 
        {...props}
        content = {tableContent}
        tableName = {tableName}
    />
    
}

    {props.selectors && <>
    		{apiArraySelector.map((tag:string, index:number) => 
          <TableUniveralSet
          key={index}
          apiPath={tag}
          headers={[]}
    /> 
        )}
        </>
    }





</>
    
}

</>
  )
}

export default TableUniveral