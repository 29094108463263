import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import OneTeam from '../components/one_team_ui/OneTeam'
import TableUniveralSet from '../components/table/tableUniveralset'
import { GetMyID } from './TodoPage'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { simpleDict } from '../types/types'
import NavbarTeams from '../components/navbar/NavbarTeams'
import { useParams } from 'react-router-dom'

const TaskCheckPage = ({dictFunc}:any) => {

    let myTeamId = GetMyID()

    const finalisationSele  = useSelector((state:RootState) => state.tables.tables['task/teams']) 
    let teamArray: Array<simpleDict>
    let idArray: Array<number> = []


    const params = useParams()
    let responsible  = params.responsible as string
    // let responsible = params.responsible as string
    // team = '/' + team.toLowerCase()
    

  if (finalisationSele) {
    // teamArray = finalisationSele.filter((item:any) => item[first_col] ===id)

     idArray = finalisationSele.map(a => a.id);
     
    // finalisationSele.map((item)=> idArray.push(item.id))

  }

    return (
        <div>
           <LayoutContainer>
           <TableUniveralSet
           apiPath='task/allteams'
          //  apiParametres={{'type':2}}

           headers={[]}

           />
           <NavbarManagement/>

           <NavbarTeams
           path={'management/alltask'}
           />
           <>
           {idArray &&
          //  <p>{idArray}</p>
          //  idArray.map((tag:number)=>
             <OneTeam myTeamId={responsible}/>

          //  )
          
           
           
           }
           
           </>



           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(TaskCheckPage)
