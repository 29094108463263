import React from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../redux/store'
// import TableUniveral from '../components/table/tableUniveral'
import { text } from 'stream/consumers'
import TableUniveralSet from '../components/table/tableUniveralset'



export const withSettings = (Component:any) => {
    const SettingsComponent = (props:any) => {
        let selectorFunc = (arr: Array<{code:string,text:string}>, value:string):string => {
            let t = arr.filter((item)=>item.code==value )
            let returnValue:string = ''
            if (t.length ===0){
                if (value){

                    returnValue = value.toString()
                } else {
                    returnValue = 'error'
                }
            } else {
                returnValue = t[0].text

            }
            return returnValue

        } 
        const languageDict  = useSelector((state:RootState) => state.tables.tables['interface/content']) as any
        if (!languageDict){
            return ( 
            <TableUniveralSet 
                apiParametres={{language:"1000"}}
                apiPath='interface/content'
                headers={[]}
                tableHeader='Content'
                isHideTable={true}
                /> )
        } else {
            const selectorFuncTotal = (key:string): string => {     
                return selectorFunc(languageDict, key)
            }
            return (
                <Component {...props
                } 
                dictFunc = {selectorFuncTotal}
                />
                )

        }
    }
    return SettingsComponent
}

export default withSettings



