import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'



const Lala = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()
  // const buttonState = useSelector((state:RootState)=>state.transmittal.newSelector)
  // let selected:any = buttonState.find(string=>string.buttonState===1)?.dbId || 0
  // useEffect(() => {
  //   dispatch(TranHandleClickThunkCreator(0))
  // },[dispatch])
  return (
<div>
<TableUniveral
  headers={[]}
  apiPath='transmittal/files'
  apiParametres={{
    'search': 'GCC-CCE-DDD-12452-00-1422-TH-PID-00001', 
    'extension':'0',
  }}
  cols={[
    "file_name", "date_modified",
  ]}
  copyColumnName={["file_name"]}
/>

  {/* <TableUniveral
  headers={[
    dictFunc('transmittal_number'),
    dictFunc('file_name'),
    dictFunc('date_time'),
    dictFunc('db_index'),

  ]}
  apiPath='transmittal/main_filter'
  apiParametres={{"transmittal__type":selected}}
  cols={[
    "transmittal__number",
    "file_name",
    "transmittal__date",
    "id",
  ]}
  rowSpanCols={[
    "transmittal__number",
    "transmittal__date",
  ]}
  isAddTime={true}
  isRemoveIdex={false}
  copyColumnName={["file_name"]}
  />  */}
</div>
    
  )
}
const FilesPage = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(FilesPage)


