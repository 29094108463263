import './App.css'
import { BrowserRouter as Router, Routes , Route} from 'react-router-dom';
import HeroPage from './pages/HeroPage';
import FilesPage from './pages/FilesPage';
import Error404Page from './pages/Error404Page';
import UploadPage from './pages/UploadPage';
import EmailPage from './pages/EmailPage';
import TransmitalPage from './pages/TransmitalPage';
import ScedulePage from './pages/ScedulePage';
import MomPage from './pages/MomPage';
import AddressPage from './pages/AddressPage';
import TranslatorPage from './pages/TranslatorPage';
import MdrPage from './pages/MdrPage';
import DrcPage from './pages/DrcPage';
import TicketsPage from './pages/TicketsPage';
import AdrcPage from './pages/AdrcPage';
import TablesPage from './pages/TablesPage';
import TodoPage from './pages/TodoPage';
import CalendarPage from './pages/CalendarPage';
import RegisterPage from './pages/RegisterPage';
import RegisterPage2 from './pages/RegisterPage2';
import RegisterPage3 from './pages/RegisterPage3';
import RegisterPage4 from './pages/RegisterPage4';
import TeamsPage from './pages/TeamsPage';
import TaskTotalPage from './pages/TaskTotalPage';
import TaskCheckPage from './pages/TaskCheckPage';
import OutTaskPage from './pages/OutTaskPage';
import DocsOutPage from './pages/DocsOutPage';
import DocsInPage from './pages/DocsInPage';
import CreateTaskPage from './pages/CreateTaskPage';
import TranslatorPageAdmin from './pages/TranslatorPageAdmin';
import TestDocsOutPage from './pages/TestDocsOutPage';
import Visits from './pages/Visits';
import TranslatorPageDocument from './pages/TranslatorPageDocument';
import TranslatorPageDocumentOne from './pages/TranslatorPageDocumentOne';
import TranslatorPageDocumentGet from './pages/TranslatorPageDocumentGet';
import TranslatorPageDocumentList from './pages/TranslatorPageDocumentList';
import NotificationPage from './pages/NotificationPage';
import LetterPageAdmin from './pages/LetterPageAdmin';
import TransmitalPage2 from './pages/TransmitalPage2';
import MdrPageAdmin from './pages/MdrPageAdmin';
import NotificationOutboxPage from './pages/NotificationOutboxPage';
import NotificationSentPage from './pages/NotificationSentPage';
import ReportsPage from './pages/ReportsPage';
import ReportsMilestonePage from './pages/ReportsMilestonePage';
import MdrSettingsPage from './pages/MdrSettingsPage';
import MdrCreatePage from './pages/MdrCreatePage';
import TranslatorPageDocumentOneEng from './pages/TranslatorPageDocumentOneEng';
import EmailSettingsPage from './pages/EmailSettingsPage';
import EmailFilteredPage from './pages/EmailFilteredPage';
import EmailSubjects from './pages/EmailSubjects';
import ReminderPage from './pages/ReminderPage';
import ReminderPageNormis from './pages/ReminderPageNormis';
import ReportsMdrPage from './pages/ReportsMdrPage';

const App = (props:any) => {
    const addToRoute:string = ':team'
    return (
        <div>
            <Router>    
                <Routes >
                    <Route path = "/" element={<HeroPage />}/>
                    <Route path = {addToRoute + "/files"} element={<FilesPage/>}/>
                    <Route path = {addToRoute + "/upload"} element={<UploadPage/>}/>
                    <Route path = {addToRoute + "/email"} element={<EmailPage/>}/>
                    <Route path = {addToRoute + "/email/filtered"} element={<EmailFilteredPage/>}/>
                    <Route path = {addToRoute + "/email/subjects"} element={<EmailSubjects/>}/>

                    <Route path = {addToRoute + "/email/settings"} element={<EmailSettingsPage/>}/>

                    <Route path = {addToRoute + "/transmital"} element={<TransmitalPage/>}/>
                    <Route path = {addToRoute + "/transmital2"} element={<TransmitalPage2/>}/>
                    {/* <Route path = "/tasks" element={<TaskPage/>}/> */}
                    <Route path = {addToRoute + "/scedule"} element={<ScedulePage/>}/>
                    <Route path = {addToRoute + "/mom"} element={<MomPage/>}/>
                    <Route path = {addToRoute + "/management/address"} element={<AddressPage/>}/>
                    <Route path = {addToRoute + "/translator"} element={<TranslatorPage/>}/>
                    <Route path = {addToRoute + "/translatorpro"} element={<TranslatorPageAdmin/>}/>
                    <Route path = {addToRoute + "/translatordoc"} element={<TranslatorPageDocument/>}/>
                    <Route path = {addToRoute + "/translatordocone"} element={<TranslatorPageDocumentOne/>}/>
                    <Route path = {addToRoute + "/translatordoconeeng"} element={<TranslatorPageDocumentOneEng/>}/>

                    <Route path = {addToRoute + "/translatordoclist"} element={<TranslatorPageDocumentList/>}/>


                    <Route path = {addToRoute + "/translatorfile"} element={<TranslatorPageDocumentGet/>}/>


                    <Route path = {addToRoute + "/mdr"} element={<MdrPage/>}/>
                    <Route path = {addToRoute + "/drc"} element={<DrcPage/>}/>
                    <Route path = {addToRoute + "/adrc"} element={<AdrcPage/>}/>
                    <Route path = {addToRoute + "/tickets"} element={<TicketsPage/>}/>
                    <Route path = {addToRoute + "/management/tables"} element={<TablesPage/>}/>
                    <Route path = {addToRoute + "/management/todo"} element={<TodoPage/>}/>
                    <Route path = {addToRoute + "/management/teams"} element={<TeamsPage/>}/>
                    <Route path = {addToRoute + "/management/calendar"} element={<CalendarPage/>}/>
                    <Route path = {addToRoute + "/management/notifications"} element={<NotificationOutboxPage/>}/>
                    <Route path = {addToRoute + "/management/reminders"} element={<ReminderPage/>}/>

                    <Route path = {addToRoute + "/management/notifications/template"} element={<NotificationPage/>}/>

                    <Route path = {addToRoute + "/management/notifications/sent"} element={<NotificationSentPage/>}/>
                    <Route path = {addToRoute + "/management/notifications/Outbox"} element={<NotificationOutboxPage/>}/>


                    <Route path = {addToRoute + "/management/letters"} element={<LetterPageAdmin/>}/>

                    <Route path = {addToRoute + "/management/register"} element={<RegisterPage/>}/>
                    <Route path = {addToRoute + "/management/register2"} element={<RegisterPage2/>}/>
                    <Route path = {addToRoute + "/management/register3"} element={<RegisterPage3/>}/>
                    <Route path = {addToRoute + "/management/register4"} element={<RegisterPage4/>}/>
                    <Route path = {addToRoute + "/management/visits"} element={<Visits/>}/>
                    <Route path = {addToRoute + "/management/mdr"} element={<MdrPageAdmin/>}/>
                    <Route path = {addToRoute + "/management/mdr/assign"} element={<MdrPageAdmin/>}/>
                    <Route path = {addToRoute + "/management/mdr/create"} element={<MdrCreatePage/>}/>
                    <Route path = {addToRoute + "/management/mdr/settings"} element={<MdrSettingsPage/>}/>




                    <Route path = {addToRoute + "/management/alltask"} element={<TaskCheckPage/>}/>
                    <Route path = {addToRoute + "/management/alltask/"+ ':responsible'} element={<TaskCheckPage/>}/>


                    <Route path = {addToRoute + "/management/"} element={<RegisterPage2/>}/>
                    <Route path = {addToRoute + "/tasks"} element={<TaskTotalPage/>}/>
                    <Route path = {addToRoute + "/tasks/" + ':teamid'} element={<TaskTotalPage/>}/>



                    <Route path = {addToRoute + "/"} element={<TaskTotalPage/>}/>

                    <Route path = {addToRoute + "/createtask"} element={<CreateTaskPage/>}/>
                    <Route path = {addToRoute + "/outtasks"} element={<OutTaskPage/>}/>

                    {/* <Route path = {addToRoute + "/outdocs"} element={<DocsOutPage/>}/> */}
                    <Route path = {addToRoute + "/documents/outbox"} element={<DocsOutPage/>}/>
                    
                    <Route path = {addToRoute + "/documents/inbox"} element={<DocsInPage/>}/>

                    {/* /documents/inbox */}

                    <Route path = {addToRoute + "/docstest"} element={<TestDocsOutPage/>}/>
                    <Route path = {addToRoute + "/reports/milestone"} element={<ReportsMilestonePage/>}/>
                    <Route path = {addToRoute + "/reports/"} element={<ReminderPage/>}/>
                    <Route path = {addToRoute + "/reports/delay"} element={<ReminderPage/>}/>
                    <Route path = {addToRoute + "/reports/normal"} element={<ReminderPageNormis/>}/>
                    <Route path = {addToRoute + "/reports/exec"} element={<ReportsPage/>}/>
                    <Route path = {addToRoute + "/reports/mdr"} element={<ReportsMdrPage/>}/>

                    {/* <Route path = "/tasks/" element={<TaskTotalPage/>}/> */}

                    <Route path = "*" element={<Error404Page />}/> 
                </Routes>
            </Router>
        </div>
    )
}

export default App;
