import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
// import store from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {  momSelector } from '../selectors/selectors'
import { getMomThunkCreator } from '../redux/momSlice'
// import Table from '../components/table/table'
// import TableRowRowspan from '../components/table/tableRowRowspan'
import TableRowspan from '../components/table/tableRowspan'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
// import withAuthRedirect from '../hoc/withAuthRedirect'


const Lala = () => {
  let dispatch:any = useDispatch()
  const momList:any = useSelector(momSelector)
  
  

  useEffect(() => {
    dispatch(getMomThunkCreator())
   
  },[dispatch])

  

  // "id": 197,
  // "date": null,
  // "number": null,
  // "revision": null,
  // "discipline": "PM",
  // "item": 5,
  // "Agenda": "Final ADRC approval procedure \nNCE will provide the first ADRC with references to related emails for CCE approval next week 03/04",
  // "Agenda_rus": null,
  // "Responsible": "NCE",
  // "due_date": null,
  // "status": "OPEN",
  // "note": null,
  // "progress": null,
  // "todo": "NCE will provide the first ADRC with references to related emails for CCE approval next week 03/04",
  // "deadline": "2024-04-03T00:00:00"


  
  return (
<div>



    {momList
    ? 
    // momList.map((tag:any, index:any) => 
    //   <p key={index}>{tag.discipline}  -{tag.todo} - {tag.deadline}</p>
    //   )
    <TableRowspan
    headers={[
      "日期",
      "纪律",
      "议程",
     "待办事项",
      "截止日期"
    ]}
    content={momList}
    cols={[
      "date",
      "discipline",
      "Agenda",
      "todo",
      "deadline",
    ]}
    rowSpanCols={["discipline", "deadline"]}
    />


  // "id": 197,
  // "date": null,
  // "number": null,
  // "revision": null,
  // "discipline": "PM",
  // "item": 5,
  // "Agenda": "Final ADRC approval procedure \nNCE will provide the first ADRC with references to related emails for CCE approval next week 03/04",
  // "Agenda_rus": null,
  // "Responsible": "NCE",
  // "due_date": null,
  // "status": "OPEN",
  // "note": null,
  // "progress": null,
  // "todo": "NCE will provide the first ADRC with references to related emails for CCE approval next week 03/04",
  // "deadline": "2024-04-03T00:00:00"



    : <p>loading...</p>
    }



</div>
    
  )
}

const FilesPage = ({dictFunc}:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <h1>{dictFunc('mom')}</h1>
               <TableUniveral
               tableHeader='NCE'
                  apiPath='mom/ncemom'
                   headers={[

                    "date",
                    "discipline",
                    "Agenda",
                    "Agenda_rus",

                    "todo",
                    "deadline"
       
                  ]}
                  cols={  [                  
                    "date",
                    "discipline",
                    "Agenda",
                    "Agenda_rus",

                    "todo",
                    "deadline"
                    ,]}

                  rowSpanCols={["discipline", "deadline"]}                  
                  />

<TableUniveral
               tableHeader='CCE'

                  apiPath='mom/ccemom'
                   headers={[

       
                  ]}
                  cols={  [                  
                    "date",
                    "discipline",
                    "Agenda",
                    "Agenda_rus",

                    "todo",
                    "deadline",]}

                  rowSpanCols={["discipline", "deadline"]}                  
                  />


               
               {/* <Lala></Lala> */}
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(FilesPage)


