import React, { useEffect, useRef } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize';
import { filesSelector, translatorSelector } from '../selectors/selectors'
import { timerTranslatorThunkCreator, updateGlossaryThunk } from '../redux/translatorSlice'
import { MutableRefObject } from "react"
import s from './TranslatorPage.module.css'
// import Table from '../components/table/table'
import { GlossaryTypeItemApi } from '../types/types';
// import TableEdit from '../components/table/tableEdit';
import TableRowspan from '../components/table/tableRowspan';
import TableUniveral from '../components/table/tableUniveral';
import withSettings from '../hoc/withSettings';
import Upload from '../components/upload/Upload';
import App3 from '../components/upload/Upload2';
import App4 from '../components/upload/Upload3';
import MyApp from '../components/upload/Upload4';
import { getFilesThunkCreator } from '../redux/filesSlice';
import TableUniveralSet from '../components/table/tableUniveralset';
import NavbarTranslator from '../components/navbar/NavbarTranslator';



const TranslatorPageDocumentList = (props:any) => {


  const Lala = () => {

    
    
    return (
      
  <div>
<TableUniveral
        apiPath={'docreveal'}
        headers={[

          "id",
          "paragraph_left",
          "paragraph_right",
          "comments",
          "is_translated",
          "is_glosary",
          "is_modified",
          "is_finish",
          "responsible",
          


        ]}
        cols={[
          "id",
          "paragraph_left",
          "paragraph_right",
          "comments",
          "is_translated",
          "is_glosary",
          "is_modified",
          "is_finish",
          "responsible",
          ]}
          selectors={
            {
              "responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
              "is_translated": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_glosary": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_modified": {"apiPath":'task/bool', "value":'id',"label":'code'},
              "is_finish": {"apiPath":'task/bool', "value":'id',"label":'code'},

          }}

          editableCols={[
            "paragraph_right",
            "comments",

          ]}
/>
  
  
  </div>

  
      
    )
  }

  
    return (
        <div>
           <LayoutContainer>
            <NavbarTranslator/>
               <div className={s.wrapper3}>Список
                
               </div>
               <Lala></Lala>


           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TranslatorPageDocumentList)

