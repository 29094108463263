import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import TableUniveralSet from '../table/tableUniveralset'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { setExpandedRow, setExpandedRowNumber } from '../../redux/tableSlice'
import { simpleDict } from '../../types/types'


function NavbarIterate(props:
    {apiPath:string,
    func?:Function,
    apiParametres?:simpleDict
    
    }

) {
    let dispatch = useDispatch()

    let expandedRowContent = useSelector((state:RootState) => state.tables.tables[props.apiPath])
    let selectRow = useSelector((state:RootState) => state.tables.expandedRowNumber[props.apiPath])


    useEffect(() => {
        if (expandedRowContent){
        dispatch(setExpandedRow({'tableName':props.apiPath,  'RowNumber':0}))}
    },[dispatch, setExpandedRow,expandedRowContent])

    let right = () =>{
        props.func && props.func()
        if (selectRow==expandedRowContent.length -1) {
            selectRow = 0
        } else {
            selectRow ++
        }
        dispatch(setExpandedRow({'tableName':props.apiPath,  'RowNumber':selectRow}))
        
 
    }

    let left = () =>{
        props.func && props.func()
        if (selectRow==0) {
            selectRow = expandedRowContent.length -1
        } else {
            selectRow = selectRow - 1
        }
        dispatch(setExpandedRow({'tableName':props.apiPath,  'RowNumber':selectRow}))


    }







    return (
        
        <div className="wrapper2">
            <TableUniveralSet
            apiPath={props.apiPath}
            apiParametres={props.apiParametres}
            headers={[]}
            />
            <>
            {expandedRowContent && <>
            <div
            onClick={left}
            >previos</div> 
            <div>{selectRow + 1} / {expandedRowContent.length}</div>
            <div
            onClick={right}
            >next</div>
             </>}
</>


       
        </div>
    )
}




export default compose (
    withSettings
  )(NavbarIterate)