import axios from "axios";
import { selectorTypeApi, selectorTypeDictApi } from "../redux/mdrSlice";
import { transmittalType } from "../redux/transmittalSlice";
import { taskType, ticketType } from "../redux/taskSlice";
import { rootTypeDictApi } from "../redux/rootSlice";
import { GlossaryTypeItemApi, TableApiType, TableRowApiType, VisitsTypeApi } from "../types/types";



//  let baseURL='http://127.0.0.1:8000/'
let baseURL='http://45.90.46.131:7000/'

const instance = axios.create(
  { 
    baseURL: `${baseURL}`,
    }
)

export const gccApi = {
  getMessagesList () {
    return instance.get('mom/messages/')
    .then(response => {
      
      return response.data
    })
  },

  getMessageFetch () {
    return instance.get('mom/fetch/',
      {
        params:
        {
          'number': 2,

        }
      }



    )
    .then(response => {
      
      return response.data
    })
  },


  getMdrSelector (facility:string, subtitle:string, mark:string, doc_type:string): Promise<selectorTypeApi>  {
    return instance.get('mdrselector/' ,
    {
      params:
      {
        'facility': facility,
        'subtitle': subtitle,
        'mark': mark,
        'doc_type': doc_type,
      }
    }
    
    )
    .then(response => {
      
      return response.data
    })
  },


  getSendNotification (): Promise<selectorTypeApi>  {
    return instance.get('mom/sendnotification/' ,    
    )
    .then(response => {
      
      return response.data
    })
  },



  getIncludeMdr () {
    return instance.get('mdrinclude/' ,    
    )
    .then(response => {
      
      return response.data
    })
  },




  prepareNotification (): Promise<selectorTypeApi>  {
    return instance.get('mom/preparenotification/' ,    
    )
    .then(response => {
      
      return response.data
    })
  },

  



  getMdrList (facility:string, subtitle:string, mark:string, doc_type:string): Promise<selectorTypeApi>  {
    return instance.get('mdrfilter/' ,
    {
      params:
      {
        'facility': facility,
        'subtitle': subtitle,
        'mark': mark,
        'doc_type': doc_type,
      }
    }
    
    )
    .then(response => {
      
      return response.data
    })
  },








  

  getTransmittalList (type:string): Promise<Array<transmittalType>>  {
    return instance.get('transmittal/main_filter/' ,
    {
      params:
      {
        'transmittal__type': type,
    
      }
    }
    
    )
    .then(response => {
      
      return response.data
    })
  },


  getDrcList (): Promise<Array<transmittalType>>  {
    return instance.get('revision/drc/' ,
    {
      params:
      {
        'transmittal__type': 0,
    
      }
    }
    
    )
    .then(response => {
      
      return response.data
    })
  },


  getAdrcList (): Promise<Array<transmittalType>>  {
    return instance.get('revision/adrc/' ,
    {
      params:
      {
        'transmittal__type': 0,
    
      }
    }
    
    )
    .then(response => {
      
      return response.data
    })
  },


  // getTasksList (): Promise<Array<transmittalType>>  {
  getTasksList (): Promise<Array<taskType>> {
    return instance.get('task/tasks/' ,
      {
        params:
        {
          'transmittal__type': 0,
        }
      }
    )
    .then(response => 
      {
        return response.data
      }
    )
  },

  getTicketsList (): Promise<Array<ticketType>> {
    return instance.get('task/tickets/' ,
      {
        params:
        {
          'transmittal__type': 0,
        }
      }
    )
    .then(response => 
      {
        return response.data
      }
    )
  },



  getRootDict (): Promise<rootTypeDictApi> {
    return instance.get('task/root/')
    .then(response => {
      
      return response.data
    })
  },



  getMdrSelectorDict (): Promise<selectorTypeDictApi> {
    return instance.get('mdrselectordict/')
    .then(response => {
      
      return response.data
    })
  },

  // getMdrList () {
  //   // return instance.get('mdr/')
  //   return instance.get('mdrfilter/')
  //   .then(response => {
      
  //     return response.data
  //   })
  // },

  getMomList () {
    return instance.get('mom/mom/')
    .then(response => {
      
      return response.data
    })
  },

  getAddressList () {
    return instance.get('mom/address/')
    .then(response => {
      
      return response.data
    })
  },

  getFilesList () {
      return instance.get('revision/revision/')
      .then(response => {
        return response.data
      })
    },

  getTranslationLog () {
    return instance.get('translationlog/')
    .then(response => {
      return response.data
    })
  },


  getGlossary ():Promise<Array<GlossaryTypeItemApi>>  {
    return instance.get('glossary/')
    .then(response => {
      return response.data
    })
  },

  postGlossary (object:GlossaryTypeItemApi): Promise<any>{
    return instance.post('glossary/', object
    // {
    //   "word_left":'11',
    //   "word_right":'11',
    //   "order":'11',
    //   "is_on":true,

    // }
  )
    .then(response => {
      return response.data
    })
  },

  editGlossary (object:GlossaryTypeItemApi): Promise<any>{
    let id = object.id
    return instance.put(`glossary/${id}/`, object)
    .then(response => {
      return response.data
    })
  },

  setGlossary (): Promise<Array<any>>{
    return instance.get('setglossary/')
    .then(response => {
      return response.data
    })
  },


  postTranslation (body:string, language: number, session: number): Promise<any>{

    let object = {
      "content": body,
      "language": language,
      "session": session
  }
    return instance.post('translate/', object)
    .then(response => {
      
      return response.data
    })
  },





  getTable ({path, params}:any):Promise<Array<TableRowApiType>>  {
    let paramObject: any = {}
    params ? paramObject = {params:params} : paramObject = {}
    return instance.get(`${path}/`, paramObject)
    .then(response => {
      return response.data
    })
  },

  postTable ({path, rowContent}:TableApiType): Promise<any>{
    return instance.post(`${path}/`, rowContent)
    .then(response => {
      return response.data
    })
  },

  editTable ({path, id, rowContent}:TableApiType): Promise<any>{
    return instance.put(`${path}/${id}/`, rowContent)
    .then(response => {
      return response.data
    })
  },
  deleteTable ({path, id}:TableApiType): Promise<any>{
    return instance.delete(`${path}/${id}/`)
    .then(response => {
      return response.data
    })
  },



 }

