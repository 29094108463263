import React, { useEffect, useRef } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize';
import { translatorSelector } from '../selectors/selectors'
import { timerTranslatorThunkCreator, updateGlossaryThunk } from '../redux/translatorSlice'
import { MutableRefObject } from "react"
import s from './TranslatorPage.module.css'
// import Table from '../components/table/table'
import { GlossaryTypeItemApi } from '../types/types';
// import TableEdit from '../components/table/tableEdit';
import TableRowspan from '../components/table/tableRowspan';
import TableUniveral from '../components/table/tableUniveral';
import withSettings from '../hoc/withSettings';
import NavbarTranslator from '../components/navbar/NavbarTranslator';


export const TranslatorContent = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()
  
    const updateGlossary = () => dispatch(updateGlossaryThunk())
  

  return (
    <div
    style={{
      "display": "flex",
      "padding":"20px",
      
    
    }}
    >
      <div>
        <Lala/>
      </div>
      <div>
        {/* <p
        onClick ={updateGlossary}
        style={{
         
          // "border":"1px solid #FAF0E6",
          "margin":"5px 0",
          "padding":"3px",
          "background":"lightgreen", 
          "color": "white", 
          "fontSize": "20px",
          "border": "3px solid",
          "borderRadius": "15px",
          "cursor": "pointer",
        }}

        
        >Update glossary</p> */}

      <TableUniveral
    headers={[dictFunc('Russian'),dictFunc('English')]}
    apiPath={'glossary'}
    cols={["word_right", "word_left"]}
    editableCols = {["word_left","word_right"]}
    postCols =  {["word_left","word_right"]}
    isRemoveIdex = {true}
    onSave = {updateGlossary}
    tableHeader={dictFunc('glossary_header')}
    />
      </div>
    </div>
  )
}


const Lala = () => {
  let dispatch:any = useDispatch()
  const translator:any = useSelector(translatorSelector)  
  
  const transRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const transRefeng = useRef() as MutableRefObject<HTMLTextAreaElement>;

  // useEffect(() => {
  //   dispatch(setTranslatorLogThunkCreator())
  // },[dispatch])
  
  let onPostChange = () => {
    let body = transRef.current.value
    dispatch(timerTranslatorThunkCreator(body, 0))
  }

  let onPostChangeEng = () => {
    let body = transRefeng.current.value
    dispatch(timerTranslatorThunkCreator(body, 1))
  }
  
  return (<>
<div   className={s.wrapper2}>
  <div className={s.wrapper}>
    <TextareaAutosize
        style={{
          "fontSize": "23px",
          // "border":"1px solid #FAF0E6",
          "width":"100%",
          "margin":"5px 0",
          "padding":"3px",
          "border": "none",
          "resize": "none",
                
        }}
        minRows={2}
        onChange={onPostChange} 
        value={translator.text}
        ref={transRef}
    />
  </div>
  <div  className={s.wrapper}>
    <TextareaAutosize
        style={{
          "fontSize": "23px",
          // "border":"0px solid #FAF0E6",
          "width":"100%",
          "margin":"5px 0",
          "padding":"3px",
          "border": "none",
          "resize": "none",
        }}
        minRows={2}
        onChange={onPostChangeEng} 
        
        value={translator.translation}
        ref={transRefeng}
    />
  </div>


</div>
  <div   className={s.wrapper2}>
  {/* <TableRowspan
  isAddTime={false}
  headers={[
// '数据库编号',
// '俄语',
// '英语',
// '日期',
// '会话编号',
// '翻译方向',

]}
  content={translator.log}
  rowSpanCols={['id_sesion']}
  cols={["russian", "english", "date", "id_sesion", "type"]}

  /> */}

<TableUniveral
  isAddTime={false}
  headers={[
// '数据库编号',
// '俄语',
// '英语',
// '日期',
// '会话编号',
// '翻译方向',

]}
  apiPath={'translationlog'}
  rowSpanCols={['id_sesion']}
  cols={["russian", "english"]}
  // editableCols={["russian", "english"]}
  isRemoveIdex={true}

  />


  </div>
</>
  )
}

const TranslatorPageAdmin = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
           <NavbarTranslator/>
               {/* <div className={s.wrapper3}>{props.dictFunc('Russian')}-{props.dictFunc('English')}</div> */}
               {/* <Lala></Lala> */}
               <TranslatorContent
               {...props}
               />

           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(TranslatorPageAdmin)

