import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'











const LetterPageAdmin = ({dictFunc}:any) => {
  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['letters/letter'])
    return (
        <div>
           <LayoutContainer>


           <iframe 
           src="http://192.168.30.124/%D0%9A%D0%BE%D1%80%D1%80%D0%B5%D1%81%D0%BF%D0%BE%D0%BD%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D1%8F/%D0%A1%D0%BB%D0%B0%D0%B2%D1%8F%D0%BD%D1%81%D0%BA%20%D0%AD%D0%9A%D0%9E/%D0%92%D1%85%D0%BE%D0%B4%D1%8F%D1%89%D0%B8%D0%B5/000014/%D0%98%D1%81%D1%85.%E2%84%96%D0%AD%D0%9A%D0%9E-%D0%9A-1658%20%D0%BE%D1%82%2027.04.2024%20(%D0%9C%D0%90%D0%A1%D0%9D%D0%A2%D0%95%D0%A1)%20%D0%9E%20%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BF%D0%B5%D1%80%D0%B5%D0%BF%D0%B8%D1%81%D0%BA%D0%B8.pdf" 
           width="540" 
           height="450">
            
           </iframe>


           <NavbarManagement/>

<TableUniveral 
apiPath='letters/letter'
headers={[
  'folder_name',
  "subtype",
  'recipient',
  'number',
  'subject',
  "short_description",
  'body',
  // 'link_local',
  // 'link_global',
  'type',
  'project',
  'time_fact',
  // 'date',
  'deadline',
  "complete_status",
]}
cols={[
  'folder_name',
  "subtype",
  'company',
  'number',
  'subject',
  "short_description",
  'body',
  // 'link_local',
  // 'link_global',
  'type',
  'project',
  'time_fact',
  // 'date',
  'deadline',
  "complete_status",
]}
editableCols={[
  'subject',
  'number',
  'body',
  "short_description",
  'link_local',
  'link_global',
  // 'type',
  // 'project',
  // 'time_fact',
  'date',
  'deadline',
]}
// postCols={['subject']}
selectors={
  {
    "type": {"apiPath":'letters/type', "value":'id',"label":'code', isLocked:true},
    "project": {"apiPath":'letters/projects', "value":'id',"label":'short_name', isLocked:true},
    "company": {"apiPath":'letters/company', "value":'id',"label":'full_name'},
    "deadline": {"apiPath":'task/calendar', "value":'id',"label":'date_calendar', isLocked:true},
    "time_fact": {"apiPath":'task/calendar', "value":'id',"label":'date_calendar'},
    "subtype": {"apiPath":'letters/subtype', "value":'id',"label":'code'},
    "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
    
}
}
tableHeader='Официальные письма'
>

{expandedRowContent &&


<>

<TableUniveral
  headers={[]}
  apiPath='letters/files'
  apiParametres={{
    'transmittal': expandedRowContent.id, 
    'extension':'0',
  }}
  cols={[
    "file_name", "date_modified",
  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}
/>


<TableUniveral 
apiPath='letters/letterlink'
apiParametres={{"letter":expandedRowContent.id}}
postColsData={{"letter":expandedRowContent.id}}
isRemoveIdex={true}
headers={[
  // 'headDescription',
  // 'letter',
  // 'head_type',
  // 'head_link',

]}
cols={[
  'headDescription',
  'letter',
  'head_type',
  'head_link',
]}
editableCols={[
  'headDescription',
]}
postCols={['headDescription']}
selectors={
  {
    "head_type": {"apiPath":'letters/letterlinktype', "value":'id',"label":'code'},
    "letter": {"apiPath":'letters/letter', "value":'id',"label":'subject', "isLocked":true},
    "head_link": {"apiPath":'letters/letter', "value":'id',"label":'subject'},
}
}
tableHeader='Связи писем'
/> 


<TableUniveral 
apiPath='letters/notification'
apiParametres={{"letter":expandedRowContent.id}}
postColsData={{"letter":expandedRowContent.id}}
headers={[

]}
cols={[
'comments',
'letter',
// 'task',
// 'transmittal',
'team', 
]
}
editableCols={[
  'comments'
]}
postCols={[
  'comments'
]}

selectors={
  {
    "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
}
}
tableHeader='уведомления писем'
/> 

</>

}


{!expandedRowContent &&
<></>
}

  
</TableUniveral> 







           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(LetterPageAdmin)






