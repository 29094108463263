import React, { useEffect } from 'react'
import Button from '../button/button'

import s from './PripareTask.module.css'


import { useDispatch } from 'react-redux'
import { getMdrSelectorThunkCreator } from '../../redux/mdrSlice'
import { setShowModalWindow, ticketType } from '../../redux/taskSlice'
import Dropdown from '../dropdown/dropdown'

export interface selectorTotalType {
  [key: string]: Array<number>
}

function PrepareTask(props:ticketType ) {
  let dispatch:any = useDispatch()

  let onClick = (group:string, id:number) => {

    dispatch(setShowModalWindow(true))
  }

  useEffect(() => {
    dispatch(getMdrSelectorThunkCreator('',-1))
  },[dispatch])
  return (

    <div className={s.wrapper}>
      <div>{props.start} Task: {props.task} Команда: {props.team}</div>
        <Button 
          id={1}
          content={'Approve'}
          key={1} 
          onClick={onClick}
          type={0}
          groupId={0}
        /> 




        <Button 
          id={1}
          content={'select team'}
          key={1} 
          onClick={onClick}
          type={0}
          groupId={0}
        /> 
      
    </div>

  )
}

export default PrepareTask