import { RootState } from "../redux/store"

export const filesSelector:any = (state:RootState) => {
    return state.files.files
}


export const momSelector:any = (state:any) => {
    return state.mom.mom
}

export const addressSelector:any = (state:any) => {
    return state.address.address
}


export const messageSelector:any = (state:RootState) => {
    return state.messages.messages
}


export const translatorSelector:any = (state:RootState) => {
    return state.translator
}

// export const glossarySelector:any = (state:RootState) => {
//     return state.translator.glossary
// }


export const mdrSelector:any = (state:RootState) => {
    
    return state.mdr.mdr
}

export const mdrSelectorTotal:any = (state:any) => {
    
    return state.mdr.totalSelector
}

export const mdrSelectorNew:any = (state:RootState) => {
    
    return state.mdr.newSelector
}

export const mdrSelectorDict:any = (state:RootState) => {
    return state.mdr.dictSelector
}

export const transmittalSelector:any = (state:RootState) => {
    
    return state.transmittal.transmittal
}

export const transmittalSelectorList:any = (state:RootState) => {
    
    return state.transmittal.newSelector
}

export const transmittalSelectorDict:any = (state:RootState) => {
    
    return state.transmittal.dictSelector
}


export const drcSelector:any = (state:RootState) => {
    
    return state.drc.drc
}

export const taskSelector: any = (state:RootState) => {
    
    return state.task.task
}

export const taskModalSelector: any = (state:RootState) => {
    
    return state.task.show_modal
}

export const ticketSelector:any = (state:RootState) => {
    
    return state.task.ticket
}


export const rootSelector:any = (state:RootState) => {  
    return state.root
}

