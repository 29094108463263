import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/store'
import TableUniveral from './components/table/tableUniveral';

const container:any = document.getElementById('root');
const root = createRoot(container)
root.render(
  
  <Provider store ={store}>

    <App />
  </Provider>
);




