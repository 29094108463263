import React from 'react'
import withSettings from '../../hoc/withSettings'
import { compose } from '@reduxjs/toolkit'
import TableUniveral from '../table/tableUniveral'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
export const OneTeam = (props:any) => {



  let expandedRowContent:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register2'])
  let expandedRowContent2:any = useSelector((state:RootState) => state.tables.expandedRowContent['transmittal/register3'])
  let expandedRowContent3:any = useSelector((state:RootState) => state.tables.expandedRowContent['mdr'])



  let type = props.type
  let lineral = props.lineral
return (
<>
<TableUniveral
isDisapearIfEmpty={true} 
apiPath='task/normal'
apiParametres={{
  'complete_status':0, 
  'responsible':props.myTeamId, 
  'responsible__team_type':type,
  'responsible__isLinear': lineral
}}
headers={[
// "date",
"тип задачи",
"Названи",
"Описание",
"Ответ разработчика",
"responsible",
"urgent_status",
"Срок выполнения",
"Автор",
"Статус задачи",
]}
tableHeader='Очень срочные входящие задачи'
selectors={
{
  "todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name', "isLocked":true},
  "responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
  "author": {"apiPath":'task/teams', "value":'id',"label":'name', "isLocked":true},
  "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
  "urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},

}}
editableCols={[ "response",]}
cols={[
// "date",
"todo_type",
"text1",
"text2",
"response",
"responsible",
"urgent_status",
"deadline",
"author",
"complete_status",
]}
/>  

{/* {!(type >= 0) &&
<TableUniveral 
isDisapearIfEmpty={true} 
apiPath='task/urgentout'
apiParametres={{
  'complete_status':0, 
  'author':props.myTeamId,

}}
headers={[
// "date",
"тип задачи",
"Названи",
"Описание",
"Ответ разработчика",
"responsible",
"urgent_status",
"Срок выполнения",
"Автор",
"Статус задачи",
]}
tableHeader='Очень срочные исходящие задачи'
selectors={
{
"todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name'},
"responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
"author": {"apiPath":'task/teams', "value":'id',"label":'name'},
"complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
"urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},
}}
editableCols={["text1","text2"]}
cols={[
// "date",
"todo_type",
"text1",
"text2",
"response",
"responsible",
"urgent_status",
"deadline",
"author",
"complete_status",
]}
/>   
} */}

{!(type >= 0) &&
<TableUniveral
  isDisapearIfEmpty={true}
  tableHeader='Выпуск документации'
  headers={[
    // "id DB",
    "N TRM",
    "Имя",
    "Тип",
    "Дата",
    "Команда",
    "Выгружен?",
    "Комментарий",
    "Применчание",    
  ]}
  selectors={
    {
      "subtype": {"apiPath":'transmittal/subtypeoout', "value":'id',"label":'code',"isLocked": true},
      // "finalization": {"apiPath":'transmittal/finalization', "value":'id',"label":'code'},
      "team": {"apiPath":'task/teams', "value":'id',"label":'name'},
      "is_submitted": {"apiPath":'task/bool', "value":'id',"label":'code', "isLocked": true},
  }
  }
  editableCols={[
    // "drc_number",
    // "comments",
    "comments2",
    // "due_date",
  ]}

  apiPath='transmittal/register3'
  apiParametres={{"is_submitted":0, "team":props.myTeamId}}
  cols={[

    // "id",
    "number",
    "name",
    "subtype",
    "date",
    "team",
    "is_submitted",
    "comments",
    "comments2",
]}
  > 

{expandedRowContent2 &&
  <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":expandedRowContent2.id, "extension":"0"}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [                  
                    'file_name',
                    "global_path",

                    ]}

                  /> 
}
{!expandedRowContent2 &&
<></>
}




  
  
  </TableUniveral> 






}

<TableUniveral 
isDisapearIfEmpty={true} 
apiPath='task/high'
apiParametres={{
  'complete_status':0, 
  'responsible':props.myTeamId,
  'responsible__team_type':type,
  'responsible__isLinear': lineral,

}}
headers={[
// "date",
"тип задачи",
"Названи",
"Описание",
"Ответ разработчика",
"responsible",
"urgent_status",
"Срок выполнения",
"Автор",
"Статус задачи",
]}
tableHeader='Текущие задачи'
selectors={
{
"todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name', "isLocked":true},
"responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
"author": {"apiPath":'task/teams', "value":'id',"label":'name', "isLocked":true},
"complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
"urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},
}}
editableCols={[ "response",]}
cols={[
// "date",
"todo_type",
"text1",
"text2",
"response",
"responsible",
"urgent_status",
"deadline",
"author",
"complete_status",
]}
/>      
<TableUniveral 
isDisapearIfEmpty={true} 
apiPath='transmittal/register2'
apiParametres={{'finalization':0, 'team':props.myTeamId,}}
headers={[


"N TRM",
"Тип",
"name",
"Номер DRC",
"Команда",
"Срок",
"Коментарий",
"Ответ разработчика",
]}
selectors={
{
"subtype": {"apiPath":'transmittal/subtype', "value":'id',"label":'code', isLocked:true},
"team": {"apiPath":'task/teams', "value":'id',"label":'name'},
}
}
cols ={
[

  // "id",
"number",
"subtype",
"name",
"drc_number",
"team",
"due_date",
"comments",
"comments2",
// "date",
// "finalization",
// "is_submitted",
]
}
editableCols={["comments2"]}
tableHeader='Ревизии'
> 


{expandedRowContent &&
  <TableUniveral
                  apiPath='transmittal/files'
                  apiParametres={{"transmittal":expandedRowContent.id, "extension":"0"}}
                   headers={[
       
                  ]}
                  copyColumnName={["file_name"]}
                  isRemoveIdex={true}

                  cols={  [                  
                    'file_name',
                    "global_path",
                    ]}

                  /> 
}
{!expandedRowContent &&
<></>
}




  
  
  </TableUniveral>











{!(type >= 0) &&
<TableUniveral 
isDisapearIfEmpty={true} 
apiPath='task/low'
apiParametres={{'complete_status':0, 'responsible':props.myTeamId,}}
headers={[
// "date",
"тип задачи",
"Название",
"Описание",
"Ответ разработчика",
"responsible",
"urgent_status",
"Срок выполнения",
"Автор",
"Статус задачи",
]}
tableHeader='Второстепенные задачи'
selectors={
{
"todo_type": {"apiPath":'task/todotype', "value":'id',"label":'name', "isLocked":true},
"responsible": {"apiPath":'task/teams', "value":'id',"label":'name'},
"author": {"apiPath":'task/teams', "value":'id',"label":'name', "isLocked":true},
"complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},
"urgent_status": {"apiPath":'task/taskstatus', "value":'id',"label":'code'},
}}
editableCols={[ "response",]}
cols={[
// "date",
"todo_type",
"text1",
"text2",
"response",
"responsible",
"urgent_status",
"deadline",
"author",
"complete_status",
]}
/>   
}




{!(type >= 0) &&
<TableUniveral 
isDisapearIfEmpty={true} 
apiPath='mdr'
apiParametres={{'complete_status':0, 'team':props.myTeamId,}}
headers={[

  "doc_number",
  'doc_title',	
  'comments',
  "deadline",
  "complete_status",
]}
cols={[
  "doc_number",
  'doc_title',	
  'comments',
  "deadline",
  "complete_status",


]}

selectors={
  {

    "deadline": {"apiPath":'task/calendar', "value":'id',"label":'date_calendar', isLocked:true},
    "complete_status": {"apiPath":'task/completestatus', "value":'id',"label":'code'},

    
}
}

editableCols={
  ['comments']
}
tableHeader='Разработка документации'

>


{expandedRowContent3 &&

<TableUniveral
  headers={[]}
  apiPath='transmittal/files'
  apiParametres={{
    'search': expandedRowContent3.doc_number, 
    'extension':'0',
  }}
  cols={[
    "file_name", "date_modified",
  ]}
  copyColumnName={["file_name"]}
  isRemoveIdex={true}
/>
}
{!expandedRowContent &&<></>}

</TableUniveral>   
}






</>
)
}
export default compose (
withSettings
)(OneTeam)





