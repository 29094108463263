import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import NavbarManagement from '../components/navbar/NavbarManagement'
import NavbarNotification from '../components/navbar/NavbarNotification'
import TableUniveralSet from '../components/table/tableUniveralset'
import Button from '../components/button/button'
import { useNavigate } from 'react-router-dom'
import { gccApi } from '../api/api'
import { useEffect, useState } from 'react'

const NotificationOutboxPage = ({dictFunc}:any) => {
  const navigate = useNavigate();

  const [isUpdated, setIsUpdated] = useState(false)
  useEffect(() => {
    gccApi.prepareNotification().then(data => {
      console.log(data)
      setIsUpdated(true)
          }) 
  },[])

  let onClick = ()=>{

    gccApi.getSendNotification().then(data => {
      console.log(data)
      navigate("../sent", { relative: "path" })

          })    

  }
    return (
        <div>
           <LayoutContainer>
           <NavbarManagement/>
           <NavbarNotification/>
           {/* <TableUniveralSet
          headers={[]}
          apiPath='mom/preparenotification'
          /> */}
                      <Button
            onClick={onClick}
            content='Oтправить уведомления'
            type={1}
            groupId={1}
            id={1}
            />
            {isUpdated &&
                <TableUniveral 
                   apiPath='mom/outboxprepare'
                   headers={[
                    "subject",
                    "body",
                    "fro_addr",
                    "to_addr",
                    "cc_addr",
                    "trm",
                    "type",
                    "is_sent",

      
                   ]}
                   cols={[
                    "subject",
                    "body",
                    "fro_addr",
                    "to_addr",
                    "cc_addr",
                    "trm",
                    "type",
                    "is_sent",
                   ]}

                   editableCols={[
                    "subject",
                    "body",
                    "fro_addr",
                    "to_addr",
                    "cc_addr",
                    // "trm",
                    // "type",
                    // "is_sent",
                   ]}
                   tableHeader='Исходящие сообщения'

                   selectors={
                    {
                      "is_sent": {"apiPath":'task/bool', "value":'id',"label":'code'},
                  }
                }



               /> 
              }

   
           </LayoutContainer>
        </div>
    )
}


export default compose (
  withSettings
)(NotificationOutboxPage)


