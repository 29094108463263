import { simpleDict } from "../types/types"
import { itemSelectorType, selectorType } from "./mdrSlice"

export const utilities = {
listToStringQuery (list:Array<number>):string {
    let t:string =''
    for (let item in list) {
        t = t + list[item].toString() + '-' 
    }
    return t.slice(0, -1)
},

buildMainSelector (bigArr:Array<Array<number>>): Array<itemSelectorType> {
    let mainArray:Array<itemSelectorType> = []

    let i:number = 0
    for (let item in bigArr){
        let sorter_array: Array<number> = bigArr[item].sort()
        for (let item2 in sorter_array){ 
        // for (let item2 in bigArr[item]){ 
            const itemSelector:itemSelectorType = {
                id: i,
                groupId: parseInt(item),
                dbId: bigArr[item][item2],
                buttonState: 0,   
            }
            mainArray.push(itemSelector)
            i = i + 1
        }   
    }
    return mainArray
},

mainSelectorToIndexes (mainArray: Array<itemSelectorType>, state_index:number): selectorType{
    let selectorDict:selectorType = {
        'fac': [],
        'sub': [],
        'mark': [],
        'doc': []
    }
    let array_fac: Array<number> = []
    let array_sub: Array<number> = []
    let array_mark: Array<number> = []
    let array_doc: Array<number> = []

    for (let ind in mainArray) {
        let item = mainArray[ind]

        if (item.buttonState===state_index){
            if (item.groupId === 0) {
                array_fac.push(item.dbId)
            }
            if (item.groupId === 1) {
                array_sub.push(item.dbId)
            }
            if (item.groupId === 2) {
                array_mark.push(item.dbId)
            }
            if (item.groupId === 3) {
                array_doc.push(item.dbId)
            }
        }
    }
    selectorDict.fac = array_fac
    selectorDict.sub = array_sub
    selectorDict.mark = array_mark
    selectorDict.doc = array_doc
    return selectorDict
},

turnButtonGray (state:Array<itemSelectorType>, bigArr:Array<Array<number>>):Array<itemSelectorType>{
    let mainArray:Array<itemSelectorType> = []
    let arrayAvalible:Array<itemSelectorType> = this.buildMainSelector(bigArr)

    for (let ind in state) {
        let itemOld = state[ind]
        let item = JSON.parse(JSON.stringify(itemOld))
        for (let ind2 in arrayAvalible){
            let item2 = arrayAvalible[ind2]
            if (item.groupId === item2.groupId && item.dbId === item2.dbId){
               item.buttonState === 1 ? item.buttonState = 1 : item.buttonState = 0
               break
            } else {
                item.buttonState === 1 ? item.buttonState = 1 : item.buttonState = 2
            }
            
        }
        mainArray.push(item)
    }

    return mainArray
},



isSeparate (initialSting: string, sepateSymbol: string): boolean{
    let answer: boolean
    if (typeof(initialSting)!=='string') {
        return false
    }

    let symbolIndex = initialSting.indexOf(sepateSymbol)
    let lenght = initialSting.length
    let range = lenght / 3
    let middle = lenght / 2
    let left_border = middle - range
    let right_border = middle + range

    if (symbolIndex > 0) {
        if (symbolIndex > left_border && symbolIndex < right_border) {
            answer = true
        } else {
            answer = false
        }
    } else {
        answer = false
    }

    return answer
},


sepateCellBySlash (initialSting: string, sepateSymbol: string): Array<string>{

    let symbolIndex = initialSting.indexOf(sepateSymbol)
    let left_part = initialSting.slice(0, symbolIndex);
    let right_part = initialSting.slice(symbolIndex + 1);

    return [left_part, right_part]
},



sepateRowProps (content: Array<any>, sepateSymbol: string): Array<any>{
   let newArray:Array<any> = []
    for (let value of content) {
        if (utilities.isSeparate(value, sepateSymbol)) {
            let left:string = utilities.sepateCellBySlash(value,sepateSymbol)[0]
            let rigth:string = utilities.sepateCellBySlash(value,sepateSymbol)[1]
            newArray.push(left, rigth)
        } else {
            // newArray.push(value)
            newArray.push(value)
        }
        // newArray.push(utilities.isSeparate(value, '/'))
    }

    return newArray
},



isDate (content: any): boolean {
    let answer:boolean

    if (typeof(content)==='string'){
        if (content.length > 19){
            if (content.slice(0,3)==='202'){
                answer = true
            } else {
                answer = false
            }
        } else {
            answer = false
        }
    } else {
        answer = false
    }
     return answer
 },


parseDate (content: string): Array<string> {
    // console.log(content)
    let date1=new Date(content).toDateString() //"Thu Apr 25 2024"
    // date1=new Date(content).toUTCString() //"Thu, 25 Apr 2024 17:54:34 GMT"
    // date1=new Date(content).toLocaleDateString() //"4/25/2024"
    date1=new Date(content).toLocaleDateString('ru-RU', {
        month: '2-digit',day: '2-digit',year: 'numeric'})
    // date1=new Date(content).toString() //"4/25/2024"
    // date1=new Date(content).toLocaleString() //"4/25/2024, 10:54:34 PM"
    let time=new Date(content).toLocaleTimeString('en-US', { hour12: false, hour: "2-digit", minute: "2-digit" })

     return [date1, time]
 },



isCellRowpan (arrayContent: Array<string|number>, start:number): boolean {
    if (arrayContent[start]===arrayContent[start + 1]){
        return true
    } else {
        return false
    }
},

isCellOff (arrayContent: Array<string|number>, start:number): boolean {
    if (start===0){
        return false
    }
    if (arrayContent[start]===arrayContent[start - 1]){
        return true
    } else {
        return false
    }
},
    


 desineRospanNumber (arrayContent: Array<string|number>, start:number): any {
    let arraySlice:Array<any> = arrayContent.slice(start)
    let index = 0
    let counter = 1    
    for (let item of arraySlice) {
        if (index===0){
            index += 1
            continue
        }
        if (item===arraySlice[index-1]){
            counter += 1
        } else {
            break
        }
        index += 1
    }
     return counter 
 },


 getListFromDictByKey (arrayContent: Array<simpleDict>, key:string): Array<string|number> {
    let newArray: Array<any> =[]


    for (let item of arrayContent){
        newArray.push(item[key])
    }
     return newArray
 },




 getDictFromDictByKey (arrayContent: Array<simpleDict>, keyArray:Array<string>): {[key:string]:Array<string|number>} {

    let newDict:{[key:string]:Array<string|number>} = {}
    for (let item of keyArray){
        newDict[item] = this.getListFromDictByKey(arrayContent, item)
    }

     return newDict
     
},


addIndexDict(contenArray:Array<simpleDict>):Array<simpleDict>{
    let count = 0
    let newArray: Array<simpleDict> = []
    for (let item of contenArray) {
        let oldDict = {...item}
        oldDict["rowIndex"] = contenArray.length - count
        count += 1
        newArray.push(oldDict)
    }

    return (newArray)
},

moveRowIndexBegin(columnsArray:Array<string>):Array<string>{
    let newArray:Array<string>=[]

    for (let item of columnsArray){
        if (item === "rowIndex") {

        } else {
            newArray.push(item)
        }

    }
    
    
    
    
    return ["rowIndex"].concat(newArray)
},


convertPathToName(path:string):string{

    return path

},


resetDictValues(dict:simpleDict):simpleDict{
    let copyDict = {...dict}
    for (let key of Object.keys(copyDict)){
        copyDict[key]=''
    }
    return copyDict

},


checkIfSelectedFilled(dict:simpleDict, array:Array<string>):boolean {
    // console.log(dict)

    for (let key of array){
        if (dict[key] ==='')
            return false
    }

    return true
},

deleteUselessKeys(dict:simpleDict, array:Array<string>):simpleDict{
    let copyDict:simpleDict = {}
    for (let key of Object.keys(dict)){
        if (array.includes(key)){
            // copyDict[key] = dict[key]
            copyDict[key] = dict[key]
           
        }
    }
    return copyDict
},



findSymbol(baseString:any, symbol:any):boolean {
    if (baseString == null) {baseString = ''}
    let baseString2 = baseString.toString().toLowerCase()
    let symbol2 = symbol.toString().toLowerCase()
    if (baseString2.indexOf(symbol2) > -1){
        return true
    } else {
        return false
    }
},


findSymbolBegin(baseString:any, symbol:any):boolean {
    if (baseString == null) {baseString = ''}
    let baseString2 = baseString.toString().toLowerCase()
    let symbol2 = symbol.toString().toLowerCase()
    if (baseString2.startsWith(symbol2)){
        return true
    } else {
        return false
    }
},


filterDict(array:Array<simpleDict>, filtePair:Array<string>):Array<simpleDict>{
    let firstKey:string = filtePair[0]
    let firstValue:any = filtePair[1]
    let newArra = array.filter((row) => 
        this.findSymbol(row[firstKey], firstValue) )
return newArra
},


filterDictComplex(array:Array<simpleDict>, filtePair:Array<string>):Array<simpleDict>{
    let firstKey:string = filtePair[0]
    let firstValue:any = filtePair[1]
    let newArra = array.filter((row) => 
        this.findSymbolBegin(row[firstKey], firstValue))
    
    let newArra2 = array.filter((row) => 
        this.findSymbol(row[firstKey], firstValue) && !this.findSymbolBegin(row[firstKey], firstValue)
)


return [...newArra, ...newArra2]
},

filterDictBegin(array:Array<simpleDict>, filtePair:Array<string>):Array<simpleDict>{
    let firstKey:string = filtePair[0]
    let firstValue:any = filtePair[1]
    let newArra = array.filter((row) => 
        this.findSymbolBegin(row[firstKey], firstValue))
return newArra
},

filterDictNotBegin(array:Array<simpleDict>, filtePair:Array<string>):Array<simpleDict>{
    let firstKey:string = filtePair[0]
    let firstValue:any = filtePair[1]
    let newArra = array.filter((row) => 
        this.findSymbol(row[firstKey], firstValue) && !this.findSymbolBegin(row[firstKey], firstValue)
)
return newArra
},


filterDictRecursiveComplex(array:Array<simpleDict>, filterDict:simpleDict):Array<simpleDict>{

    let keys:Array<string> = Object.keys(filterDict)
    let newArra:Array<simpleDict> =[...array]
    let newArra2:Array<simpleDict> =[...array]
    let newArra1:Array<simpleDict> =[...array]

    // for (let key of keys){
    //     let seachArray = [key, filterDict[key]]
    //     newArra = this.filterDictBegin(newArra, seachArray)
    // }
    for (let key of keys){
        let seachArray = [key, filterDict[key]]
        if (filterDict[key] === ''){continue}
        newArra1 = this.filterDictBegin(newArra, seachArray)
        newArra2 = this.filterDictNotBegin(newArra, seachArray)
        newArra = [...newArra1, ...newArra2]

    }



    
return [...newArra]
},



filterDictRecursive(array:Array<simpleDict>, filterDict:simpleDict):Array<simpleDict>{

    let keys:Array<string> = Object.keys(filterDict)
    let newArra:Array<simpleDict> =[...array]

    for (let key of keys){
        let seachArray = [key, filterDict[key]]
        newArra = this.filterDict(newArra, seachArray)
    }

return newArra
},



reduceArrayToDict(array:Array<simpleDict>, firstKey: string, secondKeyValue: string):simpleDict{
    let newDict:simpleDict = {}

    for (let dict of array){
        let newKey:string = dict[firstKey]
        let newValue:any = dict[secondKeyValue]
        newDict[newKey] = newValue
    }
return newDict
},




pathToObject(complexObject:{[key:string]:any}, path:string): any {
    let pathArray:Array<string> = path.split('.')
    let newObj:any
    for (let it of pathArray){
        complexObject = complexObject[it]
    }
return complexObject
},


returnPositionArray(array:Array<any>, element:any): Array<number> {
    return [array.indexOf(element) + 1,array.length]
},

scrollArray(array:Array<any>, element:any, toRight:boolean): any {
    let ind:number = array.indexOf(element)
    let len:number = array.length
    let newInd:number
    if (toRight) {
        newInd = ind + 1
    } else {
        newInd = ind - 1
    }

    if (newInd < 0) {
        newInd = len - 1
    } else if (newInd > len - 1) {
        newInd = 0
    }
    return array[newInd]
},





// сделать обновленный фильтр
//сделать путь к объетку
// createDeepCopy()
// let newData = data.map((item) => 
//     Object.assign({}, item, {selected:false})
// )



}
