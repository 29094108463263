import React, { useEffect, useState } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import TableUniveral from '../components/table/tableUniveral'
import withSettings from '../hoc/withSettings'
import { gccApi } from '../api/api'
import NavbarReports from '../components/navbar/NavbarReports'
import NavbarEmail from '../components/navbar/NavbarEmail'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

const EmailSettingsPage = ({dictFunc}:any) => {


  // console.log(expandedRowContent.id)


  
return (
<div>
  <LayoutContainer>



    <h1>Настройки</h1>
    <NavbarEmail/>

<TableUniveral
tableHeader='Типы писем'
apiPath='mom/messagestypes'
headers={[          
"code",
"comments",]}
cols={[
"code",
"comments",
]}
editableCols={[
"code",
"comments",
]}
postCols={[
"code"
]}
/>



<TableUniveral
tableHeader='Тестовые сообщения'
apiPath='mom/messagetest'
headers={[          
"full_text",
"short_text",
]}
cols={[
"full_text",
"short_text",
]}
editableCols={[
"full_text",
"short_text",
]}
postCols={[
"full_text"
]}
/>

<TableUniveral
tableHeader='Типы паттернов'
apiPath='mom/messagepatternstypes'
headers={[          
"code",

"comments",
]}
cols={[
"code",

"comments",

]}
editableCols={[
"code",
"comments",
]}
postCols={[
"code"
]}
/>

<TableUniveral
tableHeader='Паттерны'
apiPath='mom/messagepatterns'
headers={[          
"code",
"type",
"Для темы письма?",
"order",
"comments",
]}
cols={[
"code",
"type",
"is_subject",
"order",
"comments",

]}
editableCols={[
"code",
"comments",
"order",
]}
postCols={[
"code"
]}
selectors={
  {
    "type": {"apiPath":'mom/messagepatternstypes', "value":'id',"label":'code'},
    "is_subject": {"apiPath":'task/bool', "value":'id',"label":'code'},
}

}
/>


{/* 
router.register(r'messagetest', MessageTest, basename='messagetest')
router.register(r'messagepatternstypes', MessagePatternsTypes, basename='messagepatternstypes')
router.register(r'messagepatterns', MessagePatterns, basename='messagepatterns') */}



  </LayoutContainer>
</div>
)
}

export default compose (
  withSettings
)(EmailSettingsPage)


