
import s from './LayoutContainer.module.css'
// import { Header } from '../header/Header'
import Navbar from '../navbar/Navbar'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getRootThunkCreator } from '../../redux/rootSlice'
import Footer from '../footer/Footer'
import Header from '../header/Header'

const LayoutContainer = (props:any) => {
  let dispatch:any = useDispatch()

  
  useEffect(() => {
    // document.title = 'GCC'
    dispatch(getRootThunkCreator())

    // dispatch(taskHandleClickThunkCreator())
   
  },[dispatch])



  return (
    <div>
        <div className={s.appwrapper} >
          <div className={s.header}>
            <Header />
          </div>
          <div className={s.navigation}><Navbar /></div>
          <div className={s.content}> {props.children} </div>
          <div className={s.footer}><Footer/></div>
        </div>
    </div>
  )
}

export default LayoutContainer