import React, { useEffect } from 'react' 
import { compose } from 'redux'
import LayoutContainer from '../components/containers/LayoutContainer'
import { useDispatch, useSelector } from 'react-redux'
import { TranHandleClickThunkCreator } from '../redux/transmittalSlice'
import TransmittalChoice from '../components/mdr_selector/TransmittalChoice'
import TableUniveral from '../components/table/tableUniveral'
import { RootState } from '../redux/store'
import withSettings from '../hoc/withSettings'
import DropdownUniveral from '../components/dropdown/dropdownUniversal'
import { isEditable } from '@testing-library/user-event/dist/utils'
import TableUniveralSet from '../components/table/tableUniveralset'
import NavbarManagement from '../components/navbar/NavbarManagement'



const Lala = ({dictFunc}:any) => {
  let dispatch:any = useDispatch()
  const buttonState = useSelector((state:RootState)=>state.transmittal.newSelector)
  let selected:any = buttonState.find(string=>string.buttonState===1)?.dbId || 0
  const finalisationSele  = useSelector((state:RootState) => state.tables.tables['interface/settings']) as any
  let finalisation = 0
  if (finalisationSele){
    // const result = words.filter((word) => word.length > 6);
    finalisation = finalisationSele.filter((item:any) => item.id ===2)[0].value
  }

  useEffect(() => {
    dispatch(TranHandleClickThunkCreator(0))
  },[dispatch])
  return (
<div>
<NavbarManagement/>


<TableUniveralSet
          apiPath={'appstat/adrcextension'}
          headers={[]}
/>



  <TableUniveral
  tableHeader='Глобальный статус'
  headers={[
    "order",
    "trm_doc_inbox",
    "drc",
    "is_no_comment",
    "trm_drc_out",
    "is_closed",
    "is_canceled",
    "is_nce_side",
    "comments_nce",
    "comments_cce",
    "action",
    "due_date",

    
  ]}
  selectors={
    {
      // "subtype": {"apiPath":'transmittal/subtype', "value":'id',"label":'code'},
      // "finalization": {"apiPath":'transmittal/finalization', "value":'id',"label":'code'},
      // "team": {"apiPath":'task/teams', "value":'id',"label":'name'},


      "drc": {"apiPath":'revision/drcselector', "value":'id',"label":'number'},
      "trm_drc_out": {"apiPath":'transmittal/trmdrcselector', "value":'id',"label":'number'},
      "trm_doc_inbox": {"apiPath":'transmittal/trminboxselector', "value":'id',"label":'number', isLocked:true},

      "is_no_comment": {"apiPath":'task/bool', "value":'id',"label":'code'},
      "is_closed": {"apiPath":'task/bool', "value":'id',"label":'code'},
      "is_canceled": {"apiPath":'task/bool', "value":'id',"label":'code'},
      "is_nce_side": {"apiPath":'task/bool', "value":'id',"label":'code'},
  }
  

  }

  editableCols={[
    "order",

    "comments_nce",
    "comments_cce",
    "action",
    "due_date",

  ]}



  apiPath='appstat/adrcstat'

  cols={[
    "order",
    "trm_doc_inbox",
    "drc",
    "is_no_comment",
    "trm_drc_out",
    "is_closed",
    "is_canceled",
    "is_nce_side",
    "comments_nce",
    "comments_cce",
    "action",
    "due_date",
  ]}




  /> 
</div>
    
  )
}
const RegisterPage4 = (props:any) => {
  
    return (
        <div>
           <LayoutContainer>
               <Lala
               {...props}
               ></Lala>
           </LayoutContainer>
        </div>
    )
}

export default compose (
  withSettings
)(RegisterPage4)


