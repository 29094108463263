import { createSlice } from '@reduxjs/toolkit'
import { gccApi } from '../api/api'
// import { selectorTotalType } from '../components/mdr_selector/MdrChoice'
import { utilities } from './utilities'

type mdrType = {
doc_number:string
doc_title:string
doc_type: number
facility: number
ifr_plan: string | null
key_doc: number
key_milestone: string | null
mark: any
serial_number: number
subtitle: number
}

export type selectorType ={
    'fac': Array<number>,
    'sub': Array<number>,
    'mark': Array<number>,
    'doc': Array<number>
  
}

export type selectorTypeDictApi ={
  'fac': Array<any>,
  'sub': Array<any>,
  'mark': Array<any>,
  'doc': Array<any>
}

export type selectorTypeApi ={
  'facility': Array<number>,
  'subtitle': Array<number>,
  'mark': Array<number>,
  'doc_type': Array<number>

}

export type itemSelectorType = {
  id: number
  groupId: number
  dbId: number
  buttonState: number
}

type initialStateType ={
  status: string,
  mdr: Array<mdrType>
  dictSelector: selectorType
  newSelector: Array<itemSelectorType>

}


const initialState:initialStateType = {
  newSelector: [],
  status: 'idle',
  mdr: [],
  dictSelector: {
    'fac': [],
    'sub': [],
    'mark': [],
    'doc': []
  },

}

const mdrSlice = createSlice({
  name: 'mdr',
  initialState,
  reducers: {
    setMdr(state, action) {
      const fil = action.payload
      state.mdr = fil
    },

    setSelectorNew(state, action) {
      const fil = action.payload
      state.newSelector = fil
    },
    setSelectorDict(state, action) {
      const fil = action.payload
      state.dictSelector = fil
    },
    makeClick(state, action){
      const fil = action.payload

  

      state.newSelector[fil[1]].buttonState=fil[0]
    }

  }
})

//===========================THUNK================================


export const getMdrSelectorThunkCreator =(group:string, id:number) => {
  let facility = ''
  let subtilte = ''
  let mark = ''
  let doc_type = ''

  return (dispatch:any,getState:any) => {
    gccApi.getMdrSelectorDict().then(data => {
      let name_dict:any = {}
      name_dict[0] = data.fac
      name_dict[1] = data.sub
      name_dict[2] = data.mark
      name_dict[3] = data.doc
      dispatch(setSelectorDict(name_dict)) 
      // dispatch(makeClick({'group':group, 'id':id}))
      gccApi.getMdrSelector(facility, subtilte, mark, doc_type).then(data => {
        let bigArray = utilities.buildMainSelector([data.facility, data.subtitle, data.mark, data.doc_type])
        dispatch(setSelectorNew(bigArray)) 
      })
    })
  }
 }

 export const handleClickThunkCreator =(id: number) => {
  return (dispatch:any ,getState:any) => {
    let listSelector:Array<itemSelectorType> = getState().mdr.newSelector;
    let buttonState = listSelector[id].buttonState
    let newState: number
    // buttonState === 0 ? newState =1 : newState = 0
    if (buttonState===0){
      newState =1
    } else if (buttonState===2){
      newState = 1
    } else {
      newState = 0
    }



    dispatch(makeClick([newState, id]))
    listSelector = getState().mdr.newSelector;
    let selector:selectorType = utilities.mainSelectorToIndexes(listSelector, 1)
    gccApi.getMdrSelector(
      utilities.listToStringQuery(selector.fac), 
      utilities.listToStringQuery(selector.sub),
      utilities.listToStringQuery(selector.mark),
      utilities.listToStringQuery(selector.doc)).then(data => {
        
      let greyState = utilities.turnButtonGray(listSelector, [
        data.facility, 
        data.subtitle,
        data.mark,
        data.doc_type
      ])
      
      dispatch(setSelectorNew(greyState))  

    })
    // gccApi.getMdrList(
    //   utilities.listToStringQuery(selector.fac), 
    //   utilities.listToStringQuery(selector.sub),
    //   utilities.listToStringQuery(selector.mark),
    //   utilities.listToStringQuery(selector.doc)).then(data => {
    //   dispatch(setMdr(data))    
    // })
    

    
    //  gccApi.getMdrList().then(data => {
    //   dispatch(setMdr(data))    
    // })
  }
 }



// export const getMdrThunkCreator =() => {
//   let facility = ''
//   let subtilte = ''
//   let mark = ''
//   let doc_type = ''
//   return (dispatch:any) => {
//      gccApi.getMdrList(facility, subtilte, mark, doc_type).then(data => {
//       dispatch(setMdr(data))    
//     })
//   }
//  }

export const { setMdr, setSelectorDict, makeClick, setSelectorNew } = mdrSlice.actions
export default mdrSlice.reducer
